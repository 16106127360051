import React, { Component } from 'react'
import { Radio, Button, Tabs, Spin, Popconfirm, Tooltip } from 'antd'
import {
  updateMedia,
  removeMedias
} from '../../../../../../../services/api/library'
import './certificate.scss'

import template from '../../../../../../../assets/template.svg'
import icon from '../../../../../../../assets/icon.svg'
import logo from '../../../../../../../assets/logo.svg'
import pencil from '../../../../../../../assets/pencil.svg'
import bin from '../../../../../../../assets/bin.svg'
import signature from '../../../../../../../assets/signature.svg'
import Template from '../../../../../../../components/CertificateTemplate'

import { LoadingOutlined } from '@ant-design/icons'
import html2canvas from 'html2canvas'
import { withTranslation } from 'react-i18next'
import { T } from 'antd/lib/upload/utils'

const { TabPane } = Tabs
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const TYPES = {
  ICON: 'Icon',
  SIGNATURE: 'Signature',
  SIGNATURE_TWO: 'SignatureTwo',
  LOGO: 'Logo',
  CERTIFICATE: 'Cert'
}

class Certificate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: props.type,
      icons: [],
      templates: [],
      userIcons: [],
      userLogos: [],
      userCerts: [],
      signatures: [],
      userSignatures: [],
      currentIndex: 0,
      isIconUploading: false,
      isLogoUploading: false,
      isSignatureUploading: false,
      isCertificateLoading: true,
      isCertificateUploading: false,
      isDeleting: false,
      clearCertificate: false,
      addSignature: false,
      certificatesWithHistory: []
    }
  }

  componentDidMount = () => {
    this.getIconsAndTemplates()
    Object.keys(TYPES).forEach(key => {
      const type = TYPES[key]
      this.getUserImages(type)
    })
  }

  getIconsAndTemplates = () => {
    const iconsObj = this.importAll(
      require.context(
        '../../../../../../../assets/userIcons/',
        false,
        /\.(png|jpe?g|svg)$/
      )
    )
    const templatesObj = this.importAll(
      require.context(
        '../../../../../../../assets/certificateTemplates/',
        false,
        /\.(png|jpe?g|svg)$/
      )
    )
    const icons = this.importedArray(iconsObj)
    const templates = this.importedArray(templatesObj)
    this.setState({
      icons: icons,
      templates: templates,
      selectedTemplate: templates[0]
    })
  }

  importedArray = o => {
    let obj = Object.keys(o)
      .map((key, index) => {
        return { [index]: o[key] }
      })
      .reduce((a, b) => Object.assign({}, a, b))
    return obj
  }

  importAll = r => {
    let images = {}
    r.keys().forEach(item => {
      images[item.replace('./', '')] = r(item)
    })
    return images
  }

  getUserImages = type => {
    const { getLibraryList } = this.props
    getLibraryList({
      offset: 0,
      limit: null,
      type: type.toUpperCase(),
      sort: 'NEWEST'
    }).then(res => {
      this.setState(
        {
          [`user${type}s`]: res.rows,
          [`is${type}Uploading`]: false
        },
        () => {
          if (type === TYPES.CERTIFICATE) {
            this.handleCertificateData()
          }
        }
      )
    })
  }

  handleCertificateData = () => {
    const certificatesWithHistory = this.state.userCerts.filter(
      item =>
        item.custom_data &&
        Object.keys(item.custom_data).length > 0 &&
        item.custom_data.saveHistory === true
    )
    this.setState({
      isDeleting: false,
      isCertificateUploading: false,
      isCertificateLoading: false,
      certificatesWithHistory: certificatesWithHistory
    })
  }

  templates = () => {
    const { t } = this.props
    return (
      <div className="tab_icon">
        <span className="img-template">
          <img alt="" src={template} />
        </span>

        <span>{t('v4:templates')}</span>
      </div>
    )
  }

  icon = () => {
    const { t } = this.props
    return (
      <div className="tab_icon">
        <span className="img-template">
          <img alt="" src={icon} />
        </span>

        <span>{t('v4:icon')}</span>
      </div>
    )
  }

  signature = () => {
    const { t } = this.props
    return (
      <div className="tab_icon">
        <span className="img-template">
          <img alt="" src={signature} />
        </span>

        <span>{t('v4:sign')}</span>
      </div>
    )
  }

  logo = () => {
    const { t } = this.props
    return (
      <div className="tab_icon">
        <span className="img-template">
          <img alt="" src={logo} />
        </span>

        <span>{t('v4:logo')}</span>
      </div>
    )
  }

  onIconChange = e => {
    this.setState({
      selectedUserIcon: null,
      selectedUserIconId: null,
      selectedIcon: this.state.icons[e.target.value]
    })
  }

  onTemplateChange = e =>
    this.setState({ selectedTemplate: this.state.templates[e.target.value] })

  onUserImageChange = (item, type) => {
    this.setState({
      [`selectedUser${type}`]: item.link,
      [`selectedUser${type}Id`]: item.id,
      ...(type === 'Icon' && {
        [`selectedIcon`]: null
      })
    })
  }

  handleUserUpload = (e, type) => {
    let that = this
    var file = e.target.files[0]
    var reader = new FileReader()
    reader.onloadend = function () {
      that.uploadUserImage(reader.result, file, type)
    }
    reader.readAsDataURL(file)
    e.target.value = ""
  }

  uploadUserImage = (image, data, type) => {
    this.setState(
      {
        [`userUploaded${type}`]: image,
        [`is${type}Uploading`]: true
      },
      () => {
        const { upload } = this.props
        const format = type.toUpperCase()
        const body = {
          croppedImage: image,
          lastModifiedDate: new Date().getTime(),
          name: data.name,
          type: data.type,
          size: data.size,
          imgFormat: format
        }
        upload(body).then(res => {
          const { id } = res
          this.setState(
            {
              [`userUploaded${type}Id`]: id,
              [`is${type}Uploading`]: false
            },
            () => {
              this.getUserImages(type)
            }
          )
        })
      }
    )
  }

  handleCertificateText = item => {
    this.setState({
      [item.name]: item.value,
      certificateHistory: null
    })
  }

  handleImageInfo = () => {
    window.scrollTo(0, 0)
    const certificate = document.getElementById('certificate')
    const {
      selectedUserIconId,
      selectedIcon,
      selectedUserLogoId,
      signatures,
      selectedTemplate
    } = this.state

    const customTextData = this.getCustomTextData()

    return html2canvas(certificate).then(canvas => {
      var image = canvas.toDataURL('image/png', canvas.width, canvas.height)
      let y = [...image].slice(-1)[0] === '==' ? 2 : 1
      let x = image.length * (3 / 4) - y

      const body = {
        croppedImage: image,
        lastModifiedDate: new Date(),
        [`${this.state.toUpdateCertificateId ? 'filename' : 'name'}`]: this
          .state.certificateTitle,
        size: x,
        type: `image/png`,
        imgFormat: 'CERT',
        customData: {
          ...customTextData,
          selectedUserIconId,
          selectedIcon,
          selectedUserLogoId,
          signatures,
          selectedTemplate,
          saveHistory: true
        }
      }
      return body
    })
  }

  handleLineText = line => {
    const { toUpdateCertificateId, certificateHistory } = this.state
    var text
    if (toUpdateCertificateId) {
      text = String.raw(`this.state.${line}`) || certificateHistory.line || null
    } else {
      text = String.raw(`this.state.${line}`) || null
    }
    return text
  }

  getCustomTextData = () => {
    const { line1, line2, line4, line5, line6, line7, line8 } = this.state
    const textValues = {
      line1,
      line2,
      line4,
      line5,
      line6,
      line7,
      line8
    }
    return Object.keys(textValues)
      .filter(key => textValues[key] !== undefined)
      .reduce((obj, key) => {
        obj[key] = textValues[key]
        return obj
      }, {})
  }

  saveUserCertificate = () => {
    this.setState(
      {
        isCertificateUploading: true
      },
      async () => {
        const { upload } = this.props
        const imageData = await this.handleImageInfo()
        if (this.state.toUpdateCertificateId) {
          updateMedia(this.state.toUpdateCertificateId, imageData).then(() => {
            this.setState(
              {
                toUpdateCertificateId: null
              },
              () => {
                this.getUserImages(TYPES.CERTIFICATE)
              }
            )
          })
        } else {
          upload(imageData).then(() => {
            this.getUserImages(TYPES.CERTIFICATE)
          })
        }
      }
    )
  }

  clearCertificate = () =>
    this.setState({
      selectedTemplate: this.state.templates[0],
      certificateTitle: '',
      selectedIcon: null,
      selectedUserIcon: null,
      signatures: [],
      selectedUserLogo: null,
      selectedUserIconId: null,
      selectedUserLogoId: null,
      addSignature: false,
      certificateHistory: null,
      toUpdateCertificateId: null,
      clearCertificate: true
    })

  onClearImage = type => {
    switch (type) {
      case TYPES.ICON:
        this.setState({
          selectedIcon: null,
          selectedUserIcon: null,
          selectedUserIconId: null
        })
        break
      case TYPES.LOGO:
        this.setState({ selectedUserLogo: null, selectedUserLogoId: null })
        break
      case TYPES.SIGNATURE:
        if (this.state.addSignature) {
          this.setState({
            addSignature: false,
            currentIndex: 1,
            signatures: [this.state.signatures[1]]
          })
        } else {
          this.setState({
            addSignature: false,
            currentIndex: 0,
            signatures: []
          })
        }
        break
      case TYPES.SIGNATURE_TWO:
        this.setState({
          addSignature: false,
          currentIndex: 1,
          signatures: [this.state.signatures[0]]
        })
        break

      default:
        break
    }
  }

  onClear = () => this.setState({ clearCertificate: false })

  addSignature = () => this.setState({ addSignature: true })

  onUserSignatureSelect = item => {
    const { signatures, currentIndex, addSignature } = this.state
    var mySigns = [...signatures]
    var index = addSignature ? currentIndex : 0
    const data = { id: item.id, link: item.link }
    mySigns[index] = data
    index = (index + 1) % 2
    this.setState({
      signatures: mySigns,
      currentIndex: index
    })
  }

  putItemInCanvas = item => {
    const { custom_data } = item
    if (custom_data) console.log(custom_data)
    this.setState({
      line1: custom_data.line1 || this.state.line1,
      line2: custom_data.line2 || this.state.line2,
      line4: custom_data.line4 || this.state.line4,
      line5: custom_data.line5 || this.state.line5,
      line6: custom_data.line6 || this.state.line6,
      line7: custom_data.line7 || this.state.line7,
      line8: custom_data.line8 || this.state.line8,
      selectedIcon: custom_data.selectedIcon || null,
      selectedUserIcon: custom_data.selectedUserIcon || null,
      selectedUserIconId: custom_data.selectedUserIconId || null,
      selectedUserLogo: custom_data.selectedUserLogo || null,
      selectedUserLogoId: custom_data.selectedUserLogoId || null,
      addSignature: custom_data.signatures.length === 2,
      signatures: custom_data.signatures || [],
      selectedTemplate: custom_data.selectedTemplate || null,
      certificateHistory: item.custom_data,
      certificateTitle: item.filename
    })
  }

  editCertificate = item => {
    this.setState(
      {
        toUpdateCertificateId: item.id
      },
      () => {
        this.putItemInCanvas(item)
      }
    )
  }

  deleteCertificateHistory = item => {
    const { id } = item
    const data = {
      mediaIds: [id],
      custom_data: {
        ...item.custom_data,
        saveHistory: false
      }
    }

    this.setState({ isDeleting: true, toUpdateCertificateId: id }, () => {
      removeMedias(data)
        .then(() => {
          this.setState({
            toUpdateCertificateId: null,
            isDeleting: false,
            certificatesWithHistory: this.state.certificatesWithHistory.filter(
              cert => cert.id !== id
            )
          })
        })
        .catch(error => {
          console.error('Error deleting certificate:', error)
          this.setState({ isDeleting: false })
        })
    })
  }

  uploadCertificate = () => {
    this.setState(
      {
        isCertificateSaving: true
      },
      async () => {
        const { onUploadImage } = this.props
        const body = await this.handleImageInfo()
        onUploadImage(body)
      }
    )
  }

  getImageById = (params, type) => {
    if (params) {
      if (type === TYPES.LOGO) {
        const data = this.state.userLogos.filter(logo => logo.id === params)[0]
        return data && data.link
      } else if (type === TYPES.ICON) {
        const data = this.state.userIcons.filter(icon => icon.id === params)[0]
        return data && data.link
      } else {
        const data = params.map(item => {
          const signature = this.state.userSignatures.filter(
            signature => signature.id === item.id
          )
          if (signature.length !== 0) {
            return {
              id: signature[0].id,
              link: signature[0].link
            }
          } else return item
        })
        console.log(params, data)
        return data
      }
    }
  }

  handleCertName = e => {
    const inputValue = e.target.value
    if (inputValue.length <= 150) {
      this.setState({
        certificateTitle: inputValue
      })
    } else {
      this.setState({
        certificateTitle: inputValue.slice(0, 150)
      })
    }
  }

  render() {
    const {
      type,
      icons,
      templates,
      signatures,
      certificatesWithHistory,
      selectedIcon,
      selectedUserIcon,
      selectedUserIconId,
      selectedUserLogo,
      selectedUserLogoId,
      certificateHistory,
      selectedTemplate,
      clearCertificate,
      addSignature
    } = this.state
    const iconById = this.getImageById(selectedUserIconId, TYPES.ICON)
    const logoById = this.getImageById(selectedUserLogoId, TYPES.LOGO)
    const signaturesById = this.getImageById(signatures, TYPES.SIGNATURE)
    const { t } = this.props
    return (
      <React.Fragment>
        <div className="certificate_modal_wrap">
          <h1 className="certificate_modal_title">
            {t('v4:create_certificate')}
          </h1>

          <div className="certificate_modal_content">
            <div className="certificate_create_left">
              <div className="certificate_container">
                <div className="left_panel">
                  <div className="tabs_panel">
                    <Tabs
                      className="tabs"
                      defaultActiveKey="1"
                      tabPosition="top"
                    >
                      {/* Tab - Certificate Template / Background */}

                      <TabPane className="tab" tab={this.templates()} key="1">
                        <Radio.Group
                          className="certs-container certificate"
                          onChange={this.onTemplateChange}
                          defaultValue="0"
                        >
                          {Object.entries(templates).map(([key, val]) => (
                            <Radio.Button
                              className="certificate__icon"
                              value={key}
                              key={key}
                            >
                              <div>
                                {' '}
                                <img alt="" src={val} />
                              </div>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </TabPane>

                      {/* Tab - Icons Selection */}

                      <TabPane className="tab" tab={this.icon()} key="3">
                        <Radio.Group
                          className="certs-container icon"
                          onChange={this.onIconChange}
                          defaultActiveKey="1"
                        >
                          {Object.entries(icons).map(([key, val]) => (
                            <Radio.Button
                              className="certificate__icon"
                              value={key}
                              key={key}
                            >
                              <img alt="" src={val} />
                            </Radio.Button>
                          ))}
                        </Radio.Group>

                        {this.state.userIcons && (
                          <div className="user_icon">
                            {this.state.userIcons &&
                              this.state.userIcons.map(item => (
                                <div
                                  onClick={() =>
                                    this.onUserImageChange(item, TYPES.ICON)
                                  }
                                  className="certificate__icon"
                                  value={item}
                                  key={item.id}
                                >
                                  <img alt="" src={item.link} />
                                </div>
                              ))}
                          </div>
                        )}
                        <label
                          className="btn-upload"
                          style={{
                            pointerEvents: 'all'
                          }}
                        >
                          <input
                            type="file"
                            name="fileupload"
                            onChange={e => this.handleUserUpload(e, TYPES.ICON)}
                          />
                          <button className="btn">
                            {this.state.isIconUploading && (
                              <Spin indicator={antIcon} />
                            )}
                            {t('v4:upload_icon')}
                          </button>
                        </label>
                      </TabPane>

                      <TabPane className="tab" tab={this.signature()} key="4">
                        {this.state.userSignatures && (
                          <div className="user_icon">
                            {this.state.userSignatures &&
                              this.state.userSignatures.map(item => (
                                <div
                                  onClick={() =>
                                    this.onUserSignatureSelect(item)
                                  }
                                  className="certificate__icon"
                                  value={item}
                                  key={item.id}
                                >
                                  <img alt="" src={item.link} />
                                </div>
                              ))}
                          </div>
                        )}
                        <div className="signature-upload-wrap">
                          <div>
                            <label
                              className="btn-upload"
                              style={{
                                pointerEvents: 'all'
                              }}
                            >
                              <input
                                type="file"
                                name="fileupload"
                                onChange={e =>
                                  this.handleUserUpload(e, TYPES.SIGNATURE)
                                }
                              />
                              <button className="btn">
                                {this.state.isSignatureUploading && (
                                  <Spin indicator={antIcon} />
                                )}
                                {t('v4:upload_signature')}
                              </button>
                            </label>
                          </div>
                          {!addSignature && signatures.length === 1 && (
                            <Button onClick={this.addSignature}>
                              {t('v4:add_another_sign')}
                            </Button>
                          )}
                        </div>
                      </TabPane>
                      <TabPane className="tab" tab={this.logo()} key="5">
                        {this.state.userLogos && (
                          <div className="user_icon">
                            {this.state.userLogos &&
                              this.state.userLogos.map(item => (
                                <div
                                  onClick={() =>
                                    this.onUserImageChange(item, TYPES.LOGO)
                                  }
                                  className="certificate__icon"
                                  value={item}
                                  key={item.id}
                                >
                                  <img alt="" src={item.link} />
                                </div>
                              ))}
                          </div>
                        )}
                        <label
                          className="btn-upload"
                          style={{
                            pointerEvents: 'all'
                          }}
                        >
                          <input
                            type="file"
                            name="fileupload"
                            onChange={e => this.handleUserUpload(e, TYPES.LOGO)}
                          />
                          <button className="btn">
                            {this.state.isLogoUploading && (
                              <Spin indicator={antIcon} />
                            )}
                            {t('v4:upload_logo')}
                          </button>
                        </label>
                      </TabPane>
                    </Tabs>
                  </div>
                  <div className="canvas" id="certificate">
                    <Template
                      icon={selectedIcon}
                      userIcon={selectedUserIcon}
                      iconById={iconById}
                      logo={selectedUserLogo}
                      logoById={logoById}
                      signatures={signatures}
                      signaturesById={signaturesById}
                      onText={this.handleCertificateText}
                      certificateHistory={certificateHistory}
                      template={selectedTemplate}
                      clearCertificate={clearCertificate}
                      onClear={this.onClear}
                      addSignature={addSignature}
                      onClearImage={this.onClearImage}
                    />
                  </div>
                </div>
                {/* <div className="right_panel">
               

               
                </div> */}
              </div>
            </div>

            {/* User History of Certificate Creation */}

            <div className="certificate_create_right">
              {/* <h1 className="certificate_modal_title">Your Certificate</h1> */}
              <div className="detail">
                <div className="title d-col">
                  <div className="cert-item-title">
                    <span>{t('v4:name_of_cert')}</span>
                    <span className="max-character">
                      {t('v4:should_less_150')}
                    </span>
                  </div>
                  <input
                    type="text"
                    value={this.state.certificateTitle}
                    onChange={this.handleCertName}
                    name="certificateTitle"
                  />
                </div>
                <div className="buttons">
                  <Button
                    className="btn"
                    disabled={!this.state.certificateTitle}
                    onClick={this.clearCertificate}
                    style={{
                      backgroundColor: '#b10f0f'
                    }}
                  >
                    {t('v4:delete')}
                  </Button>
                  <Button
                    className="btn"
                    loading={this.state.isCertificateUploading}
                    onClick={() => this.saveUserCertificate()}
                    disabled={!this.state.certificateTitle}
                    style={{
                      background:
                        'linear-gradient(180deg, #698bf2 0%, #5057d5 100%)'
                    }}
                  >
                    {this.state.toUpdateCertificateId
                      ? 'Update Certificate'
                      : 'Save For Later'}
                  </Button>
                </div>
              </div>
              <div className="certificate_history">
                <div className="title">{t('v4:certificate_history')}</div>
                <div className="history_items" style={{}}>
                  <Spin
                    indicator={antIcon}
                    spinning={this.state.isCertificateLoading}
                  />
                  {certificatesWithHistory &&
                    certificatesWithHistory.map(item => (
                      <Spin
                        key={item.id}
                        spinning={
                          item.id === this.state.toUpdateCertificateId &&
                          this.state.isDeleting
                        }
                        tip="Deleting..."
                      >
                        <div className="history_item">
                          <div
                            className="history_item_details"
                            onClick={() => this.putItemInCanvas(item)}
                          >
                            <img
                              alt=""
                              src={item.custom_data.selectedTemplate}
                            />
                            <div className="history_item_info">
                              <Tooltip title={item.filename}>
                                <h3>{item.filename}</h3>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="history_item_action">
                            <div
                              className="history_item_action_img"
                              onClick={() => this.editCertificate(item)}
                            >
                              <img alt="" src={pencil} />
                            </div>
                            <Popconfirm
                              title={`${t('v4:do_you_want_delete_cert')}`}
                              onConfirm={() =>
                                this.deleteCertificateHistory(item)
                              }
                              okText="Yes"
                              cancelText="No"
                              icon={null}
                            >
                              <div className="history_item_action_img">
                                <img alt="" src={bin} />
                              </div>
                            </Popconfirm>
                          </div>
                        </div>
                      </Spin>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          className="save_certificate"
          disabled={!this.state.certificateTitle}
          onClick={this.uploadCertificate}
          loading={this.state.isCertificateSaving}
        >
          {t('v4:certificate_save_btn') + type}
        </Button>
      </React.Fragment>
    )
  }
}
export default withTranslation()(Certificate)
