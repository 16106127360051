import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import {
  Button,
  Icon,
  Popconfirm,
  Tooltip,
  Modal,
  Checkbox,
  message,
  Alert,
  Form,
  Input,
  InputNumber,
  Spin,
  List,
  Row,
  Dropdown,
  Menu,
  Upload
} from 'antd'

import { QuizCreation, QuizQuestions } from './internal'

import { Quiz } from './styled'
import { withTranslation } from 'react-i18next'
import { api } from '../../../../services'
import classNames from 'classnames'
import { PopupMedia } from '../../../../views/Library/internal'
import Dropzone from 'react-dropzone'
import nanoid from 'nanoid'

const { confirm } = Modal
class EQuiz extends Component {
  state = {
    isFetching: false,
    formCreateVisible: false,
    manageVisible: false,
    questionsVisible: false,
    editQuestion: null,
    currentQuiz: null,
    enableQuizGame: false,
    showMediaLibrary: false,
    selectedImage: '',
    coverLink: null,
    isAddSimulation: false,
    savingSimulation: false,
    isUploadingSimulation: false,
    selectedSimulation: [],
    selectedOption: "Default",
    sectionId: 0,
  }

  componentDidMount() {
    const {
      pageBuilder: { contentId, isSimulation }
    } = this.props
    this.setState({
      ...(isSimulation && { isAddSimulation: true }),
      formCreateVisible: true
    })

    if (contentId) {
      this.setState({ isFetching: true })
      api.quizes.getQuizByIdAnswers(contentId).then(res => {
        if (res.data) {
          this.setState({
            currentQuiz: res.data,
            enableQuizGame: res.data.is_quizgame,
            ...(res.data.is_quizgame && {
              selectedImage: res.data.cover,
              coverLink: res.data.cover.link
            }),
            ...(this.state.isAddSimulation && {
              selectedSimulation: res.data.simulation.links
            }),
            isFetching: false
          })
        }
      })
    }
  }

  setVisible = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  onAddQuestion = () => {
    this.setState({
      questionsVisible: true,
      editQuestion: null
    })
  }

  onEditQuestion = id => {
    this.setState({
      questionsVisible: true,
      editQuestion: {
        id,
        ...this.state.currentQuiz.questions[id]
      }
    })
  }

  onRemoveQuestion = index => {
    const { quizesActions } = this.props

    const questions = this.state.currentQuiz.questions
    const id = this.state.currentQuiz.id

    questions.splice(index, 1)

    quizesActions.updateQuestions(
      {
        questions
      },
      id
    )
  }

  handleInput = ({ id, questions_count: count }) => {
    const { uid, handleParams } = this.props

    if (handleParams) {
      handleParams(uid, { contentId: id, questions_count: count })
    }
  }

  setCurrentQuiz = data => {
    this.setState({
      currentQuiz: data
    })
  }

  updateCurrentQuizQuestions = data => {
    this.setState(
      {
        enableQuizGame: false,
        currentQuiz: {
          ...this.state.currentQuiz,
          questions: data
        }
      },
      () => {
        if (this.state.currentQuiz.is_quizgame) {
          const canAddGame =
            data &&
            data.every(
              item => item.type === 'single' || item.type === 'multiple'
            )
          // if quiz with game has new set of questions that has any item with answer type that is not single or multiple, update api
          if (!canAddGame) {
            this.updateQuiz({
              is_quizgame: false,
              cover_id: null,
              cover: {}
            })
          }
        }
      }
    )
  }

  toggleQuizInGames = () => {
    const questions = this.state.currentQuiz.questions
    if (
      questions &&
      !questions.every(
        item => item.type === 'single' || item.type === 'multiple'
      )
    ) {
      message.error(
        `You can add games only if all the questions have answer type of either single or multiple.`
      )
      this.setState({ enableQuizGame: false })
      return
    }
    this.setState(
      {
        enableQuizGame: !this.state.enableQuizGame
      },
      () => {
        if (this.state.currentQuiz.is_quizgame && !this.state.enableQuizGame) {
          this.updateQuiz({
            is_quizgame: false,
            cover_id: null,
            cover: {}
          })
        }
      }
    )
  }

  handleSelectImage = games => {
    const item = _.head(
      games.rows && games.rows.filter(value => value.isSelected)
    )
    this.setState(
      {
        selectedImage: item,
        showMediaLibrary: false,
        coverLink: item.link
      },
      () => {
        this.updateQuiz({
          is_quizgame: true,
          cover_id: item.id
        })
      }
    )
  }

  updateQuiz = params => {
    const { id } = this.state.currentQuiz
    const data = {
      ...this.state.currentQuiz,
      ...params
    }
    api.quizes.quizUpdate(data, id).then(quizRes => {
      if (quizRes && quizRes.data && quizRes.data.is_simulation) {
        this.setState({ savingSimulation: false })
      }

      api.quizes.getQuizByIdAnswers(id).then(res => {
        this.handleInput(data)
        this.setCurrentQuiz(data)
      })
    })
  }

  toggleSimulationModal = () => {
    this.setState({
      isAddSimulation: !this.state.isAddSimulation
    })
  }

  onDropSimulation = (...dropped) => {
    const files = dropped[1]

    const { libraryActions } = this.props

    this.setState(
      {
        isUploadingSimulation: true
      },
      () => {
        files.forEach((file, index) => {
          libraryActions
            .upload(file, { isSimulation: true, category: 'game' })
            .then(response => {
              const newArr = [...this.state.selectedSimulation, response]
              this.setState({
                selectedSimulation: newArr,
                ...(index + 1 === files.length && {
                  isUploadingSimulation: false
                })
              })
            })
        })
      }
    )
  }

  deleteSimulationFile = id => {
    let that = this
    confirm({
      title: 'Are you sure delete this simulation file?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        that.confimDeleteSimulationFile(id)
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  confimDeleteSimulationFile = simId => {
    this.setState(state => ({
      selectedSimulation: state.selectedSimulation.filter(
        ({ id }) => id !== simId
      )
    }))
  }

  renderSimulationModal = () => {
    const {
      currentQuiz,
      isFetching,
      isUploadingSimulation,
      selectedSimulation,
      selectedOption,
      sectionId
    } = this.state
    const {
      t,
      form: { getFieldDecorator },
      library: { uploadProgress }
    } = this.props

    const simulationTitle =
      currentQuiz && currentQuiz.simulation && currentQuiz.simulation.title
    const simulationScore =
      currentQuiz && currentQuiz.simulation && currentQuiz.simulation.score

    return (
      <Spin spinning={isFetching}>
        <Quiz.Main>
          <Form onSubmit={() => { }}>
            <Form.Item label="Select Simulation Type">
              <Dropdown overlay={this.menu} trigger={["click"]}>
                <Button>
                  {selectedOption}
                </Button>
              </Dropdown>
            </Form.Item>
            <Form.Item label={t('v3:name_simulation')} colon={false}>
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: t('errors:title_quiz')
                  }
                ],
                initialValue: simulationTitle
              })(<Input type="text" size="large" />)}
            </Form.Item>

            <Form.Item name="simulation_score" label="Score">
              <Fragment>
                <Tooltip
                  trigger={['focus']}
                  title={t('v3:score_between_one_hundred')}
                  placement="topLeft"
                  overlayClassName="numeric-input"
                >
                  {getFieldDecorator('score', {
                    rules: [
                      {
                        required: true,
                        message: t('v3:required_simulation')
                      }
                    ],
                    initialValue: simulationScore || 5
                  })(<InputNumber size="large" min={0} max={100} />)}
                </Tooltip>
              </Fragment>
            </Form.Item>
            {(selectedOption !== "Default" &&
              <Form.Item name="section_id" label="Upload Section file">
                <Fragment>
                  {getFieldDecorator('section_id', {
                    rules: [
                      {
                        required: true,
                        message: t('v3:required_section_file')
                      }
                    ],
                    initialValue: sectionId
                  })(<Upload
                    beforeUpload={(file) => {
                      const isJson = file.type === "application/json";
                      if (!isJson) {
                        message.error("You can only upload JSON files!");
                        return false;
                      }

                      const reader = new FileReader();
                      reader.onload = (e) => {
                        try {
                          const jsonData = JSON.parse(e.target.result);
                          console.log("Uploaded JSON Content:", jsonData);
                          this.setState({
                            sectionId: jsonData.section_id
                          })
                          // Handle the parsed JSON data here
                        } catch (error) {
                          message.error("Invalid JSON file!");
                          console.error("Error parsing JSON:", error);
                        }
                      };
                      reader.readAsText(file);

                      return false; // Prevent auto-upload
                    }}
                    accept=".json"
                  >
                    <Button>
                      <Icon type="upload" /> Upload Section file
                    </Button>
                  </Upload>)}
                </Fragment>
              </Form.Item>
            )}

            {selectedSimulation && selectedSimulation.length > 0 && (
              <List
                header={<h2>{'v3:selected_simulation_files'} </h2>}
                footer={false}
                style={{ marginBottom: '3rem' }}
                size="small"
                bordered
                dataSource={selectedSimulation}
                renderItem={item => (
                  <List.Item onClick={() => this.deleteSimulationFile(item.id)}>
                    <Row
                      style={{ width: '100%' }}
                      type="flex"
                      justify="space-around"
                      align="middle"
                    >
                      {item.title} <Icon type="close" />
                    </Row>
                  </List.Item>
                )}
              />
            )}

            <Form.Item label={t('v3:simulation')} colon={false} required={true}>
              <Dropzone
                accept={'*'}
                onDrop={this.onDropSimulation}
                style={{}}
                disabled={isUploadingSimulation}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    className={classNames(
                      'upload-dropzone',
                      'upload-dropzone_event',
                      {
                        'upload-dropzone_active': isDragActive
                        // 'upload-dropzone_invalid': !coverId && validate
                      }
                    )}
                    style={
                      {
                        // backgroundImage: 'url(' + coverLink + ')'
                      }
                    }
                  >
                    <div className="upload-dropzone__button">
                      {isUploadingSimulation && uploadProgress.length ? (
                        <Button className="rounded" size="large" icon="loading">
                          {uploadProgress[0].percent + '%'}
                        </Button>
                      ) : (
                        <div className="wrap_upload_buttons">
                          <Button
                            className="rounded"
                            size="large"
                            icon="picture"
                          >
                            {t('v3:upload_simulation')}
                          </Button>
                          {/* <Button
                            className="rounded"
                            size="large"
                            icon="picture"
                            onClick={e => {
                              e.stopPropagation()
                              this.setVisible('showMediaLibrary', true)
                            }}
                          >
                            {t('buttons:select_media_library')}
                          </Button> */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </Form.Item>
          </Form>
        </Quiz.Main>
      </Spin>
    )
  }

  handleSaveSimulation = () => {
    const {
      form: { validateFields },
      handleCloseQuizModal,
      handleAddNewQuizElement,
      lessonId,
      courseId,
      t
    } = this.props

    const { currentQuiz, selectedSimulation, sectionId, selectedOption } = this.state

    validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        if (selectedSimulation.length === 0) {
          message.error(`${t('v4:select_upload_simulation')}`)
          return
        }

        if (selectedOption !== "Default" && sectionId === 0) {
          console.log("selectedOption", selectedOption, "sectionId", sectionId)
          message.error(`${t('v4:select_upload_section_id')}`)
          return
        }

        this.setState({ savingSimulation: true }, () => {
          const newData = {
            title: values.title,
            is_simulation: true,
            simulation: {
              title: values.title,
              score: values.score,
              links: selectedSimulation.map(item => item.id)
            },
            lesson_id: lessonId,
            course_id: courseId
          }
          if (currentQuiz && currentQuiz.id) {
            this.updateQuiz(newData)
          } else {
            api.quizes.quizCreate(newData).then(res => {
              this.handleInput(res.data);
              this.setCurrentQuiz(res.data);

              const uid = nanoid(8);

              const data = {
                content: res.data.id,
                questions_count: 0,
                isSimulation: true,
              };

              if (this.state.selectedOption !== "Default" && this.state.sectionId !== 0) {
                data.section_id = this.state.sectionId;
                data.sim_type = this.state.selectedOption;
              }

              handleAddNewQuizElement({
                uId: uid,
                data: data,
              });
            });
          }
          handleCloseQuizModal()
        })
      }
    })
  }

  handleMenuClick = (e) => {
    this.setState({ selectedOption: e.item.props.children, sectionId: 0 });
    console.log("Selected key:", e.key);
  };

  handleMenuClick = (e) => {
    const selectedOption = e.item.props.children;

    this.setState(
      (prevState) => ({
        selectedOption,
        sectionId: selectedOption === "Default" ? 0 : prevState.sectionId,
      }),
      () => {
        console.log("Selected Option:", this.state.selectedOption);
        console.log("Section ID:", this.state.sectionId);
      }
    );
  };


  menu = () => {
    return <Menu onClick={this.handleMenuClick}>
      <Menu.Item key="default">Default</Menu.Item>
      <Menu.Item key="nyit">NYIT</Menu.Item>
      <Menu.Item key="dhl">DHL</Menu.Item>
    </Menu>
  }

  render() {

    const {
      handleCloseQuizModal,
      pageBuilder: { contentId },
      t,
      handleAddNewQuizElement,
      visible,
      lessonId,
      courseId,
      lessonBuilder: { currentPage, pages }
    } = this.props
    const {
      formCreateVisible,
      manageVisible,
      questionsVisible,
      editQuestion,
      enableQuizGame,
      showMediaLibrary,
      selectedImage,
      coverLink,
      isAddSimulation,
      savingSimulation,
      isUploadingSimulation
    } = this.state

    let questions = []
    if (this.state.currentQuiz) {
      questions = this.state.currentQuiz.questions
    }
    const pageId = pages[currentPage - 1].id

    const showAddQuestion = questionsVisible && !editQuestion

    const canAddGame =
      questions &&
      questions.length > 0 &&
      questions.every(
        item => item.type === 'single' || item.type === 'multiple'
      )

    const showGameSelection =
      formCreateVisible || !canAddGame || isAddSimulation
        ? false
        : enableQuizGame && !questionsVisible && !editQuestion

    console.log('in render selectedImage', selectedImage)
    return (
      <Modal
        title={isAddSimulation ? 'Add Simulation' : ''}
        visible={visible}
        closable={isAddSimulation}
        onCancel={handleCloseQuizModal}
        width="fit-content"
        className="quiz-create"
        centered
        footer={
          <>
            {isAddSimulation && (
              <Button
                shape="round"
                className="shl-primary-btn"
                loading={savingSimulation || isUploadingSimulation}
                style={{ marginBottom: '5px', marginRight: '5px' }}
                onClick={() => this.handleSaveSimulation()}
              >
                {t('buttons:save')}
              </Button>
            )}
            {manageVisible ? (
              <Button
                shape="round"
                className="shl-secondary-btn"
                style={{ marginBottom: '5px' }}
                onClick={() => handleCloseQuizModal()}
              >
                {t('buttons:close')}
              </Button>
            ) : (
              ''
            )}
          </>
        }
      >
        <Quiz>
          {formCreateVisible && !isAddSimulation && (
            <Quiz.Main>
              <Quiz.Title>
                {_.isEmpty(contentId)
                  ? t('labels:quiz_create')
                  : t('labels:quiz_update')}
              </Quiz.Title>

              <QuizCreation
                isFetching={this.state.isFetching}
                data={this.state.currentQuiz}
                handleInput={this.handleInput}
                setCurrentQuiz={this.setCurrentQuiz}
                handleCloseQuizModal={handleCloseQuizModal}
                onCancel={() => {
                  this.setVisible('formCreateVisible', false)
                  this.setVisible('manageVisible', true)
                }}
                ids={{
                  lessonId,
                  courseId,
                  pageId
                }}
              />
            </Quiz.Main>
          )}

          {isAddSimulation && this.renderSimulationModal()}

          {manageVisible && !showAddQuestion && !isAddSimulation && (
            <Quiz.Main>
              <Quiz.Header>
                <Quiz.Back>
                  <Icon type="left" />
                  <Quiz.Back.Label
                    onClick={() => {
                      this.setVisible('formCreateVisible', true)
                      this.setVisible('manageVisible', false)
                    }}
                  >
                    {t('buttons:back')}
                  </Quiz.Back.Label>
                </Quiz.Back>
              </Quiz.Header>

              <Quiz.Name>
                {t('labels:quiz_name')}:{' '}
                {this.state.currentQuiz
                  ? this.state.currentQuiz.title
                  : t('general:loading')}
              </Quiz.Name>

              <Quiz.Add
                onClick={this.onAddQuestion}
                disabled={!this.state.currentQuiz}
              >
                <Icon type="plus" />
                {t('buttons:add_question')}
              </Quiz.Add>

              {!_.isEmpty(questions) ? (
                <Fragment>
                  <hr
                    style={{ border: '1px solid #e6e4e4', margin: '2rem 0' }}
                  />

                  <Quiz.Info> Total {questions.length} items </Quiz.Info>

                  <Quiz.List>
                    {questions.map((question, i) => (
                      <Quiz.Item
                        key={i}
                        active={editQuestion && editQuestion.id === i}
                      >
                        <Quiz.Item.Number>{i + 1}.</Quiz.Item.Number>{' '}
                        <Quiz.Item.Information>
                          <Quiz.Item.Name>{question.title}</Quiz.Item.Name>

                          <Quiz.Item.Events>
                            <Tooltip title={t('labels:edit')}>
                              <Quiz.Item.Event
                                size="small"
                                onClick={() => this.onEditQuestion(i)}
                                active={editQuestion && editQuestion.id === i}
                              >
                                <Icon
                                  type="edit"
                                  style={{ color: '#246CF6' }}
                                />
                              </Quiz.Item.Event>
                            </Tooltip>

                            <Popconfirm
                              title={t('warnings:delete')}
                              okText={t('general:yes')}
                              cancelText={t('general:no')}
                              icon={false}
                              onConfirm={() => this.onRemoveQuestion(i)}
                            >
                              <Tooltip title={t('v4:delete')}>
                                <Quiz.Item.Event size="small">
                                  <Icon
                                    type="close"
                                    style={{ color: '#FF0000' }}
                                  />
                                </Quiz.Item.Event>
                              </Tooltip>
                            </Popconfirm>
                          </Quiz.Item.Events>
                        </Quiz.Item.Information>
                      </Quiz.Item>
                    ))}
                  </Quiz.List>

                  <hr
                    style={{ border: '1px solid #e6e4e4', margin: '2rem 0' }}
                  />

                  {canAddGame ? (
                    <Checkbox
                      onChange={e => this.toggleQuizInGames(e.target.checked)}
                      defaultChecked={enableQuizGame}
                    >
                      {t('v3:simulation_quiz_games')}
                    </Checkbox>
                  ) : (
                    questions &&
                    questions.length > 0 && (
                      <Alert
                        type={t('v3:alert_info')}
                        description={t('v3:simulation_alert_des')}
                      />
                    )
                  )}
                </Fragment>
              ) : (
                <Quiz.Empty>{t('questions_empty')}</Quiz.Empty>
              )}
            </Quiz.Main>
          )}

          {questionsVisible && !formCreateVisible && !isAddSimulation && (
            <Quiz.Main style={{ marginLeft: editQuestion ? '3rem' : null }}>
              <Quiz.Header>
                <Quiz.Back
                  onClick={() => {
                    this.setVisible('questionsVisible', false)
                    if (editQuestion) {
                      this.setState({ editQuestion: null })
                    }
                  }}
                  style={{
                    justifyContent: editQuestion ? 'flex-end' : 'flex-start'
                  }}
                >
                  <Icon type={editQuestion ? 'close' : 'left'} />
                  <Quiz.Back.Label>
                    {editQuestion
                      ? t('buttons:close')
                      : t('labels:back_to_question')}
                  </Quiz.Back.Label>
                </Quiz.Back>
              </Quiz.Header>

              <QuizQuestions
                currentQuiz={this.state.currentQuiz}
                updateCurrentQuizQuestions={this.updateCurrentQuizQuestions}
                data={editQuestion}
                handleAddNewQuizElement={handleAddNewQuizElement}
                onCancel={() => {
                  this.setVisible('questionsVisible', false)
                  if (editQuestion) {
                    this.setState({ editQuestion: null })
                  }
                }}
              />
            </Quiz.Main>
          )}

          {showGameSelection && (
            <Quiz.Main style={{ marginLeft: '3rem' }}>
              <Quiz.Title>{t('v3:games')}</Quiz.Title>

              <div
                style={{
                  position: 'relative'
                }}
              >
                <div
                  className={classNames(
                    'upload-dropzone',
                    'upload-dropzone_event'
                  )}
                  style={{
                    backgroundImage: 'url(' + coverLink + ')'
                  }}
                >
                  <div className="wrap_upload_buttons">
                    <Button
                      className="rounded"
                      size="large"
                      icon="filepdfoutlined"
                      onClick={e => {
                        e.stopPropagation()
                        this.setVisible('showMediaLibrary', true)
                      }}
                    >
                      {t('buttons:select_media_library')}
                    </Button>
                  </div>
                </div>
              </div>
            </Quiz.Main>
          )}

          {showMediaLibrary && (
            <Modal
              visible={showMediaLibrary}
              onCancel={() => this.setVisible('showMediaLibrary', false)}
              centered
              footer={null}
              width={'80%'}
            >
              <div className="wrap_modal_tabs">
                <PopupMedia
                  isGame
                  isPageBuilder
                  handleInsertData={this.handleSelectImage}
                />
              </div>
            </Modal>
          )}
        </Quiz>
      </Modal>
    )
  }
}

export default Form.create()(withTranslation('quiz')(EQuiz))
