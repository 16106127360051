import _ from 'lodash'
import nanoid from 'nanoid'
import { withTranslation } from 'react-i18next'
import React, { Component } from 'react'

import './styles.scss'
import { Alert, Modal, Spin } from 'antd'
import { orientationConversion } from './utils'
import history from '../../../../../../../history'
import { api } from '../../../../../../../services'
import {
  AudioIcon,
  ImageIcon,
  VideoIcon,
  TextIcon,
  AudioModalTitleIcon
} from './components/Icons'
import LandscapeMode from './components/LandscapeMode'
import FooterActions from './components/FooterActions'
import Text from '../../../PageBuilder/Components/Text'
import { builder } from '../../../../../../../constants'
import PageViewOption from './components/PageViewOption'
import Audio from '../../../PageBuilder/Components/Audio'
import Image from '../../../PageBuilder/Components/Image'
import Video from '../../../PageBuilder/Components/Video'
import { Builder } from '../../../../../../../components'
import {
  EditAudio,
  EditDetailAudio
} from '../../../PageBuilder/Components/Audio/internal/Edit'
import {
  CreateAudio,
  AddDetailAudio
} from '../../../PageBuilder/Components/Audio/internal/Create'
import BackgroundImageCropper from './components/BackgroundImageCropper'
import EQuiz from '../../../../../../../components/Builder/Elements/EQuiz'
import { EditImage } from '../../../PageBuilder/Components/Image/internal/Edit'
import { EditVideo } from '../../../PageBuilder/Components/Video/internal/Edit'
import EditText from '../../../PageBuilder/Components/Text/internal/Edit/EditText'
import EAnnotation from '../../../../../../../components/Builder/Elements/EAnnotation'

const { types } = builder

class PageMaker extends Component {
  state = {
    src: '',
    error: null,
    selectedItem: [],
    pageLandScape: [],
    isUploading: false,
    isLandScape: false,
    isStockImage: false,
    selectedBgImage: null,
    isShowTextModal: false,
    isShowQuizModal: false,
    isShowVideoModal: false,
    isShowAudioModal: false,
    changeBackground: false,
    isShowImageModal: false,
    isShowDetailModal: false,
    isPexelProcessing: false,
    isShowEditDetailModal: false,
    errorMessage: 'upload_error',
    isAnnotationItemClicked: false,
    isShowCreateAudioModal: false
  }

  lessonUpdate = _.debounce(param => {
    const { lesson, updateLesson } = this.props
    if (lesson.saving) return
    const data = {
      title: lesson.lessonTitle,
      description: lesson.lessonDescription,
      cover_id: lesson.lessonImgId,
      topics: lesson.lessonTags || [],
      pages: param.pages,
      pages_landscape: param.pages_landscape
    }
    const val = updateLesson(data, lesson.lessonId)
    this.setState({ pageLandScape: val.data.pages_landscape })
  }, 2000)

  updateLesson = () => {}

  importElement = () => {
    const {
      lesson: { pages }
    } = this.props
    let landscape = this.orientationConversion(pages)
    this.lessonUpdate({
      pages: landscape.pages,
      pages_landscape: landscape.pages_landscape
    })
  }

  orientationConversion = (pages, data) => orientationConversion(pages, data)

  handleData = (data, key = false) => {
    const {
      lesson: {
        pages: oldPages,
        pages_landscape: oldPagesLandScape,
        currentPage
      },
      handleLessonInput
    } = this.props

    const { isLandScape } = this.state

    if (!isLandScape) {
      const pages = [...oldPages]
      if (key) {
        pages[currentPage - 1].data = [...pages[currentPage - 1].data, data]
      } else {
        pages[currentPage - 1].data = data
      }
      const landScapePages = [...oldPagesLandScape]
      if (key) {
        landScapePages[currentPage - 1].data = [
          ...landScapePages[currentPage - 1].data,
          data
        ]
      } else {
        // landScapePages[currentPage - 1].data = data
      }
      let landscape = this.orientationConversion(pages, data)
      handleLessonInput('pages', pages)
      handleLessonInput('pages_landscape', landScapePages)
      this.lessonUpdate({
        pages: landscape.pages,
        pages_landscape: !key ? oldPagesLandScape : landscape.pages_landscape
      })
    } else {
      const landScapePages = [...oldPagesLandScape]
      if (key) {
        landScapePages[currentPage - 1].data = [
          ...landScapePages[currentPage - 1].data,
          data
        ]
      } else {
        landScapePages[currentPage - 1].data = data
      }
      handleLessonInput('pages_landscape', landScapePages)
      this.lessonUpdate({ pages: oldPages, pages_landscape: landScapePages })
    }
  }

  /**
   * Don't use third parameter
   */
  handleParams = (uid, params, settings = null, e = null) => {
    if (e) e.stopPropagation()
    const {
      lesson: {
        pages: oldPages,
        pages_landscape: oldPagesLandScape,
        currentPage
      },
      handleLessonInput
    } = this.props

    const { isLandScape } = this.state

    if (!isLandScape) {
      const pages = [...oldPages]
      const pageStructure = pages[currentPage - 1].data
      pages[currentPage - 1].data = pageStructure.map(el => {
        if (el.uid === uid) {
          return {
            ...el,
            params: { ...el.params, ...params },
            ...settings
          }
        }
        return el
      })

      const landScapePages = [...oldPagesLandScape]
      const pageStructureLS = landScapePages[currentPage - 1].data
      landScapePages[currentPage - 1].data = pageStructureLS.map(el => {
        if (el.uid === uid) {
          return {
            ...el,
            params: { ...el.params, ...params },
            ...settings
          }
        }
        return el
      })
      handleLessonInput('pages', pages)
      handleLessonInput('pages_landscape', landScapePages)
      this.lessonUpdate({ pages: pages, pages_landscape: oldPagesLandScape })
    } else {
      const landScapePages = [...oldPagesLandScape]
      const pageStructureLS = landScapePages[currentPage - 1].data
      landScapePages[currentPage - 1].data = pageStructureLS.map(el => {
        if (el.uid === uid) {
          return {
            ...el,
            params: { ...el.params, ...params },
            ...settings
          }
        }
        return el
      })
      handleLessonInput('pages_landscape', landScapePages)
      this.lessonUpdate({ pages: oldPages, pages_landscape: landScapePages })
    }
  }

  toPage = type => {
    const {
      handleLessonInput,
      lesson: { currentPage, saving },
      rootPath,
      uploadFetching
    } = this.props

    if (saving || uploadFetching) return

    if (type === 'previous') {
      handleLessonInput('currentPage', currentPage - 1)
      history.push(`${rootPath}/page-constructor`)
    }

    if (type === 'next') {
      handleLessonInput('currentPage', currentPage + 1)
      history.push(`${rootPath}/page-constructor`)
    }

    if (type === 'new') {
      handleLessonInput('currentPage', null)
      history.push(`${rootPath}/page-builder`)
    }
  }

  handleMultipleDrop = filesLength => {
    this.setState(
      { error: true, errorMessage: this.getErrroMessage(filesLength) },
      () => {
        setTimeout(() => {
          if (this.state.error) this.clearAlert()
        }, 10000)
      }
    )
  }

  getErrroMessage = filesLength => {
    if (filesLength < 1) return 'upload_error'
    if (filesLength > 10) return 'upload_more_than_10'
  }

  clearAlert = () => this.setState({ error: null })

  handleShowAudioModal = () => {
    const { clearUploadList } = this.props
    this.setState({ isShowAudioModal: true }, () => clearUploadList())
  }

  handleShowImageModal = () => {
    const { clearUploadList } = this.props
    this.setState({ isShowImageModal: true }, () => clearUploadList())
  }

  handleChangeBackground = () => {
    const { clearUploadList } = this.props
    this.setState({ isShowImageModal: true, changeBackground: true }, () =>
      clearUploadList()
    )
  }

  handleShowVideoModal = () => {
    const { clearUploadList } = this.props
    this.setState({ isShowVideoModal: true }, () => clearUploadList())
  }

  handleShowTextModal = () => {
    const { clearUploadList } = this.props
    this.setState({ isShowTextModal: true }, () => clearUploadList())
  }

  onShowCreateModal = () => {
    this.setState({ isShowAudioModal: false, isShowCreateAudioModal: true })
  }

  onShowDetailModal = src => {
    this.setState({
      src: src,
      isShowCreateAudioModal: false,
      isShowDetailModal: true
    })
  }

  onShowEditDetailModal = src => {
    const {
      audioSynthesizer: { contentId }
    } = this.props

    this.setState(
      {
        src: src,
        isShowAudioModal: false,
        isShowCreateAudioModal: false,
        isShowDetailModal: false,
        isShowEditDetailModal: true
      },
      () => {
        const { showEditAudioModal } = this.props
        showEditAudioModal({ contentId: contentId, isShowEditAudio: false })
      }
    )
  }

  handleAddNewElement = ({ uid, data }) => {
    this.addElement(uid, types.ANNOTATION)
    this.handleParams(uid, data)
  }

  onHideAudioModal = () => {
    this.setState(
      {
        src: '',
        isShowAudioModal: false,
        isShowImageModal: false,
        isShowVideoModal: false,
        isShowTextModal: false
      },
      () => {
        const { showAnnotationAudioModal } = this.props
        showAnnotationAudioModal({ isShowAnnotationAudio: false })
      }
    )
  }

  onHideCreateAudioModal = () => {
    this.setState({
      isShowCreateAudioModal: false,
      isShowAudioModal: false,
      isShowImageModal: false,
      isShowVideoModal: false,
      isShowTextModal: false
    })
  }

  onHideImageModal = () => {
    this.newlyUploadedCroppedBgImage = null
    this.setState(
      {
        src: '',
        selectedItem: null,
        changeBackground: false,
        isShowCreateAudioModal: false,
        isShowAudioModal: false,
        isShowImageModal: false,
        isShowVideoModal: false,
        isShowTextModal: false
      },
      () => {
        const { showEditImageModal, showAnnotationImageModal } = this.props
        showEditImageModal({ isShowEditImage: false, uid: '' })
        showAnnotationImageModal({
          isShowAnnotationImage: false
        })
      }
    )
  }

  onHideVideoModal = () => {
    this.setState(
      {
        src: '',
        isShowCreateAudioModal: false,
        isShowAudioModal: false,
        isShowImageModal: false,
        isShowVideoModal: false,
        isShowTextModal: false
      },
      () => {
        const { showEditVideoModal, showAnnotationVideoModal } = this.props
        showEditVideoModal({ isShowEditVideo: false })
        showAnnotationVideoModal({ isShowAnnotationVideo: false })
      }
    )
  }

  onCloseVideoModal = () => {
    const { showEditVideoModal } = this.props
    showEditVideoModal({ contentId: '', isShowEditVideo: false })
  }

  onHideTextModal = () => {
    this.setState(
      {
        src: '',
        isShowCreateAudioModal: false,
        isShowAudioModal: false,
        isShowImageModal: false,
        isShowVideoModal: false,
        isShowTextModal: false
      },
      () => {
        const { showEditTextModal, showAnnotationTextModal } = this.props
        showEditTextModal({ isShowEditText: false })
        showAnnotationTextModal({ isShowAnnotationText: false })
      }
    )
  }

  onHideDetailModal = () => {
    this.setState(
      {
        isShowAudioModal: false,
        isShowCreateAudioModal: false,
        isShowDetailModal: false,
        isShowEditDetailModal: false
      },
      () => {
        const { showEditAudioDetailModal } = this.props
        showEditAudioDetailModal({ isShowDetailEditAudio: false })
      }
    )
  }

  onHideEditModal = () => {
    const { showEditAudioModal } = this.props
    showEditAudioModal({ isShowEditAudio: false })
  }

  isValidType = type => {
    let valid = true
    switch (type) {
      case types.TEXT:
        break
      case types.VIDEO:
        break
      case types.VIDEO_STREAM:
        break
      case types.AUDIO:
        break
      case types.IMAGE:
        break
      default:
        valid = false
        break
    }
    return valid
  }

  handleInsertData = ({ rows: privateData }, publicMedia, orgMedia) => {
    let tempData = privateData

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      tempData = [...privateData, ...publicData]
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      tempData = [...privateData, ...orgData]
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({ selectedItem }, () => {
      const { changeBackground, selectedItem } = this.state
      if (selectedItem && changeBackground) {
        // this.handleBackgroundChange(selectedItem)
      }
    })
  }

  handleInsertStockImageData = selectedItem =>
    this.setState({ selectedItem, isStockImage: true })

  handleShowAnnotationModal = () => {
    const { showAnnotationModal } = this.props
    showAnnotationModal({ isShowAnnotation: true })
  }

  handleCloseAnnotationModal = () => {
    const { showAnnotationModal } = this.props
    showAnnotationModal({ isShowAnnotation: false, isStockImage: false })
  }

  handleCloseQuizModal = () => {
    const { showEditQuizModal } = this.props
    showEditQuizModal({ isShowEditQuiz: false, uid: '' })
    this.setState({ isShowQuizModal: false })
  }

  setTextContent = content => {
    this.setState({
      selectedItem: { content: content, media_format: types.TEXT }
    })
  }

  handleInsertText = content => {
    console.log('reached text', content)
    if (_.isEmpty(content)) return
    const uid = nanoid(8)
    this.addElement(uid, types.TEXT)
    this.handleParams(uid, content, true)
    this.setState({ isShowTextModal: false })
  }

  handleEditText = content => {
    const {
      pageBuilder: { uid }
    } = this.props
    if (_.isEmpty(content)) return
    this.handleParams(uid, content)
    const { showEditTextModal } = this.props
    showEditTextModal({ isShowEditText: false })
  }

  handleFileMediaLibrary = ({ file, uid }) => {
    let data = {}
    if (file.media_format === types.VIDEO_STREAM) {
      /* data = {   @todo: Check Video stream logic
        ...data,
        stream_id: file.dependency ? file.dependency.instance_id : '',
        stream_type: file.dependency ? file.dependency.instance_type : '',
        height: 200,
        chat: false
      } */
      data = { content: file.link, content_id: file.id }
    } else {
      data = { content: file.link, content_id: file.id }
    }
    this.setState(
      {
        src: '',
        selectedItem: [],
        isShowAudioModal: false,
        isShowDetailModal: false,
        isShowImageModal: false,
        isShowVideoModal: false
      },
      () => {
        const { showEditImageModal } = this.props
        showEditImageModal({ uid: '' })
      }
    )
    this.handleParams(uid, data)
  }

  handlePexelImage = ({ file, uid }) => {
    const params = {
      title: 'test',
      filename: 'what ever',
      key: 'what ever',
      description: 'test',
      link: file.link,
      contentId: file.id,
      customData: {},
      isFromPexelImage: true
    }
    this.setState({ isPexelProcessing: true })
    api.library.upload(params).then(({ data }) => {
      let dataPxl = { content: data.link, content_id: data.id }
      this.handleParams(uid, dataPxl)
      this.setState({ isPexelProcessing: false })
    })
    this.setState(
      {
        src: '',
        selectedItem: [],
        isShowAudioModal: false,
        isShowDetailModal: false,
        isShowImageModal: false,
        isShowVideoModal: false,
        isStockImage: false
      },
      () => {
        const { showEditImageModal } = this.props
        showEditImageModal({ uid: '' })
      }
    )
  }

  getType = ({ media_format }) => {
    if (media_format) return media_format
    return ''
  }

  getSelectedFile = files => {
    if (!files) return
    this.setState({ selectedItem: files[0], src: files[0].link })
  }

  handleEmptySelectedItem = () => {
    const { showEditTextModal } = this.props
    this.setState({ selectedItem: [], isAnnotationItemClicked: false })
    showEditTextModal({ isShowEditText: false })
  }

  handleShowQuizModal = () => {
    const { clearUploadList } = this.props
    this.setState({ isShowQuizModal: true }, () => clearUploadList())
  }

  handleAddNewQuizElement = ({ uId, data }) => {
    const {
      pageBuilder: { uid }
    } = this.props
    if (uid) {
      this.handleParams(uid, data)
    } else {
      this.addElement(uId, types.QUIZ)
      this.handleParams(uId, data)
    }
  }

  closeChangeBackground = () => this.setState({ changeBackground: false })

  handleAnnotationItems = item => {
    if (!item) {
      return
    }
    if (item) {
      this.setState({ isAnnotationItemClicked: true, selectedItem: item })
      const { changeBackground } = this.state
      const {
        showAnnotationImageModal,
        showAnnotationTextModal,
        showAnnotationVideoModal,
        showAnnotationAudioModal
      } = this.props
      if (changeBackground) this.setState({ isShowImageModal: false })
      showAnnotationImageModal({ isShowAnnotationImage: false })
      showAnnotationTextModal({ isShowAnnotationText: false })
      showAnnotationVideoModal({ isShowAnnotationVideo: false })
      showAnnotationAudioModal({ isShowAnnotationAudio: false })
    }
  }

  addElement = (uid, type) => {
    const width = type === types.TEXT ? 450 : 400
    const data = {
      uid,
      type: type,
      params: { content: '', width, height: 200, x: 0, y: 0 }
    }
    this.handleData(data, true)
  }

  onHandleCroppedFile = data => {
    if (!data) {
      this.cancelBackgroundImageCrop()
      return
    }
    const item = this.state.selectedItem
    const { filename, file_size, media_mime_type } = item
    const body = {
      name: filename,
      size: file_size,
      croppedImage: data,
      type: media_mime_type,
      lastModifiedDate: new Date()
    }
    this.handleUploadImage(body)
  }

  handleUploadImage = file => {
    const { upload } = this.props
    this.setState({ isUploading: true, selectedBgImage: null })
    return upload(file)
      .then(res => {
        this.handleInsertStockImageData(res)
        this.newlyUploadedCroppedBgImage = res
      })
      .catch(e => {})
      .then(e => this.setState({ isUploading: false }))
  }

  handleBackgroundChange = selectedFile => {
    const data = {
      uid: nanoid(8),
      type: types.BACKGROUND_IMAGE,
      params: { content: '', content_id: null }
    }
    data.params.content = selectedFile.link
    data.params.content_id = selectedFile.id
    const {
      lesson: { pages: oldPages, currentPage }
    } = this.props

    const pages = []
    oldPages.forEach(each => {
      let filterData = []
      let item = {}
      if (each.data) {
        filterData = each.data.filter(i => i.type !== types.BACKGROUND_IMAGE)
      }
      item = { ...each, data: filterData }
      pages.push(item)
    })

    const pageStructure =
      pages[currentPage - 1] && pages[currentPage - 1].data
        ? pages[currentPage - 1].data
        : []
    if (_.isEmpty(pageStructure)) this.handleData([...pageStructure, data])
    if (
      !_.isEmpty(pageStructure) &&
      !pageStructure.find(node => node.uid === data.uid)
    ) {
      this.handleData([...pageStructure, data])
    } else {
      // handleParams(data.uid, { content: link, content_id: id })
    }
  }

  handleFileUpload = (files, isMedia = false, isFromPexel = false) => {
    const file = files[0]
    const { changeBackground } = this.state
    if (!file) return
    if (changeBackground) {
      this.newlyUploadedCroppedBgImage = null
      this.handleBackgroundChange(file)
      this.setState({
        selectedItem: null,
        isShowImageModal: false,
        changeBackground: false
      })
      return
    }
    const { handleFileDropIn } = this.props
    const getType = this.getType(file)
    if (!this.isValidType(getType)) return

    const {
      pageBuilder: { uid }
    } = this.props
    let customFile = {}
    if (uid) {
      customFile.file = file
      customFile.uid = uid
    } else {
      const uId = nanoid(8)
      customFile.file = file
      customFile.uid = uId
      this.addElement(uId, getType) // function prepare data for uploading and display content of data depend on type in page
    }
    if (isMedia) {
      if (isFromPexel) {
        this.handlePexelImage(customFile)
      } else {
        this.handleFileMediaLibrary(customFile)
      }
    } else {
      handleFileDropIn(customFile) // Create redux to store dropped file with auto generated uid
    }
  }

  /* hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    });
  }; */

  handleAnnnotationOrUploadFile = () => {
    const { selectedItem } = this.state
    this.props.pageBuilder.isShowAnnotation
      ? this.handleAnnotationItems([selectedItem])
      : this.handleFileUpload([selectedItem], true, !!this.state.isStockImage)
  }

  onChangeLandscape = () => this.setState({ isLandScape: true })

  onHideLandscapeMode = () => this.setState({ isLandScape: false })

  cancelBackgroundImageCrop = () => this.setState({ selectedBgImage: null })

  render() {
    const {
      lesson: { lessonId, pages, pages_landscape, currentPage, saving },
      courseId,
      upload,
      t,
      audioSynthesizer: { isShowEditAudio, isShowDetailEditAudio },
      pageBuilder: {
        isShowEditText,
        isShowEditImage,
        isShowEditVideo,
        isShowEditQuiz,
        isShowAnnotationImage,
        isShowAnnotationText,
        isShowAnnotationVideo,
        isShowAnnotationAudio,
        isShowAnnotation,
        uid
      }
    } = this.props
    const {
      error,
      isShowAudioModal,
      selectedItem,
      errorMessage,
      isShowCreateAudioModal,
      isShowDetailModal,
      src,
      isUploading,
      selectedBgImage,
      isShowEditDetailModal,
      isShowImageModal,
      isShowVideoModal,
      isShowTextModal,
      isAnnotationItemClicked,
      isShowQuizModal,
      isStockImage,
      isPexelProcessing,
      changeBackground,
      isLandScape,
      pageLandScape
    } = this.state

    if (!pages[currentPage - 1]) return null

    let pageStructureLS

    const pageStructure = pages[currentPage - 1].data || []

    if (pageLandScape.length !== 0) {
      pageStructureLS = pageLandScape[currentPage - 1].data || []
    } else {
      pageStructureLS = pages_landscape[currentPage - 1].data || []
    }

    const audioModalTitle = (
      <AudioModalTitleIcon
        t={t}
        isShowCreateAudioModal={isShowCreateAudioModal}
      />
    )

    return (
      <>
        {isLandScape && (
          <LandscapeMode
            t={t}
            upload={upload}
            loading={saving}
            isUploading={isUploading}
            isLandScape={isLandScape}
            handleData={this.handleData}
            pageStructure={pageStructureLS}
            updateLesson={this.updateLesson}
            handleParams={this.handleParams}
            onClickImportElement={this.importElement}
            handleMultipleDrop={this.handleMultipleDrop}
            handleShowTextModal={this.handleShowTextModal}
            onHideLandscapeMode={this.onHideLandscapeMode}
            handleShowQuizModal={this.handleShowQuizModal}
            handleShowAudioModal={this.handleShowAudioModal}
            handleShowImageModal={this.handleShowImageModal}
            handleShowVideoModal={this.handleShowVideoModal}
            handleChangeBackground={this.handleChangeBackground}
            handleShowAnnotationModal={this.handleShowAnnotationModal}
          />
        )}
        <Builder.Toolbar
          mode={'PAGE'}
          upload={upload}
          handleData={this.handleData}
          pageStructure={pageStructure}
          handleShowTextModal={this.handleShowTextModal}
          handleShowQuizModal={this.handleShowQuizModal}
          handleShowAudioModal={this.handleShowAudioModal}
          handleShowImageModal={this.handleShowImageModal}
          handleShowVideoModal={this.handleShowVideoModal}
          handleChangeBackground={this.handleChangeBackground}
          handleShowAnnotationModal={this.handleShowAnnotationModal}
        />
        <PageViewOption
          pages={pages}
          upload={upload}
          toPage={this.toPage}
          currentPage={currentPage}
          handleData={this.handleData}
          pageStructure={pageStructure}
          handleParams={this.handleParams}
          onChangeLandscape={this.onChangeLandscape}
          handleMultipleDrop={this.handleMultipleDrop}
        />
        {error && (
          <div className="alert_message">
            <Alert
              message="Error"
              description={t(`errors:${errorMessage}`)}
              type="error"
              closable
              showIcon
              banner
              onClose={this.clearAlert}
            />
          </div>
        )}
        {(isShowAudioModal || isShowAnnotationAudio) && (
          <Modal
            title={<AudioIcon t={t} />}
            className="custom-ant-modal"
            visible={isShowAudioModal || isShowAnnotationAudio}
            onCancel={this.onHideAudioModal}
            width={'85%'}
            footer={
              <FooterActions
                t={t}
                loading={saving}
                title={t('buttons:insert')}
                onClickCancel={this.onHideAudioModal}
                onClickTitle={() =>
                  isShowAnnotation
                    ? this.handleAnnotationItems([selectedItem])
                    : this.handleFileUpload([selectedItem], true)
                }
              />
            }
          >
            <Audio
              isPageBuilder
              onShowCreateModal={this.onShowCreateModal}
              getSelectedFile={this.getSelectedFile}
              handleInsertData={this.handleInsertData}
              src={src}
            />
          </Modal>
        )}
        {isShowCreateAudioModal && (
          <Modal
            className="custom-ant-modal"
            title={audioModalTitle}
            visible={isShowCreateAudioModal}
            onCancel={this.onHideCreateAudioModal}
            footer={false}
            width={'88%'}
          >
            <CreateAudio onShowDetailModal={this.onShowDetailModal} t={t} />
          </Modal>
        )}
        {isShowEditAudio && (
          <Modal
            className="custom-ant-modal"
            title={audioModalTitle}
            visible={isShowEditAudio}
            onCancel={this.onHideEditModal}
            footer={false}
            width={'85%'}
          >
            <EditAudio
              onShowEditDetailModal={this.onShowEditDetailModal}
              t={t}
            />
          </Modal>
        )}
        {isShowDetailModal && (
          <Modal
            className="custom-ant-modal insert-audio-detail"
            visible
            onCancel={this.onHideDetailModal}
            width={'40%'}
            footer={false}
          >
            <AddDetailAudio
              handleFileUpload={this.handleFileUpload}
              getSelectedFile={this.getSelectedFile}
              src={src}
              isFromCreateAudio
              onHideDetailModal={this.onHideDetailModal}
              t={t}
            />
          </Modal>
        )}
        {(isShowEditDetailModal || isShowDetailEditAudio) && (
          <Modal
            className="custom-ant-modal insert-audio-detail"
            visible
            onCancel={this.onHideDetailModal}
            width={'40%'}
            footer={false}
          >
            <EditDetailAudio
              handleFileUpload={this.handleFileUpload}
              getSelectedFile={this.getSelectedFile}
              src={src}
              onHideDetailModal={this.onHideDetailModal}
              t={t}
            />
          </Modal>
        )}
        {(isShowImageModal ||
          isShowAnnotationImage ||
          isPexelProcessing ||
          (!_.isEmpty(uid) && !isShowEditQuiz && !isShowEditText)) && (
          <Modal
            className="custom-ant-modal"
            title={<ImageIcon t={t} />}
            visible={
              isPexelProcessing ||
              isShowImageModal ||
              isShowAnnotationImage ||
              !_.isEmpty(uid)
            }
            onCancel={this.onHideImageModal}
            width={isShowAnnotationImage ? '80%' : '85%'}
            footer={
              <FooterActions
                t={t}
                loading={saving}
                title={
                  isPexelProcessing
                    ? t('buttons:inserting')
                    : t('buttons:insert')
                }
                isDisabled={
                  Array.isArray(selectedItem) && selectedItem.length === 0
                }
                onClickCancel={this.onHideImageModal}
                onClickTitle={this.handleAnnnotationOrUploadFile}
              />
            }
          >
            <Spin className="ant_spin" spinning={isUploading}>
              <Image
                src={src}
                isPageBuilder
                getSelectedFile={this.getSelectedFile}
                handleInsertData={this.handleInsertData}
                handleInsertStockImageData={this.handleInsertStockImageData}
                newlyUploadedCroppedBgImage={this.newlyUploadedCroppedBgImage}
                onDoubleClickCrop={
                  changeBackground
                    ? () => this.setState({ selectedBgImage: selectedItem })
                    : null
                }
                isLandScape={isLandScape}
                changeBackground={changeBackground}
                changeTabs={() => {
                  this.setState({ selectedItem: [] })
                }}
              />
            </Spin>
          </Modal>
        )}
        {selectedBgImage && (
          <Modal
            visible
            width={'60%'}
            footer={false}
            onCancel={this.cancelBackgroundImageCrop}
            className="custom-ant-modal insert-audio-detail"
          >
            <BackgroundImageCropper
              t={t}
              selectedBgImage={selectedBgImage}
              onClickDone={this.onHandleCroppedFile}
              onClickClose={this.cancelBackgroundImageCrop}
            />
          </Modal>
        )}
        {isShowEditImage && (
          <Modal
            className="custom-ant-modal insert-audio-detail"
            visible
            onCancel={this.onHideImageModal}
            width={'40%'}
            footer={false}
          >
            <EditImage
              t={t}
              getSelectedFile={this.getSelectedFile}
              onHideImageModal={this.onHideImageModal}
              handleFileUpload={this.handleFileUpload}
            />
          </Modal>
        )}
        {isShowEditVideo && (
          <Modal
            className="custom-ant-modal insert-audio-detail"
            visible
            onCancel={this.onCloseVideoModal}
            width={'40%'}
            footer={false}
          >
            <EditVideo
              handleFileUpload={this.handleFileUpload}
              getSelectedFile={this.getSelectedFile}
              onHideVideoModal={this.onCloseVideoModal}
              t={t}
            />
          </Modal>
        )}
        {(isShowVideoModal || isShowAnnotationVideo) && (
          <Modal
            className="custom-ant-modal"
            title={<VideoIcon t={t} />}
            visible={isShowVideoModal || isShowAnnotationVideo}
            onCancel={this.onHideVideoModal}
            width={'85%'}
            footer={
              <FooterActions
                t={t}
                loading={saving}
                title={t('buttons:insert')}
                onClickCancel={this.onHideVideoModal}
                onClickTitle={() =>
                  isShowAnnotation
                    ? this.handleAnnotationItems([selectedItem])
                    : this.handleFileUpload([selectedItem], true)
                }
              />
            }
          >
            <Video
              isPageBuilder
              getSelectedFile={this.getSelectedFile}
              handleInsertData={this.handleInsertData}
              src={src}
            />
          </Modal>
        )}
        {(isShowTextModal || isShowAnnotationText) && (
          <Modal
            className="custom-ant-modal"
            title={<TextIcon t={t} />}
            visible={isShowTextModal || isShowAnnotationText}
            onCancel={this.onHideTextModal}
            width={'85%'}
            footer={
              <FooterActions
                t={t}
                loading={saving}
                title={t('buttons:insert')}
                onClickCancel={this.onHideTextModal}
                onClickTitle={() =>
                  isShowAnnotation
                    ? this.handleAnnotationItems([selectedItem])
                    : this.handleInsertText(selectedItem, true)
                }
              />
            }
          >
            <Text setTextContent={this.setTextContent} />
          </Modal>
        )}
        {isShowEditText && (
          <Modal
            className="custom-ant-modal"
            title={<TextIcon t={t} />}
            visible={isShowEditText}
            onCancel={this.onHideTextModal}
            width={'85%'}
            footer={
              <FooterActions
                t={t}
                loading={saving}
                title={t('buttons:update')}
                onClickCancel={this.onHideTextModal}
                onClickTitle={() =>
                  isShowAnnotation
                    ? this.handleAnnotationItems([selectedItem])
                    : this.handleEditText(selectedItem, true)
                }
              />
            }
          >
            <EditText setTextContent={this.setTextContent} />
          </Modal>
        )}
        {isShowAnnotation && (
          <EAnnotation
            selectedItem={isAnnotationItemClicked ? selectedItem : []}
            emptySelectedItem={this.handleEmptySelectedItem}
            pageStructure={pageStructure}
            handleAddNewElement={this.handleAddNewElement}
            handleCloseAnnotationModal={this.handleCloseAnnotationModal}
            handleParams={this.handleParams}
            isStockImage={isStockImage}
            changeBackground={changeBackground}
            handleChangeBackground={this.handleChangeBackground}
            closeChangeBackground={this.closeChangeBackground}
          />
        )}
        {(isShowQuizModal || isShowEditQuiz) && (
          <EQuiz
            pageStructureMain={pageStructure}
            handleAddNewQuizElement={this.handleAddNewQuizElement}
            handleCloseQuizModal={this.handleCloseQuizModal}
            visible={isShowQuizModal || isShowEditQuiz}
            handleParams={this.handleParams}
            lessonId={lessonId}
            courseId={courseId}
          />
        )}
        )
      </>
    )
  }
}

export default withTranslation('buttons')(PageMaker)
