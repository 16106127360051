import React, { Fragment, useEffect, useRef, useState } from 'react'
import { api } from '../../services'
import {
  Alert,
  Modal,
  Card,
  Input,
  Button,
  Icon,
  Avatar,
  Spin,
  Row,
  Col
} from 'antd'
import './AiCourseCreation.scss'
import AiCourseForm from './AiCourseForm'
import AiTableOfContent from './AiTableOfContent'
import { useTranslation } from 'react-i18next'
import uuidv4 from 'uuid/v4'
import NewHeader from '../NewHeader'
import history from '../../history'
import { useSelector } from 'react-redux'
import { config } from '../../constants'

const { TextArea } = Input
const { confirm } = Modal

const defaultErrorObj = {
  hasError: false,
  msg: ''
}

const defaultShowModal = {
  isVisible: false,
  content: {}
}

const CourseCreation = props => {
  const [courseTitle, setCourseTitle] = useState('')
  const [conversation, setConversation] = useState([])
  const [chatText, setChatText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(defaultErrorObj)
  const [isInitialForm, setIsInitialForm] = useState(true)
  const [isGeneratingTableOfContent, setIsGeneratingTableOfContent] = useState(
    false
  )
  const [isContentGenerationStarted, setIsContentGenerationStarted] = useState(
    false
  )
  const [showModal, setShowModal] = useState(defaultShowModal)
  const [isFetchingAiContent, setIsFetchingAiContent] = useState(false)
  const [aiContent, setAiContent] = useState(null)
  const [
    isContentGenerationCompleted,
    setIsContentGenerationCompleted
  ] = useState(false)

  const courseId = props.match.params.id
  const user = useSelector(state => state.user)
  const { t } = useTranslation()

  useEffect(() => {
    if (courseId) {
      getCourseTitle()
    }
  }, [])

  useEffect(() => {
    if (aiContent) {
      setShowModal({
        isVisible: true,
        content: {
          id: uuidv4(),
          content: aiContent
        }
      })
      setIsInitialForm(false)
      setIsContentGenerationStarted(true)
    }
  }, [aiContent])

  const getCourseTitle = () => {
    setIsFetchingAiContent(true)
    api.courses
      .getCourseById(courseId)
      .then(res => {
        setCourseTitle(res.data.title)
        if (res.data.is_ai_generated) {
          history.push(`/courses/${courseId}/edit`)
        } else {
          fetchContent()
        }
      })
      .catch(error => {
        console.error(error)
        setIsFetchingAiContent(false)
        history.push(`/courses/${courseId}/edit`)
      })
  }

  const fetchContent = () => {
    setIsFetchingAiContent(true)
    const activeOrganizationId = user.info.active_organization_id
    api.courses
      .getGeneratedContent({
        orgId: activeOrganizationId,
        courseId: courseId
      })
      .then(response => {
        if (response.data.content) {
          setAiContent(response.data.content)
          setIsInitialForm(false)
        }
        setIsFetchingAiContent(false)
      })
      .catch(() => {
        setIsFetchingAiContent(false)
      })
  }

  const handleChat = (prompt = '') => {
    if ((!prompt && !chatText) || isLoading) return

    const finalPrompt = prompt || chatText
    setChatText('')
    setIsLoading(true)

    const userMessage = { id: uuidv4(), role: 'user', questions: finalPrompt }

    const systemMessageId = uuidv4()
    const systeMessage = {
      id: systemMessageId,
      role: 'assistant',
      questions: ''
    }

    const newConversation = [...conversation, userMessage, systeMessage]
    setConversation(newConversation)

    const contextData = conversation.find(item =>
      item.hasOwnProperty('context')
    )

    const conversationPayload = [
      ...contextData.context,
      ...conversation,
      userMessage,
      systeMessage
    ]

    const conversationText = conversationPayload
      .map(msg => ({
        role: msg.role,
        content: msg.questions || msg.content
      }))
      .filter(i => i.content)

    console.log({
      conversationPayload,
      conversationText
    })

    const url = config.questionAnswerAiUrl
    const token = localStorage.getItem('accessToken')

    fetch(`${url}/?service=chat-stream`, {
      method: 'POST',
      headers: {
        Accept: 'text/event-stream',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        conversation_history: conversationText,
        suggestion_generate: true,
        toc_generate: false
      })
    })
      .then(response => {
        if (!response.body)
          throw new Error('ReadableStream not yet supported in this browser.')

        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        let accumulatedText = ''

        function readStream() {
          return reader.read().then(({ done, value }) => {
            if (done) {
              setIsLoading(false)
              return
            }

            const chunk = decoder.decode(value, { stream: true })
            accumulatedText += chunk

            setConversation(prev =>
              prev.map(msg =>
                msg.id === systemMessageId
                  ? { ...msg, questions: accumulatedText }
                  : msg
              )
            )

            return readStream()
          })
        }

        return readStream()
      })
      .catch(error => {
        console.error('Streaming error:', error)
        setError({ hasError: true, msg: 'Error in streaming data' })
        setIsLoading(false)
      })
  }

  const handleFormSubmit = (values, data) => {
    console.log({
      values,
      data,
      newConvo: [
        {
          id: uuidv4(),
          formValues: values,
          role: 'user',
          isInitial: true
        },
        {
          id: uuidv4(),
          ...data,
          role: 'assistant'
        }
      ]
    })
    setIsInitialForm(false)
    setConversation([
      {
        id: uuidv4(),
        formValues: values,
        role: 'user',
        isInitial: true
      },
      {
        id: uuidv4(),
        ...data,
        role: 'assistant'
      }
    ])
  }

  const generateTableOfContent = () => {
    const contextData = conversation.find(item =>
      item.hasOwnProperty('context')
    )

    const conversationPayload = [...contextData.context, ...conversation]

    const conversationText = conversationPayload
      .map(msg => ({
        role: msg.role,
        content: msg.questions || msg.content
      }))
      .filter(i => i.content)

    setIsGeneratingTableOfContent(true)
    setChatText('')

    api.courses
      .generateStreaming({
        conversation_history: conversationText,
        suggestion_generate: false,
        toc_generate: true
      })
      .then(res => {
        setConversation(prev => [
          ...prev,
          {
            id: uuidv4(),
            table_of_content: res.data.table_of_content,
            promptId: prev[prev.length - 1].id,
            role: 'assistant'
          }
        ])
        setIsGeneratingTableOfContent(false)
      })
      .catch(err => {
        setIsGeneratingTableOfContent(false)
        setError({ hasError: true, msg: err.message })
      })
  }

  const handleSetupAgain = () => {
    confirm({
      title: t('v3:ai_creation_confirm_title'),
      content: t('v3:ai_creation_confirm_content'),
      centered: true,
      onOk() {
        setIsInitialForm(true)
        setConversation([])
        setShowModal({ isVisible: false, content: {} })
        setIsGeneratingTableOfContent(false)
        setIsLoading(false)
        setChatText('')
        setError(defaultErrorObj)
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const handleGoBack = () => {
    if (isContentGenerationCompleted) {
      history.push(`/courses/${courseId}/edit`)
      return
    }
    confirm({
      title: t('v3:ai_creation_confirm_title'),
      content: t('v3:ai_creation_confirm_content'),
      centered: true,
      onOk() {
        history.push(`/courses/${courseId}/edit`)
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const handleTableOfContentChange = ({ id, tableOfContent }) => {
    setConversation(prev =>
      prev.map(item =>
        item.id === id ? { ...item, table_of_content: tableOfContent } : item
      )
    )
  }

  const handleContentGenerationStarted = () => {
    setIsContentGenerationStarted(true)
  }

  const isFromKnowledgeCenter = window.location.pathname.includes(
    'knowledge-center'
  )

  const handleCancel = () => {
    const hasContentGenerationStarted =
      isContentGenerationStarted && !isGeneratingTableOfContent
    if (hasContentGenerationStarted) {
      return
    }
    confirm({
      title: 'Are you sure you want to close?',
      content: `All your changes will be lost.`,
      onOk: () => {
        history.push(`/courses/${courseId}/edit`)
      }
    })
  }

  const renderCreationModalContent = () => {
    return (
      <>
        <div className="course-creation-ai-modal__header">
          <h2>{t('v3:ai_course_creation')}</h2>
          <div className="shl-header">
            <NewHeader />
          </div>
        </div>

        {isInitialForm ? (
          isFromKnowledgeCenter ? (
            <div onCancel={handleCancel}>
              <AiCourseForm
                courseId={courseId}
                onFormSubmit={handleFormSubmit}
                loading={isFetchingAiContent}
                isFromKnowledgeCenter={isFromKnowledgeCenter}
              />
            </div>
          ) : (
            <Modal visible footer={false} onCancel={handleCancel} centered>
              <AiCourseForm
                courseId={courseId}
                onFormSubmit={handleFormSubmit}
                loading={isFetchingAiContent}
                courseTitle={courseTitle}
              />
            </Modal>
          )
        ) : (
          <div className="course-creation-ai-modal__body">
            {!isContentGenerationStarted && (
              <Card bordered={false}>
                <div
                  className={`course-creation-ai-wrap ${
                    showModal.isVisible ? 'show-creation-content' : ''
                  }`}
                >
                  <AiCourseFormPreview
                    handleSetupAgain={handleSetupAgain}
                    message={conversation[0]}
                  />
                  <div className="course-creation-ai-box">
                    <div className="course-creation-ai-box-title">
                      <h2>{t('v4:course_assistance')}</h2>
                    </div>
                    <div className="course-creation-ai-box-body">
                      <AiCourseChat
                        conversation={conversation}
                        isLoading={isLoading}
                        generateTableOfContent={generateTableOfContent}
                        isGeneratingTableOfContent={isGeneratingTableOfContent}
                        setShowModal={setShowModal}
                        showModal={showModal}
                        handleSetupAgain={handleSetupAgain}
                      />
                      <div className="course-creation-ai-modal__input-container">
                        <TextArea
                          disabled={isLoading || isGeneratingTableOfContent}
                          placeholder={t('reports:message')}
                          className="course-creation-ai-modal__input"
                          onChange={e => setChatText(e.target.value)}
                          onPressEnter={e => {
                            e.preventDefault()
                            if (!chatText.trim()) return
                            handleChat()
                          }}
                          value={chatText}
                          style={{ width: '100%' }}
                        />
                        <a
                          className={`course-creation-ai-modal__send ${
                            !chatText.trim()
                              ? 'course-creation-ai-modal__send--disabled'
                              : ''
                          }`}
                          onClick={() => {
                            if (!chatText.trim()) return
                            handleChat()
                          }}
                        >
                          <Icon type="arrow-up" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
            {showModal && showModal.isVisible && (
              <AiTableOfContent
                courseTitle={
                  courseTitle
                    ? courseTitle
                    : aiContent
                    ? t('v3:course_generated')
                    : conversation[0].formValues.courseTitle
                }
                courseId={courseId}
                tableOfContent={showModal.content}
                onTableOfContentChange={handleTableOfContentChange}
                onContentGenerationStarted={handleContentGenerationStarted}
                isFromKnowledgeCenter={isFromKnowledgeCenter}
                setIsContentGenerationCompleted={
                  setIsContentGenerationCompleted
                }
              />
            )}
          </div>
        )}

        {error.hasError && !isLoading && (
          <Alert
            message={error.msg}
            type="error"
            showIcon
            style={{ marginTop: 20 }}
          />
        )}
        {!isFromKnowledgeCenter && (
          <div className="ai-go-back-btn">
            <Button
              className="shl-primary-btn"
              icon="left"
              onClick={handleGoBack}
            >
              {t('v4:go_back')}
            </Button>
          </div>
        )}
      </>
    )
  }

  if (isFromKnowledgeCenter) {
    return (
      <div className="course-creation-ai-modal knowledge-center-course-ai-container">
        {renderCreationModalContent()}
      </div>
    )
  }

  return (
    <Spin spinning={isFetchingAiContent}>
      <div
        className="course-creation-ai-modal"
        centered
        visible
        onOk={handleCancel}
        onCancel={handleCancel}
        width="100%"
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('v4:go_back')}
          </Button>
        ]}
        maskClosable={false}
        closable={false}
      >
        {renderCreationModalContent()}
      </div>
    </Spin>
  )
}

const AiCourseChat = ({
  conversation,
  showModal,
  setShowModal,
  generateTableOfContent,
  isGeneratingTableOfContent,
  isLoading
}) => {
  const endOfConversationRef = useRef(null)
  const user = useSelector(state => state.user)
  const { t } = useTranslation()

  useEffect(() => {
    if (endOfConversationRef.current) {
      endOfConversationRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [conversation, isLoading])

  const showGenerateTableOfContentButton = messageId => {
    const lastItem = conversation[conversation.length - 1]
    return (
      lastItem && lastItem.id === messageId && lastItem.questions.trim() !== ''
    )
  }

  const showTableOfContentButton = messageId => {
    const item = conversation.find(item => item.promptId === messageId)
    return item && item.table_of_content ? item : false
  }

  const isShowingTableOfContent = messageId => {
    const contentToShow = showTableOfContentButton(messageId)
    return contentToShow && contentToShow.id === showModal.content.id
  }

  const parseMarkdown = text => {
    if (!text) return ''

    // First handle code blocks to prevent internal parsing
    const codeBlocks = new Map()
    let codeBlockCounter = 0

    // Replace code blocks with placeholders
    text = text.replace(/```(?:\w+)?\n?([\s\S]*?)```/g, (match, code) => {
      const placeholder = `__CODE_BLOCK_${codeBlockCounter}__`
      codeBlocks.set(placeholder, code.trim())
      codeBlockCounter++
      return placeholder
    })

    // Process text line by line
    const lines = text.split('\n')
    let inList = false
    let listType = null
    let listItems = []
    let currentIndentation = 0

    const processedLines = lines
      .map(line => {
        // Handle code block placeholders first
        const codeBlockMatch = line.match(/__CODE_BLOCK_(\d+)__/)
        if (codeBlockMatch) {
          const code = codeBlocks.get(codeBlockMatch[0])
          inList = false // Reset list state when encountering a code block
          return `<pre><code>${escapeHtml(code)}</code></pre>`
        }

        const trimmedLine = line.trim()
        if (!trimmedLine) {
          // Handle empty lines
          if (inList) {
            const listHtml = `<${listType}>${listItems.join('')}</${listType}>`
            inList = false
            listItems = []
            return listHtml
          }
          return ''
        }

        // Handle headings (# Heading)
        const headingMatch = trimmedLine.match(/^(#{1,6})\s+(.+)$/)
        if (headingMatch) {
          inList = false // Reset list state when encountering a heading
          const level = headingMatch[1].length
          return `<h${level}>${parseInlineMarkdown(
            headingMatch[2]
          )}</h${level}>`
        }

        // Handle lists (both unordered and ordered)
        const unorderedListMatch = trimmedLine.match(/^([-*])\s+(.+)$/)
        const orderedListMatch = trimmedLine.match(/^(\d+)\.\s+(.+)$/)

        if (unorderedListMatch || orderedListMatch) {
          const match = unorderedListMatch || orderedListMatch
          const content = match[2]
          const newListType = unorderedListMatch ? 'ul' : 'ol'

          if (!inList) {
            // Starting a new list
            inList = true
            listType = newListType
            listItems = []
          } else if (listType !== newListType) {
            // List type changed, process previous list
            const listHtml = `<${listType}>${listItems.join('')}</${listType}>`
            listItems = []
            listType = newListType
          }

          listItems.push(`<li>${parseInlineMarkdown(content)}</li>`)
          return null // Will be processed when list ends
        } else if (inList) {
          // End of list reached
          const listHtml = `<${listType}>${listItems.join('')}</${listType}>`
          inList = false
          listItems = []
          return listHtml + '\n' + parseInlineMarkdown(trimmedLine)
        }

        // Handle blockquotes
        const blockquoteMatch = trimmedLine.match(/^>\s+(.+)$/)
        if (blockquoteMatch) {
          return `<blockquote>${parseInlineMarkdown(
            blockquoteMatch[1]
          )}</blockquote>`
        }

        // Handle horizontal rules
        if (trimmedLine.match(/^([-*_]){3,}$/)) {
          return '<hr/>'
        }

        // Regular paragraph
        return `<p>${parseInlineMarkdown(trimmedLine)}</p>`
      })
      .filter(Boolean)

    // Handle any remaining list
    if (inList && listItems.length > 0) {
      processedLines.push(`<${listType}>${listItems.join('')}</${listType}>`)
    }

    return processedLines.join('\n')
  }

  // Helper function to escape HTML special characters
  const escapeHtml = text => {
    const htmlEntities = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;'
    }
    return text.replace(/[&<>"']/g, char => htmlEntities[char])
  }

  // Helper function to parse inline markdown elements
  const parseInlineMarkdown = text => {
    // Handle inline code first to prevent parsing markdown inside code
    text = text.replace(
      /`([^`]+)`/g,
      (match, code) => `<code>${escapeHtml(code)}</code>`
    )

    return (
      text
        // Bold (either ** or __)
        .replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>')
        // Italic (either * or _)
        .replace(/(\*|_)(.*?)\1/g, '<em>$2</em>')
        // Strike-through
        .replace(/~~(.*?)~~/g, '<del>$1</del>')
        // Links
        .replace(
          /\[([^\]]+)\]\(([^)]+)\)/g,
          '<a href="$2" target="_blank">$1</a>'
        )
        // Line breaks
        .replace(/  $/gm, '<br/>')
    )
  }

  return (
    <div className="course-creation-ai-conversation">
      {conversation.map((message, index) => {
        if (message.table_of_content) return null

        const isSystem = message.role === 'assistant'
        const isLastMessage = index === conversation.length - 1
        const isStreaming = isSystem && isLoading && isLastMessage

        let content = message.questions || ''
        if (isSystem && !isStreaming && !message.questions) {
          content = message.revised_prompt
            ? t('v3:ai_prompt_ready')
            : t('v3:ai_unable_respond')
        }

        return (
          <Fragment key={message.id}>
            {message.isInitial ? null : (
              <div
                className={`course-creation-ai-conversation__message ${
                  isSystem ? 'msg--system' : 'msg--user'
                }`}
              >
                <div className="course-creation-ai-conversation__message-from">
                  {isSystem ? (
                    <span>{t('v3:ai')}</span>
                  ) : user &&
                    user.info &&
                    user.info.avatar &&
                    user.info.avatar.link ? (
                    <Avatar src={user.info.avatar.link} alt="User Avatar" />
                  ) : (
                    <span>{t('v3:ai_you')}</span>
                  )}
                </div>
                <div className="course-creation-ai-conversation__message-content">
                  {isStreaming && !message.questions ? (
                    <div className="dot-container">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parseMarkdown(content)
                      }}
                    />
                  )}

                  {showTableOfContentButton(message.id) && (
                    <div
                      className="course-creation-ai-conversation__message-content__preview-button"
                      onClick={() => {
                        const contentToShow = showTableOfContentButton(
                          message.id
                        )
                        setShowModal(
                          isShowingTableOfContent(message.id)
                            ? defaultShowModal
                            : {
                                isVisible: true,
                                content: {
                                  id: contentToShow.id,
                                  content: contentToShow.table_of_content
                                }
                              }
                        )
                      }}
                    >
                      {isShowingTableOfContent(message.id) ? (
                        <>
                          <Icon type="eye-invisible" /> {t('v3:ai_hide_toc')}
                        </>
                      ) : (
                        <>
                          <Icon type="eye" /> {t('v3:ai_preview_toc')}
                        </>
                      )}
                    </div>
                  )}
                  {showGenerateTableOfContentButton(message.id) && !isLoading && (
                    <Button
                      loading={isGeneratingTableOfContent}
                      type="primary"
                      onClick={generateTableOfContent}
                    >
                      {t('v3:ai_generate_toc_now')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )
      })}
      <div ref={endOfConversationRef} />
    </div>
  )
}

const AiCourseFormPreview = ({ message = {}, handleSetupAgain }) => {
  if (!message.formValues) return null

  const {
    industrySpecific,
    industry,
    standard,
    standardDetails,
    courseTitle,
    learningObjective,
    duration
  } = message.formValues
  const { t } = useTranslation()

  return (
    <div className="course-ai-preview">
      <div className="course-ai-titile">
        <h2>{t('v4:course_information')}</h2>
      </div>
      <div className="course-ai-sec">
        <div className="course-ai-preview-section">
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_course_title')}</span>
            <p>{courseTitle}</p>
          </div>
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_industry_specific')}</span>
            <p>{industrySpecific}</p>
          </div>
          {industry && (
            <div className="course-ai-preview-item">
              <span>{t('v3:ai_creation_industry')}</span>
              <p>{industry}</p>
            </div>
          )}
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_standard_needed')}</span>
            <p>{standard}</p>
          </div>
          {standardDetails && (
            <div className="course-ai-preview-item">
              <span>{t('v3:ai_creation_standard_details')}</span>
              <p>{standardDetails}</p>
            </div>
          )}
        </div>
        <div className="course-ai-preview-section">
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_estimated_duration')}</span>
            <p>
              {duration} {t('v3:minutes')}
            </p>
          </div>
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_learning_objective')}</span>
            <p>{learningObjective}</p>
          </div>
        </div>
      </div>
      <Row type="flex" justify="end" className="course-ai-btn">
        <Col>
          <Button
            type="green"
            shape="round"
            className="course-ai-preview-button"
            onClick={handleSetupAgain}
          >
            {t('v3:setup_again')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CourseCreation
