import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { isSafari } from 'react-device-detect'
import {
  Button,
  Row,
  Col,
  Tabs,
  Input,
  List,
  Avatar,
  Tooltip,
  Select,
  Spin,
  Radio,
  message
} from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { activateSoundIcon, deactiveSoundIcon } from '../../../images'
import { AudioPlayer } from '../../../components'
import { saveBase64AsAudioFile } from '../../../../../../../../../../utils'
import { translationKeys } from '../../../../../../../../../../constants'
import { api } from '../../../../../../../../../../services'
import {
  fetchVoices,
  generateVoiceover,
  filterVoices
} from './elevenLabs.utils'
import PropTypes from 'prop-types'

const { TabPane } = Tabs
const { TextArea } = Input
const { Option } = Select

const CreateAudio = ({
  listTTSVoices,
  postTTSVoices,
  text,
  onShowDetailModal,
  audioSynthesizer
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState({
    src: '',
    voiceList: [],
    selectedText: text.replace(/\*/g, '') || '',
    languageValue: '',
    languages: [],
    filteredList: [],
    selectedVoice: '',
    autoPlay: false,
    lang: '',
    sampleSrc: '',
    audioprevLoading: false,
    ai_language: 'en-US',
    processAudio: false,
    // ElevenLabs specific state
    provider: 'default', // 'default' or 'elevenlabs'
    elevenLabsVoices: [],
    selectedElevenLabsVoice: null,
    searchQuery: '',
    isGenerating: false
  })

  const updateState = newState => {
    setState(prev => ({ ...prev, ...newState }))
  }

  useEffect(() => {
    loadInitialData()

    return () => {
      updateState({
        sampleSrc: '',
        src: ''
      })
    }
  }, [])

  const loadInitialData = async () => {
    // Load default voices
    if (listTTSVoices) {
      const res = await listTTSVoices()
      if (res && res.data) {
        updateVoiceList(res.data)
      }
    }

    // Load ElevenLabs voices
    try {
      const elevenLabsVoices = await fetchVoices()
      updateState({ elevenLabsVoices })
    } catch (error) {
      console.error('Failed to fetch ElevenLabs voices:', error)
    }

    audioPremiumTalen6()
  }

  useEffect(() => {
    if (text !== state.selectedText) {
      updateState({ selectedText: text.replace(/\*/g, '') })
    }
  }, [text])

  const audioPremiumTalen6 = async () => {
    try {
      const [list1, list2] = await Promise.all([
        api.audioSynthesizer.getNonEnglishVoices(),
        api.user.getSharelookVoices()
      ])

      if (list1 && list1.data && list2 && list2.data) {
        const mockData = { ...list1.data.data, ...list2.data.data }
        const mockDataArr = Object.entries(mockData).map(item => ({
          ...item,
          premium: true
        }))
        const mockDatavalues = Object.values(mockData)
        const mockDataArrLang = mockDatavalues.map(t => t.language)

        updateState({
          voiceList: [...mockDataArr, ...state.voiceList],
          languages: [...state.languages, ...mockDataArrLang]
        })
      }
    } catch (error) {
      console.error('Error loading premium voices:', error)
    }
  }

  const handleChangeOption = async e => {
    if (!state.selectedText) return

    const textToTranslate = state.selectedText

    updateState({
      lang: e,
      selectedText: 'Please wait... preparing translation'
    })

    try {
      const res1 = await api.audioSynthesizer.textTranslate({
        text: state.selectedText
      })

      if (res1 && res1.data && res1.data.result) {
        updateState({ selectedText: 'Please wait... translating' })

        const res2 = await api.audioSynthesizer.textTranslate({
          text: state.selectedText,
          source_language: res1.data.data,
          language: e
        })

        if (res2 && res2.data) {
          updateState({
            selectedText: res2.data.data && res2.data.data.replace(/\*/g, '')
          })
        } else {
          throw new Error(res2.data.error)
        }
      } else if (res1.data.error) {
        throw new Error(res1.data.error)
      }
    } catch (error) {
      console.error('Translation error:', error)
      message.error('Translation error occurred. Please try again.')
      updateState({ selectedText: textToTranslate })
    }
  }

  const updateVoiceList = data => {
    const voiceArr = Object.entries(data)
    const values = Object.values(data)
    const allLanguage = values.map(t => t.language)
    const languages = allLanguage.reduce((all, item) => {
      return all.includes(item) ? all : [...all, item]
    }, [])

    updateState({
      voiceList: [...voiceArr, ...state.voiceList],
      languages: [...languages, ...state.languages]
    })
  }

  const onChangeText = e => {
    updateState({
      src: '',
      autoPlay: false,
      selectedText: e.target.value
    })
  }

  const onClickCard = value => {
    updateState({
      src: '',
      autoPlay: false,
      selectedVoice: value[0]
    })
  }

  const postTTS = (txt, type) => {
    if (!postTTSVoices) return

    postTTSVoices({
      voice: state.selectedVoice,
      text: txt
    })
      .then(data => {
        if (data && data.result && data.data) {
          const audioSrc = `data:${data.data.mimetype};base64,${data.data.audio}`
          updateState({
            [type === 'preview' ? 'sampleSrc' : 'src']: audioSrc
          })
        }
      })
      .catch(error => {
        console.error('TTS error:', error)
      })
  }

  const onClickButton = async () => {
    const { src, selectedText, selectedVoice, voiceList } = state

    const premvoicelist = voiceList
      .map(item => (item.premium && item.premium === true ? item[0] : null))
      .filter(Boolean)

    if (premvoicelist.includes(selectedVoice)) {
      if (_.isEmpty(src)) {
        updateState({ processAudio: true })
      }

      if (!_.isEmpty(src)) {
        const filename = `voiceover${Math.floor(
          1000 + Math.random() * 9000
        )}.mp3`
        try {
          const response = await fetch(src, { mode: 'cors' })
          const blob = await response.blob()
          const file = new File([blob], filename, { type: 'audio/mpeg' })
          onShowDetailModal(file, selectedText)
          return
        } catch (error) {
          console.error('Error processing audio file:', error)
        }
      }

      try {
        const dataForPreview = {
          text: selectedText,
          model_name: selectedVoice
        }

        let res
        if (
          selectedVoice.includes('matea') ||
          selectedVoice.includes('gerel')
        ) {
          res = await api.user.getNonEnglishAudioPreview(dataForPreview)
        } else if (selectedVoice.includes('sharelook')) {
          res = await api.user.getEnglishAudioPreview(dataForPreview)
        } else {
          res = await api.audioSynthesizer.postNewAudioAdminRes(dataForPreview)
        }

        if (res && res.data) {
          updateState({
            autoPlay: true,
            src: res.data.s3_presigned_url,
            processAudio: false
          })
        }
      } catch (err) {
        console.error('Error generating audio preview:', err)
        updateState({ processAudio: false })
      }
      return
    }

    if (!_.isEmpty(src)) {
      const [, base64Data] = src.split(',')
      const filename = `voiceover${Math.floor(1000 + Math.random() * 9000)}.mp3`
      const mp3File = saveBase64AsAudioFile(filename, base64Data, 'audio/mpeg')
      onShowDetailModal(mp3File, selectedText)
      return
    }

    updateState({
      sampleSrc: '',
      processAudio: false
    })
    postTTS(selectedText, 'create')
  }

  const onClickPreviewIcon = async value => {
    updateState({
      src: '',
      autoPlay: true,
      selectedVoice: value[0],
      sampleSrc: '',
      audioprevLoading: true
    })

    if (value.premium && value.premium === true) {
      try {
        const dataForPreview = {
          text: value[1] && value[1].sample,
          model_name: value[0]
        }

        let res
        if (!value[1].file) {
          res = await api.user.getEnglishAudioPreview(dataForPreview)
        } else if (value[0].includes('matea') || value[0].includes('gerel')) {
          res = await api.audioSynthesizer.getNonEnglishVoicePreviewByFile(
            value[1].file
          )
        } else {
          res = await api.audioSynthesizer.getVoicePreviewByFile(value[1].file)
        }

        if (res && res.data) {
          updateState({
            autoPlay: true,
            selectedVoice: value[0],
            sampleSrc: res.data.s3_presigned_url,
            audioprevLoading: false
          })
        }
      } catch (err) {
        console.error('Error loading preview:', err)
        updateState({
          selectedVoice: value[0],
          sampleSrc: '',
          audioprevLoading: false
        })
      }
      return
    }

    if (value[1] && value[1].sample) {
      postTTS(value[1].sample, 'preview')
    }
    updateState({ audioprevLoading: false })
  }

  const onLanguageSelect = value => {
    const filteredList = state.voiceList.filter(v => v[1].language === value)
    updateState({
      languageValue: value,
      filteredList
    })
  }

  // New methods for ElevenLabs integration
  const handleProviderChange = e => {
    updateState({
      provider: e.target.value,
      selectedVoice: '',
      src: '',
      sampleSrc: ''
    })
  }

  const handleElevenLabsGenerate = async () => {
    if (!state.selectedElevenLabsVoice || !state.selectedText) return

    if (state.src) {
      handleElevenLabsPublish(state.src)
      return
    }

    updateState({ isGenerating: true })
    try {
      const audioUrl = await generateVoiceover(
        state.selectedText,
        state.selectedElevenLabsVoice,
        state.voiceSettings
      )
      updateState({ src: audioUrl })
    } catch (error) {
      console.error('Failed to generate audio:', error)
    } finally {
      updateState({ isGenerating: false })
    }
  }

  const handleElevenLabsPublish = async () => {
    if (!state.src) {
      message.error('No audio to publish. Please generate audio first.')
      return
    }

    try {
      // Fetch the audio blob from the URL
      const response = await fetch(state.src, { mode: 'cors' })
      const blob = await response.blob()
      const filename = `elevenlabs_voiceover${Math.floor(
        1000 + Math.random() * 9000
      )}.mp3`
      const file = new File([blob], filename, { type: 'audio/mpeg' })
      updateState({
        src: '',
        sampleSrc: ''
      })

      // Call onShowDetailModal with the file and text
      onShowDetailModal(file, state.selectedText)
    } catch (error) {
      console.error('Error processing ElevenLabs audio file:', error)
      message.error('Failed to publish audio. Please try again.')
    }
  }

  const handleElevenLabsPreview = async voice => {
    if (voice.preview_url) {
      updateState({ sampleSrc: voice.preview_url, src: '' })
    }
  }

  const renderElevenLabsVoiceList = () => {
    const filteredVoiceList = filterVoices(
      state.elevenLabsVoices,
      state.searchQuery
    )

    return (
      <List
        itemLayout="horizontal"
        dataSource={filteredVoiceList}
        renderItem={voice => (
          <List.Item
            style={{ minWidth: '220px' }}
            actions={[
              <span
                style={{ color: '#1890ff', cursor: 'pointer' }}
                key="preview"
                role="button"
                tabIndex="0"
              >
                <Tooltip title="Preview">
                  <PlayCircleOutlined
                    onClick={() => handleElevenLabsPreview(voice)}
                  />
                </Tooltip>
              </span>
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  src={
                    state.selectedElevenLabsVoice &&
                    state.selectedElevenLabsVoice.voice_id === voice.voice_id
                      ? activateSoundIcon
                      : deactiveSoundIcon
                  }
                />
              }
              style={{ cursor: 'pointer' }}
              onClick={() => updateState({ selectedElevenLabsVoice: voice })}
              title={voice.name}
            />
          </List.Item>
        )}
      />
    )
  }

  const {
    src,
    voiceList,
    selectedText,
    selectedVoice,
    languages,
    languageValue,
    filteredList,
    sampleSrc,
    processAudio,
    audioprevLoading
  } = state

  const allList = _.isEmpty(languageValue) ? voiceList : filteredList
  const maleList = allList.filter(m => m[1].gender === 'Male')
  const femaleList = allList.filter(f => f[1].gender === 'Female')
  const neuralList = allList.filter(n => n[1].neural)

  const renderVoiceList = dataSource => (
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={item => (
        <List.Item
          style={{ minWidth: '220px' }}
          actions={[
            <span
              style={{ color: '#1890ff', cursor: 'pointer' }}
              key="list-loadmore-edit"
              role="button"
              tabIndex="0"
            >
              <Tooltip title="Preview">
                {item && item[1].sample !== '' && (
                  <PlayCircleOutlined
                    onClick={() => onClickPreviewIcon(item)}
                  />
                )}
              </Tooltip>
            </span>
          ]}
        >
          <List.Item.Meta
            avatar={
              <Avatar
                src={
                  selectedVoice === item[0]
                    ? activateSoundIcon
                    : deactiveSoundIcon
                }
              />
            }
            style={{ cursor: 'pointer' }}
            onClick={() => onClickCard(item)}
            title={`${item[0]}.mp3`}
          />
        </List.Item>
      )}
    />
  )

  return (
    <div className="bg-modal">
      <Row gutter={24}>
        <Col span={16}>
          <div className="d-flex justify-content-between">
            <Radio.Group
              value={state.provider}
              onChange={handleProviderChange}
              style={{ marginBottom: '20px' }}
            >
              <Radio.Button value="default">Default TTS</Radio.Button>
              <Radio.Button value="elevenlabs">ElevenLabs</Radio.Button>
            </Radio.Group>

            <Select
              showSearch
              style={{ width: '200px' }}
              placeholder={t('v4:translate_to')}
              optionFilterProp="children"
              onChange={handleChangeOption}
            >
              {translationKeys.map(item => (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="textarea-warp">
            <TextArea
              disabled={!!audioSynthesizer.isProcessing || state.isGenerating}
              value={state.selectedText}
              onChange={onChangeText}
              placeholder={t('audio:type_something')}
              autoSize={{ minRows: 25, maxRows: 25 }}
            />
            <div className={isSafari ? 'audio-warp-safari' : 'audio-warp'}>
              {state.src && (
                <AudioPlayer src={state.src} autoPlay={Boolean(state.src)} />
              )}
              {state.provider === 'default' ? (
                <Button
                  type="submit"
                  shape="round"
                  size="default"
                  disabled={
                    _.isEmpty(state.selectedText) ||
                    _.isEmpty(state.selectedVoice) ||
                    audioSynthesizer.isProcessing ||
                    state.processAudio
                  }
                  className="button-create btn-success"
                  onClick={onClickButton}
                >
                  {audioSynthesizer.isProcessing || state.processAudio
                    ? t('media:processing_audio')
                    : !_.isEmpty(state.src)
                    ? t('v4:publish')
                    : t('media:create')}
                </Button>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  size="default"
                  disabled={
                    !state.selectedElevenLabsVoice ||
                    !state.selectedText ||
                    state.isGenerating
                  }
                  onClick={handleElevenLabsGenerate}
                >
                  {state.isGenerating
                    ? 'Generating...'
                    : !_.isEmpty(state.src)
                    ? t('v4:publish')
                    : 'Generate with ElevenLabs'}
                </Button>
              )}
            </div>
          </div>
        </Col>
        <Col span={8}>
          {state.provider === 'default' ? (
            // Original voice selection UI
            <>
              <Row>
                <Col span={6}>
                  <Select
                    showSearch
                    style={{ width: 225 }}
                    placeholder="Select language"
                    optionFilterProp="children"
                    onChange={onLanguageSelect}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {state.languages.map(language => (
                      <Select.Option key={language} value={language}>
                        {language}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Tabs animated={false}>
                <TabPane tab={t('media:all')} key="1">
                  <div style={{ height: '470px', overflow: 'auto' }}>
                    {renderVoiceList(allList)}
                  </div>
                </TabPane>
                <TabPane tab={t('media:male')} key="2">
                  <div style={{ height: '470px', overflow: 'auto' }}>
                    {renderVoiceList(maleList)}
                  </div>
                </TabPane>
                <TabPane tab={t('media:female')} key="3">
                  <div style={{ height: '470px', overflow: 'auto' }}>
                    {renderVoiceList(femaleList)}
                  </div>
                </TabPane>
                <TabPane tab={t('v4:human')} key="4">
                  <div style={{ height: '470px', overflow: 'auto' }}>
                    {renderVoiceList(neuralList)}
                  </div>
                </TabPane>
              </Tabs>
            </>
          ) : (
            // ElevenLabs voice selection UI
            <>
              <Input.Search
                placeholder="Search voices..."
                value={state.searchQuery}
                onChange={e => updateState({ searchQuery: e.target.value })}
                style={{ marginBottom: '20px' }}
              />
              <div style={{ height: '470px', overflow: 'auto' }}>
                {renderElevenLabsVoiceList()}
              </div>
            </>
          )}
          <Spin spinning={state.audioprevLoading}>
            <div className={isSafari ? 'audio-warp-safari' : 'audio-warp'}>
              {state.sampleSrc && (
                <AudioPlayer
                  src={state.sampleSrc}
                  autoPlay={Boolean(state.sampleSrc)}
                />
              )}
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

// Prop types (optional but recommended)
CreateAudio.propTypes = {
  listTTSVoices: PropTypes.func,
  postTTSVoices: PropTypes.func,
  text: PropTypes.string,
  onShowDetailModal: PropTypes.func,
  audioSynthesizer: PropTypes.shape({
    isProcessing: PropTypes.bool
  })
}

CreateAudio.defaultProps = {
  text: '',
  audioSynthesizer: {
    isProcessing: false
  }
}

export default CreateAudio
