import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import className from 'classnames'
import { Tabs, Row, Col, Modal, Input, Pagination, Spin } from 'antd'
import { general, config } from '../../../../../../../constants'
import { ImageCard } from './components'
import PexelCard from './components/ImageCard/PexelCard'
import { DropzoneImage, FilterImage, ShowDetailImage } from './internal/View'
import Spinner from '../../../../../../../components/Spinner/Spinner'
import { withTranslation } from 'react-i18next'
import { removeMedias } from '../../../../../../../services/api/library'
import { api } from '../../../../../../../services'
import './styles.scss'

class Image extends Component {
  state = {
    isFetching: true,
    data: {},
    pexelsData: [],
    pagePublic: 1,
    publicLibrary: {},
    orgLibrary: {},
    pageSizePublic: 9,
    pageSizeOrg: 9,
    libraryType: general.IMAGE,
    tab: general.MY_MEDIA_LIBRARY,
    isRemovingMedia: false,
    fileNotCompatible: false,
    fileSizeExceed: false,
    cardSelected: '',
    search: '',
    searchPublic: '',
    searchOrg: '',
    sortBy: 'NEWEST',
    page: 1,
    pageSize: 6,
    pageOrg: 1,
    stockPage: 1,
    stockPerPage: 10,
    stockQuery: '',
    stockTotalResults: '',
    orgMediaLoaded: false,
    publicMediaLoaded: false,
    imgLoading: false,
    canvaUploadProgress: false
  }

  componentDidMount() {
    this.handleGetLibraryList()
  }

  componentDidUpdate(prevProps) {
    const { newlyUploadedCroppedBgImage } = this.props
    if (prevProps.newlyUploadedCroppedBgImage !== newlyUploadedCroppedBgImage) {
      this.handleGetLibraryList(true)
    }
  }

  handleGetLibraryList = async (resetSelectedItem = false) => {
    const {
      getLibraryList,
      library: { isFetching },
      newlyUploadedCroppedBgImage
    } = this.props
    const { page, pageSize, libraryType, search, sortBy } = this.state
    this.setState({
      isFetching,
      isRemovingMedia: true
    })
    await getLibraryList({
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      search,
      sort: sortBy,
      type: libraryType
    })

    const {
      library: { data }
    } = this.props
    this.setState({ data, isFetching: false, isRemovingMedia: false }, () => {
      if (resetSelectedItem)
        this.handleViewDetails(
          newlyUploadedCroppedBgImage.id,
          newlyUploadedCroppedBgImage
        )
    })
  }

  getMediaListing = list => {
    return list ? list.rows : []
  }

  allowUpdateStatus = type => {
    const {
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    if (isSuperAdmin) {
      return type === general.MY_MEDIA_LIBRARY
    }
    return false
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  handleGetPublicMedia = async () => {
    const { getPublicList } = this.props
    const {
      pageSizePublic,
      pagePublic,
      libraryType,
      searchPublic,
      sortBy
    } = this.state

    await getPublicList({
      offset: this.createOffset(pagePublic, pageSizePublic),
      limit: pageSizePublic,
      type: libraryType,
      search: searchPublic,
      sort: sortBy,
      isPublic: true,
      publicMediaLoaded: false
    })

    const {
      library: { publicLibrary }
    } = this.props
    this.setState({
      publicLibrary,
      isFetching: false
    })
  }

  handleGetOrgList = async () => {
    const { getOrgList } = this.props
    const { pageOrg, pageSizeOrg, searchOrg, libraryType, sortBy } = this.state

    await getOrgList({
      offset: this.createOffset(pageOrg, pageSizeOrg),
      limit: pageSizeOrg,
      search: searchOrg,
      sort: sortBy,
      type: libraryType,
      isOrg: true,
      orgMediaLoaded: false
    })

    const { orgLibrary } = this.props.library
    this.setState({
      orgLibrary,
      isFetching: false
    })
  }

  handleChangeTabs = async tab => {
    const { publicMediaLoaded, orgMediaLoaded } = this.state
    const { changeTabs } = this.props
    changeTabs()
    this.setState({
      tab,
      cardSelected: ''
    })
    if (tab === general.PUBLIC_MEDIA_LIBRARY && !publicMediaLoaded) {
      this.setState({ isFetching: true })
      await this.handleGetPublicMedia()
      this.setState({
        publicMediaLoaded: true,
        isFetching: false
      })
    } else if (tab === general.ORGANIZATION_LIBRARY && !orgMediaLoaded) {
      this.setState({ isFetching: true })
      await this.handleGetOrgList()
      this.setState({
        orgMediaLoaded: true,
        isFetching: false
      })
    }
  }

  useCanva = () => {
    const { isLandScape } = this.props
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: isLandScape ? 'Presentation' : 'Poster'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title) => {
    const { upload } = this.props
    this.setState({
      canvaUploadProgress: true
    })
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title,
      size: blob.size,
      type: blob.type
    }
    upload(body).then(() => {
      this.setState(
        {
          canvaUploadProgress: false,
          tab: general.MY_MEDIA_LIBRARY
        },
        () => {
          this.handleGetLibraryList()
        }
      )
    })
  }

  onEditImageDetail = id => {
    const { showEditImageModal } = this.props
    showEditImageModal({
      contentId: id,
      isShowEditImage: true
    })
  }

  onDrop = dropped => {
    const { upload } = this.props
    if (dropped.length === 0) {
      this.setState({ fileNotCompatible: true })
    } else if (dropped[0].size > 2500000) {
      this.setState({ fileSizeExceed: true })
    } else {
      return upload(dropped[0])
    }
  }

  /**
   * Check upload progress bar status
   */
  checkedCompleteUpload = item => {
    const {
      library: { uploadSuccess }
    } = this.props
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )
    return !(uploadSuccess.length && filterSuccessFiles.length)
  }

  uploadListClassName = list => {
    return list.length <= 0 ? 'upload-modal__list-wrapper__no-border' : ''
  }

  handlePrivateFileSelect = item => {
    const { data } = this.state
    const { rows } = data
    const newRows = rows.map(value => {
      value.isSelected = value.id === item.id
      return value
    })

    return {
      count: data.count,
      rows: newRows
    }
  }

  handlePublicFileSelect = item => {
    const { publicLibrary } = this.state
    const { rows } = publicLibrary
    const newRows = rows.map(value => {
      if (value.id === item.id) {
        value.isSelected = !value.isSelected
      } else {
        value.isSelected = false
      }
      return value
    })

    return {
      count: publicLibrary.count,
      rows: newRows
    }
  }

  handleOrgFileSelect = item => {
    const { orgLibrary } = this.state
    const { rows } = orgLibrary
    const newRows = rows.map(value => {
      if (value.id === item.id) {
        value.isSelected = !value.isSelected
      } else {
        value.isSelected = false
      }
      return value
    })

    return {
      count: orgLibrary.count,
      rows: newRows
    }
  }

  handleViewDetails = (id, item) => {
    const { isPageBuilder, handleInsertData } = this.props
    const { publicLibrary, orgLibrary } = this.state

    if (isPageBuilder && id) {
      const newPrivateMediaData = this.handlePrivateFileSelect(item)
      const newPublicMediaData = !_.isEmpty(publicLibrary)
        ? this.handlePublicFileSelect(item)
        : null
      const newOrgMediaData = !_.isEmpty(orgLibrary)
        ? this.handleOrgFileSelect(item)
        : null

      this.setState(
        {
          data: newPrivateMediaData,
          publicLibrary: newPublicMediaData || null,
          orgLibrary: newOrgMediaData || null,
          cardSelected: id
        },
        () => {
          handleInsertData(
            newPrivateMediaData,
            newPublicMediaData,
            newOrgMediaData
          )
        }
      )
    }
  }

  handleViewDetailsStockImage = (id, item) => {
    const { isPageBuilder, handleInsertStockImageData } = this.props
    const temp = {
      id: item.id,
      link: item.src.medium,
      isSelected: true,
      media_format: 'IMAGE'
    }

    if (isPageBuilder && id) {
      this.setState(
        {
          cardSelected: id
        },
        () => {
          handleInsertStockImageData(temp)
        }
      )
    }
  }

  resetFileInCompatibleMessage = () => {
    this.setState({ fileNotCompatible: false, fileSizeExceed: false })
  }

  handleRemoveImage = async imageId => {
    this.setState({ isRemovingMedia: true, cardSelected: '' })
    const data = {
      mediaIds: [imageId]
    }
    await removeMedias(data)
    await this.handleGetLibraryList()
    await this.handleGetPublicMedia()
    await this.handleGetOrgList()
  }

  removeCardSelection = () => {
    this.setState({ cardSelected: '' })
  }

  onChangePage = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
        isFetching: true,
        cardSelected: ''
      },
      () => {
        this.handleGetLibraryList()
      }
    )
  }

  onChangePagePublic = (pagePublic, pageSizePublic) => {
    this.setState(
      {
        pagePublic,
        pageSizePublic,
        isFetching: true,
        cardSelected: ''
      },
      () => {
        this.handleGetPublicMedia()
      }
    )
  }

  onChangePageOrg = (pageOrg, pageSizeOrg) => {
    this.setState(
      {
        pageOrg,
        pageSizeOrg,
        isFetching: true
      },
      this.handleGetOrgList
    )
  }

  handlePagination = (type, event) => {
    const { pageSize, pageSizePublic, pageSizeOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return this.onChangePagePublic(event, pageSizePublic)
    } else if (type === general.MY_MEDIA) {
      this.onChangePage(event, pageSize)
    } else {
      return this.onChangePageOrg(event, pageSizeOrg)
    }
  }

  getCurrent = type => {
    const { page, pagePublic, pageOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return pagePublic
    } else if (type === general.MY_MEDIA) {
      return page
    } else {
      return pageOrg
    }
  }

  getPageSize = type => {
    const { pageSizePublic, pageSize, pageSizeOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return pageSizePublic
    } else if (type === general.MY_MEDIA) {
      return pageSize
    } else {
      return pageSizeOrg
    }
  }

  getNotFoundMsg = (type, t) => {
    if (type === general.PUBLIC_MEDIA_LIBRARY) {
      return t('image:public_image_not_found')
    } else if (type === general.ORGANIZATION_LIBRARY) {
      return t('v4:org_image_not_found')
    } else {
      return t('image:private_image_not_found')
    }
  }

  onDoubleClick = (id, data) => {
    const { onDoubleClickCrop } = this.props
    if (onDoubleClickCrop) {
      onDoubleClickCrop()
      this.handleViewDetails(id, data)
    }
  }

  renderImage = (imageList, type) => {
    if (imageList === null) {
      return
    }
    const { t, isPageBuilder } = this.props
    const { rows } = imageList
    const {
      cardSelected,
      page,
      pageSize,
      pagePublic,
      pageSizePublic
    } = this.state
    const NOT_FOUND_MESSAGE = this.getNotFoundMsg(type, t)
    const classNames = className(
      'media',
      { 'media-page__item': !cardSelected },
      { 'media-page__item-on-select': cardSelected }
    )
    const currentPage = this.getCurrent(type)
    const currentPageSize = this.getPageSize(type)
    return (
      <Fragment>
        {!imageList || _.isEmpty(rows) ? (
          <div className="media-page__no-medial-list">{NOT_FOUND_MESSAGE}</div>
        ) : cardSelected ? (
          <Fragment>
            <Row className="bg-modal">
              <Col span={14}>
                {' '}
                <div className="media-page__list bg-modal-left">
                  {this.getMediaListing(imageList).map(item => (
                    <div className={classNames} key={item.id}>
                      <ImageCard
                        data={item}
                        type={type}
                        onDoubleClick={this.onDoubleClick}
                        confirmDeleteItem={this.confirmDeleteItem}
                        onEditImageDetail={this.onEditImageDetail}
                        handleViewDetails={this.handleViewDetails}
                        isPageBuilder={isPageBuilder}
                        cardSelected={
                          item.id === cardSelected ? cardSelected : ''
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="media-page__controls">
                  <Pagination
                    total={imageList.count}
                    // current={
                    //   type === general.PUBLIC_MEDIA_LIBRARY ? pagePublic : page
                    // }
                    // pageSize={
                    //   type === general.PUBLIC_MEDIA_LIBRARY
                    //     ? pageSizePublic
                    //     : pageSize
                    // }
                    // onChange={
                    //   type === general.PUBLIC_MEDIA_LIBRARY
                    //     ? this.onChangePagePublic
                    //     : this.onChangePage
                    // }
                    current={currentPage}
                    pageSize={currentPageSize}
                    onChange={event => this.handlePagination(type, event)}
                  />
                </div>
              </Col>
              <Col span={10}>
                <ShowDetailImage
                  handleRemoveImage={this.handleRemoveImage}
                  t={t}
                  data={rows}
                  removeCardSelection={this.removeCardSelection}
                />
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Fragment>
            <div className="media-page__list bg-modal">
              {this.getMediaListing(imageList).map(item => (
                <div className={classNames} key={item.id}>
                  <ImageCard
                    data={item}
                    type={type}
                    onDoubleClick={this.onDoubleClick}
                    confirmDeleteItem={this.confirmDeleteItem}
                    onEditImageDetail={this.onEditImageDetail}
                    handleViewDetails={this.handleViewDetails}
                    isPageBuilder={isPageBuilder}
                    cardSelected={item.id === cardSelected ? cardSelected : ''}
                  />
                </div>
              ))}
            </div>
            <div className="media-page__controls">
              <Pagination
                total={imageList.count}
                current={
                  type === general.PUBLIC_MEDIA_LIBRARY ? pagePublic : page
                }
                pageSize={
                  type === general.PUBLIC_MEDIA_LIBRARY
                    ? pageSizePublic
                    : pageSize
                }
                onChange={
                  type === general.PUBLIC_MEDIA_LIBRARY
                    ? this.onChangePagePublic
                    : this.onChangePage
                }
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }

  onSearch = _.debounce((value, type) => this.onHandleSearch(value, type), 1000)

  onHandleSearch = (value, type) => {
    if (type === general.MY_MEDIA_LIBRARY) {
      this.setState(
        {
          search: value,
          page: 1,
          isFetching: true
        },
        () => {
          this.handleGetLibraryList()
        }
      )
    } else if (type === general.PUBLIC_MEDIA_LIBRARY) {
      this.setState(
        {
          searchPublic: value,
          pagePublic: 1,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    } else {
      this.setState(
        {
          searchOrg: value,
          pageOrg: 1,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  onFilter = (order, tab) => {
    this.setState({ isFetching: true })
    // Filter for My Media tab
    if (tab === general.MY_MEDIA_LIBRARY) {
      this.setState(
        {
          page: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetLibraryList()
        }
      )
    }

    // Filter for Public Library tab
    if (tab === general.PUBLIC_MEDIA_LIBRARY) {
      this.setState(
        {
          pagePublic: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    }

    // Filter for Org Library tab
    if (tab === general.ORGANIZATION_LIBRARY) {
      this.setState(
        {
          pageOrg: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  onOkClicked = id => this.handleRemoveImage(id)

  onCancelClicked = () => {
    console.log('Item removal cancelled')
  }

  confirmDeleteItem = ({ data: { title, id } }) => {
    const { t } = this.props
    Modal.confirm({
      title: t('audio:are_you_sure_to_delete'),
      centered: true,
      content: `${title}.png`,
      okText: general.OK,
      cancelText: general.CANCEL,
      onOk: () => this.onOkClicked(id),
      onCancel: this.onCancelClicked
    })
  }

  onSearchStockImages = val => {
    const { stockPage, stockPerPage, stockQuery } = this.state
    this.setState(
      {
        isFetching: true,
        stockQuery: val !== undefined ? val : stockQuery
      },
      () => {
        const tempData = {
          query: val !== undefined ? val : stockQuery,
          perPage: stockPerPage,
          page: stockPage
        }

        api.library
          .getStockImages(tempData)
          .then(response => {
            this.setState({
              pexelsData: response.data.photos,
              stockTotalResults: response.data.total_results,
              stockPage: response.data.page,
              stockPerPage: response.data.per_page,
              isFetching: false
            })
          })
          .catch(err => {
            console.log('Data tab 4 error: ', err)
          })
      }
    )
  }

  onChangeStockPage = stockPage => {
    this.setState(
      {
        stockPage,
        isFetching: true
      },
      this.onSearchStockImages
    )
  }

  renderStockImage = () => {
    const { t } = this.props
    const {
      cardSelected,
      pexelsData,
      stockPage,
      stockPerPage,
      stockTotalResults
    } = this.state

    return (
      <Fragment>
        {_.isEmpty(pexelsData) ? (
          <div className="media-page__no-medial-list">
            {t('image:public_image_not_found')}
          </div>
        ) : (
          <Fragment>
            <div className="media-page__list bg-modal">
              {pexelsData.map(item => (
                <div className="media media-page__item" key={item.id}>
                  <PexelCard
                    data={item}
                    confirmDeleteItem={this.confirmDeleteItem}
                    handleViewDetailsStockImage={
                      this.handleViewDetailsStockImage
                    }
                    cardSelected={item.id === cardSelected ? cardSelected : ''}
                  />
                </div>
              ))}
            </div>
            <div className="media-page__controls">
              <Pagination
                total={stockTotalResults}
                current={stockPage}
                pageSize={stockPerPage}
                onChange={this.onChangeStockPage}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }

  render() {
    const {
      publicLibrary,
      orgLibrary,
      isFetching,
      data,
      fileNotCompatible,
      fileSizeExceed,
      isRemovingMedia,
      tab
    } = this.state
    const { t, library, getSelectedFile, changeBackground, src } = this.props
    const { uploadProgress } = library
    const { TabPane } = Tabs
    const { Search } = Input

    return (
      <Fragment>
        <div className="tab-nav-margin">
          <Tabs
            activeKey={this.state.tab}
            onChange={this.handleChangeTabs}
            animated={false}
          >
            <TabPane tab={t('media:my_media')} key={general.MY_MEDIA_LIBRARY}>
              <FilterImage
                onSearch={this.onSearch}
                t={t}
                tab={tab}
                onFilter={this.onFilter}
                type={general.MY_MEDIA_LIBRARY}
              />
              {isFetching || isRemovingMedia ? (
                <Spinner />
              ) : (
                this.renderImage(data, general.MY_MEDIA_LIBRARY)
              )}
            </TabPane>
            <TabPane
              tab={t('media:public_library')}
              key={general.PUBLIC_MEDIA_LIBRARY}
            >
              <FilterImage
                onSearch={this.onSearch}
                t={t}
                tab={tab}
                onFilter={this.onFilter}
                type={general.PUBLIC_MEDIA_LIBRARY}
              />
              {isFetching || isRemovingMedia ? (
                <Spinner />
              ) : (
                this.renderImage(publicLibrary, general.PUBLIC_MEDIA_LIBRARY)
              )}
            </TabPane>

            <TabPane
              tab={t('media:organization_library')}
              key={general.ORGANIZATION_LIBRARY}
            >
              <FilterImage
                onSearch={this.onSearch}
                t={t}
                tab={tab}
                onFilter={this.onFilter}
                type={general.ORGANIZATION_LIBRARY}
              />
              {isFetching || isRemovingMedia ? (
                <Spinner />
              ) : (
                this.renderImage(orgLibrary, general.ORGANIZATION_LIBRARY)
              )}
            </TabPane>

            <TabPane
              tab={t('media:upload_from_computer')}
              key={general.UPLOAD_FROM_COMPUTER}
            >
              <DropzoneImage
                t={t}
                getSelectedFile={getSelectedFile}
                uploadProgress={uploadProgress}
                fileNotCompatible={fileNotCompatible}
                fileSizeExceed={fileSizeExceed}
                src={src}
                onDrop={this.onDrop}
                uploadListClassName={this.uploadListClassName}
                checkedCompleteUpload={this.checkedCompleteUpload}
                resetFileInCompatibleMessage={this.resetFileInCompatibleMessage}
              />
            </TabPane>
            <TabPane
              tab={t('v4:pexel_images')}
              key={general.IMAGES_FROM_PEXELS}
            >
              <div className="filter filter-spacing workshop-head-wrapper">
                <Row>
                  <Col span={12}>
                    <Search
                      placeholder={t('placeholders:search')}
                      onChange={e => this.onSearchStockImages(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              {isFetching ? <Spinner /> : this.renderStockImage()}
            </TabPane>
            {!changeBackground && (
              <TabPane tab={t('v4:canva')} key={general.CANVA_EDIT}>
                <Spin spinning={this.state.canvaUploadProgress}>
                  <div
                    className="canva-container"
                    onClick={() => this.useCanva()}
                  >
                    {t('v4:canva_open')}
                  </div>
                </Spin>
              </TabPane>
            )}
          </Tabs>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('buttons')(Image)
