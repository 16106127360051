import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import './ViewChannelDisplay.scss';

function ViewChannelDisplay({ description, previewFile, isVideo = false, handleAutoNext }) {
  const fileLink = useMemo(
    () =>
      previewFile && previewFile.type === 'youtube'
        ? `https://www.youtube.com/watch?v=${previewFile.id}`
        : previewFile && previewFile.link,
    [previewFile]
  );

  const { t } = useTranslation();
  return (
    <div className="view-channel-display">
      {isVideo ? (
        <ReactPlayer
          url={fileLink}
          className="view-channel-display-file"
          controls
          playing
          width="100%"
          height="100%"
          onEnded={handleAutoNext}
        />
      ) : (
        <img
          className="view-channel-display-file"
          src={fileLink}
          alt="Preview File"
        />
      )}
      {description && (
        <>
          <div className="ssl-channel-description">{t('v3:video_des')}</div>
          <div className="view-channel-descption">
            <p>{description}</p>
          </div>
          <div style={{ height: '30px' }}></div>
        </>
      )}
    </div>
  );
}

export default ViewChannelDisplay;
