import React, { useState, useEffect, useMemo } from 'react'

import { getCertificatesById } from '../../../../services/api/certificate'
import { getBadgesById } from '../../../../services/api/badge'

import { report } from '../../../../constants'
import { Col, Icon, Row, Tabs, Tooltip } from 'antd'
import ReportHeader from '../../components/ReportHeader'
import { CustomTabPane } from '../../styled'
import { api } from '../../../../services'
import InfoIcon from '../../../../assets/info_icon.png'

import { OverviewUserReport, StatsContainer, UserReportTable } from './internal'
import { withTranslation } from 'react-i18next'

import './detail.scss'
import _ from 'lodash'
import { generateCSV, getMinutes } from '../../../../utils'
import moment from 'moment'
import { isSafari } from 'react-device-detect'
import history from '../../../../history'
import CategoryChart from '../../components/CategoryChart'
import CategoryGraph from '../../components/CategoryGraph'

const { TabPane } = Tabs

const workShopType = {
  today: 1,
  week: 7,
  month: 30,
  year: 12,
  allTime: 5,
  other: 2
}

const defaultDateFilter = {
  date: 'allTime',
  value: '5',
  dateTo: null,
  dateFrom: null,
  loading: false
}

const UserReport = props => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    courses: { data: [], loading: true },
    mazes: { data: [], loading: true },
    events: { data: [], loading: true },
    quizzes: { data: [], loading: true },
    skills: { data: [], loading: true },
    assessments: { data: [], loading: true },
    trivia: { data: [], loading: true }
  })
  const [user, setUser] = useState(null)
  const [badges, setBadges] = useState([])
  const [certificates, setCertificates] = useState([])
  const [dateFilter, setDateFilter] = useState(defaultDateFilter)
  const [activeType, setActiveType] = useState(report.OVERVIEW)

  const {
    t,
    match: {
      params: { id, userType }
    },
    userInfo: {
      info: { organization, role, is_super_admin, active_organization_id }
    }
  } = props
  const [isUserAsHost, setIsUserAsHost] = useState(
    userType === report.USER_TYPE_HOST
  )

  const fetchCertificatesAndBadges = () => {
    getCertificatesById(id).then(res => {
      setCertificates(res.data)
    })

    getBadgesById(id).then(res => {
      setBadges(res.data)
    })
  }

  const setUserRole = () => {
    setIsUserAsHost(userType === report.USER_TYPE_HOST)
  }

  const loadUserData = () => {
    const { date, dateFrom, dateTo } = dateFilter
    const defaultParams = {
      userType: isUserAsHost ? report.HOST_USER : report.PARTICIPANT_USER,
      id: id,
      offset: '',
      limit: '',
      // is_total: true,
      search: '',
      ...(date && { date: date }),
      ...(dateFrom && { from: dateFrom }),
      ...(dateTo && { to: dateTo })
    }

    fetchData(defaultParams)
  }

  const fetchUserInformation = id => {
    api.user.getDbUser(id).then(res => setUser(res.data))
  }

  const setDataLoading = val => {
    let myObj = data
    for (const item in myObj) {
      myObj[item].loading = val
    }

    setData(myObj)
  }

  const enable_maze = useMemo(
    () => (organization && organization.enable_maze) || false,
    [organization]
  )

  const fetchData = params => {
    const dataTypes = [
      {
        type: 'course',
        value: 'courses'
      },
      {
        type: 'event',
        value: 'events'
      },
      {
        type: 'live_assessment',
        value: 'assessments'
      },
      {
        type: 'quiz',
        value: 'quizzes'
      },
      {
        type: 'skill',
        value: 'skills'
      },
      enable_maze && {
        type: 'maze',
        value: 'mazes'
      },
      {
        type: 'trivia',
        value: 'trivia'
      }
    ].filter(item => item)

    setLoading(true)
    setDataLoading(true)

    dataTypes.forEach((item, index) => {
      api.reports[item.type === 'maze' ? 'getUserMazeReport' : 'getUserData']({
        ...params,
        type: item.type
      })
        .then(res => {
          setData(data => ({
            ...data,
            [item.value]: {
              data:
                item.type === 'trivia'
                  ? res.data
                    ? res.data
                    : []
                  : item.type === 'maze'
                  ? _.isEmpty(res.data)
                    ? []
                    : res.data.data.programmes
                  : item.type === 'quiz'
                  ? res.data.quizzes
                  : res.data[`${item.type}s`],
              loading: false
            }
          }))
          if (index === dataTypes.length - 1) {
            setLoading(false)
          }
        })
        .catch(err => {
          setData(data => ({
            ...data,
            [item.value]: {
              data: [],
              loading: false
            }
          }))
          setLoading(false)
        })
    })
  }
  const mapByType = (arr = [], type) =>
    arr.map(item => ({ ...item, type: type }))

  const overviewData = useMemo(() => {
    const {
      courses,
      mazes,
      events,
      skills,
      quizzes,
      assessments,
      trivia
    } = data
    const overview = [
      ...mapByType(courses.data, 'Course'),
      ...mapByType(events.data, 'Event'),
      ...mapByType(mazes.data, 'MetaMaze'),
      ...mapByType(skills.data, 'Skill Journey'),
      ...mapByType(assessments.data, 'Live Assessment'),
      ...mapByType(quizzes.data, 'Quiz'),
      ...mapByType(trivia.data, 'Trivia')
    ]
    return overview
  }, [data])

  useEffect(() => {
    fetchCertificatesAndBadges()
    fetchUserInformation(id)
    setUserRole()
  }, [])

  useEffect(() => {
    if (loading) return

    if (dateFilter.value === '2' && !dateFilter.dateTo && !dateFilter.dateFrom)
      return

    loadUserData()
  }, [isUserAsHost, dateFilter])
  const onDateChange = value => {
    const workshopDateType = Object.keys(workShopType).filter(function(key) {
      return workShopType[key] === value
    })[0]

    setDateFilter({
      date: workshopDateType,
      value: value
    })
  }

  const onCustomDate = async params => {
    setDateFilter({
      ...dateFilter,
      dateFrom: params[0],
      dateTo: params[1]
    })
  }

  const downloadType = () => {
    if (isUserAsHost) {
      downloadHostReport()
    } else {
      downloadParticipantReport()
    }
  }

  const downloadParticipantReport = () => {
    const {
      courses,
      mazes,
      events,
      skills,
      quizzes,
      assessments,
      trivia
    } = data
    let dataCSV = []
    let header = []

    if (activeType === report.OVERVIEW) {
      header = ['Title', 'Type', 'Created Date', 'Time Spent', 'Pass Status']
      dataCSV = overviewData.map(x => {
        return {
          label: x.title || x.name,
          type: x.type,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          spending_time: x.spending_time ? x.spending_time : 'N/A',
          pass_course:
            JSON.stringify(x.pass_course) === 'true' && x.completed
              ? 'Passed'
              : JSON.stringify(x.pass_course) === 'false' && x.completed
              ? 'Failed'
              : (JSON.stringify(x.pass_course) === 'true' ||
                  JSON.stringify(x.pass_course) === 'false') &&
                !x.completed
              ? 'Not Completed'
              : 'N/A'
        }
      })
    } else if (activeType === report.COURSE) {
      header = [
        'Course Id',
        'Title',
        'Author',
        'Participants Enrollments',
        'Quizzes',
        'Views',
        'Pass Score',
        'Avg Score',
        'Completed'
      ]
      dataCSV = courses.data.map(x => {
        return {
          id: x.id,
          title: x.title,
          host_name: x.host_name,
          enrollments: x.enrollments,
          quizzes: x.quizzes,
          views: x.views,
          pass_score: x.pass_score === null ? 'n/a' : x.pass_score,
          avg_scores: x.avg_scores === null ? 'n/a' : x.avg_scores,
          completed: x.completed ? 'Yes' : 'No'
        }
      })
    } else if (activeType === report.LIVE_EVENT) {
      header = [
        'Event Id',
        'Title',
        'Host Name',
        'Views',
        'Live Date',
        'No.of Comments',
        'No. of Poll Vote',
        'Broadcast started',
        'Broadcast ended',
        'Joined',
        'Leaved',
        'Watched time'
      ]
      dataCSV = events.data.map(x => {
        return {
          id: x.id,
          title: x.title,
          host_name: x.host_name,
          views: x.views,
          date: x.date,
          comment: x.comment.length,
          poll_vote: x.poll_vote.length,
          stream_start:
            x.stream_start !== null
              ? moment(x.stream_start).format('HH:mm')
              : 'n/a',
          stream_stop:
            x.stream_stop !== null
              ? moment(x.stream_stop).format('HH:mm')
              : 'n/a',
          connected_at:
            x.connected_at !== null
              ? moment(x.connected_at).format('HH:mm')
              : 'n/a',
          disconnected_at:
            x.disconnected_at !== null
              ? moment(x.disconnected_at).format('HH:mm')
              : 'n/a',
          watched_time:
            x.connected_at === null || x.disconnected_at === null
              ? 'n/a'
              : getMinutes(x.connected_at, x.disconnected_at)
        }
      })
    } else if (activeType === report.MAZE) {
      if (mazes && mazes.data.length === 0) return
      header = ['Title', 'Date', 'Host', 'Views', 'Pass Score', 'Score']
      dataCSV = mazes.data.map(x => {
        return {
          title: x.title,
          date: moment(x.created_at).fromNow(),
          host: x.host_name,
          views: x.session_play,
          pass_score: x.minimum_pass_score,
          score: x.averageScore
        }
      })
    } else if (activeType === report.LIVE_ASSESSMENT) {
      header = [
        'Assessment Id',
        'Title',
        'Host Name',
        'Views',
        'Date',
        'Pass Score',
        'Average Score',
        'No.of Questions'
      ]
      dataCSV = assessments.data.map(x => {
        return {
          id: x.id,
          title: x.title,
          host: x.host_name,
          views: x.views,
          date: moment(x.start_date_time).format('YYYY-MM-DDTHH:mm'),
          pass_score: x.pass_score,
          avg_scores:
            x.live_assessment_scores &&
            x.live_assessment_scores.reduce((total, item) => {
              return total + item.score
            }, 0) / x.live_assessment_scores.length,
          questions: x.live_assessment_questions.length
        }
      })
    } else if (activeType === report.QUIZ) {
      header = [
        'Quix Id',
        'Title',
        'Total Questions',
        'Total Score',
        'Score Obtained'
      ]
      dataCSV = quizzes.data.map(x => {
        return {
          id: x.id,
          title: x.title,
          total_questions: x.questions_count,
          total_scores: x.total_scores,
          obtained_scores: x.scores
        }
      })
    } else if (activeType === report.SKILL_JOURNEY) {
      header = ['id', 'Title', 'Minimum Pass Score', 'Resources', 'Spend Time']
      dataCSV = skills.data.map(x => {
        return {
          id: x.id,
          title: x.title,
          pass_score: x.pass_score,
          resources: x.resources && x.resources.length,
          spend_time: x.spend_time
        }
      })
    } else if (activeType === report.TRIVIA) {
      header = [
        'Category Name',
        'Failed Attempts',
        'Success Attempts',
        'Total Attempts'
      ]
      dataCSV = trivia.data.map(x => {
        return {
          category: x.name,
          failed: x.failedAttempts,
          success: x.successAttempts,
          total: x.totalAttempts,
          date: moment(x.created_at).format('YYYY-MM-DD')
        }
      })
    }
    getDownload(dataCSV, header)
  }

  const downloadHostReport = () => {
    const {
      courses,
      mazes,
      events,
      skills,
      quizzes,
      assessments,
      trivia
    } = data
    let dataCSV = []
    let header = []
    if (
      activeType === report.OVERVIEW &&
      overviewData &&
      overviewData.length > 0
    ) {
      header = ['Title', 'Type', 'Created Date', 'Time Spent', 'Pass Status']
      dataCSV = overviewData.map(x => {
        return {
          label: x.title || x.name,
          type: x.type,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          spending_time: x.spending_time ? x.spending_time : 'N/A',
          pass_course:
            JSON.stringify(x.pass_course) === 'true' && x.completed
              ? 'Passed'
              : JSON.stringify(x.pass_course) === 'false' && x.completed
              ? 'Failed'
              : (JSON.stringify(x.pass_course) === 'true' ||
                  JSON.stringify(x.pass_course) === 'false') &&
                !x.completed
              ? 'Not Completed'
              : 'N/A'
        }
      })
    } else if (activeType === report.COURSE) {
      header = [
        'Title',
        'Created Date',
        'Host Name',
        'Quizzes',
        'Lessons',
        'Enrollments',
        'Viewers'
      ]
      dataCSV = courses.data.map(x => {
        return {
          title: x.title,
          date: moment(x.created_at).format('YYYY-MM-DD'),
          host_name: x.host_name || x.organiser,
          quizzes: x.quizzes && x.quizzes.length,
          lessons: x.lessons && x.lessons.length,
          enrollments: x.enrollments,
          viewers: x.views
        }
      })
    } else if (activeType === report.LIVE_EVENT) {
      header = [
        'id',
        'Title',
        'Type',
        'Views',
        'Event Date',
        'No.of Comments',
        'No. of Poll Vote'
      ]
      dataCSV = events.data.map(x => {
        return {
          id: x.id,
          title: x.title,
          type: x.event_type && x.event_type.toUpperCase(),
          views: x.views_count,
          date: moment(x.start_date_time).format('YYYY-MM-DD'),
          comments: x.comments && x.comments.length,
          poll_vote: x.poll_vote && x.poll_vote.length
        }
      })
    } else if (activeType === report.MAZE) {
      if (mazes && mazes.data.length === 0) return
      header = ['Title', 'Date', 'Views', 'Pass Score']
      dataCSV = mazes.data.map(x => {
        return {
          title: x.title,
          date: moment(x.created_at).format('YYYY-MM-DD'),
          views: x.session_play,
          pass_score: x.minimum_pass_score
        }
      })
    } else if (activeType === report.LIVE_ASSESSMENT) {
      header = [
        'Assessment Id',
        'Title',
        'Views',
        'Created Date',
        'Pass Score',
        'Average Score',
        'No.of Questions'
      ]
      dataCSV = assessments.data.map(x => {
        return {
          id: x.id,
          title: x.title,
          views: x.views_count,
          date: moment(x.created_at).format('YYYY-MM-DD'),
          pass_score: x.pass_score,
          avg_scores:
            x.live_assessment_host_scores &&
            x.live_assessment_host_scores.reduce((total, item) => {
              return total + item.score
            }, 0) / x.live_assessment_host_scores.length,
          questions: x.live_assessment_questions.length
        }
      })
    } else if (activeType === report.QUIZ) {
      header = ['id', 'Type', 'Title', 'Questions', 'Submitted']
      dataCSV = quizzes.data.map(x => {
        return {
          id: x.id,
          type: x.is_simulation
            ? 'Simulation'
            : x.is_quizgame
            ? 'QuizGame'
            : 'Quiz',
          title: x.title,
          questions: x.questions_count,
          submit: x.submit
        }
      })
    } else if (activeType === report.SKILL_JOURNEY) {
      header = [
        'id',
        'Title',
        'Created Date',
        'Participants',
        'Passed',
        'Failed'
      ]
      dataCSV = skills.data.map(x => {
        return {
          id: x.id,
          title: x.title,
          date: moment(x.created_at).format('YYYY-MM-DD'),
          participants: x.participants,
          passed: x.passed,
          failed: x.failed
        }
      })
    } else if (activeType === report.TRIVIA) {
      header = [
        'Category Name',
        'Success Attempts',
        'Failed Attempts',
        'Total Attempts'
      ]
      dataCSV = trivia.data.map(x => {
        return {
          category: x.name,
          success: x.successAttempts,
          failed: x.failedAttempts,
          total: x.totalAttempts
        }
      })
    }
    getDownload(dataCSV, header)
  }

  const getDownload = (dataCSV, header) => {
    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'user-report.csv')
      document.body.appendChild(ele)
      ele.click()
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'user-report.csv')
      ele.click()
    }
  }

  const isAdmin = is_super_admin ? true : role ? role.name === 'Admin' : false

  const totalCount = useMemo(() => overviewData.length, [overviewData])

  const onChangeUserType = val => {
    history.push(
      `/reports/user/${
        val ? report.USER_TYPE_HOST : report.USER_TYPE_PARTICIPANT
      }/${id}`
    )
    setIsUserAsHost(val)
  }

  return (
    <div className="content-warp">
      <div className="report-warp">
        <Tabs
          defaultActiveKey="1"
          animated={false}
          onTabClick={key => {
            if (key === '0') {
              props.history.push(`/reports`)
            }
          }}
          tabBarExtraContent={
            <ReportHeader
              borderRadius
              download={downloadType}
              dateChange={value => onDateChange(value)}
              workshopDateType={dateFilter && dateFilter.value}
              handleDate={(moment, str) => onCustomDate(str)}
              dateTo={dateFilter.dateTo}
              dateFrom={dateFilter.dateFrom}
            />
          }
        >
          <TabPane
            disabled={!isAdmin || !active_organization_id}
            tab={
              <CustomTabPane>
                <Icon type="left" style={{ fontSize: '18px' }} />
              </CustomTabPane>
            }
            key="0"
          />
          <TabPane disabled={false} tab="User profile" key="1">
            <StatsContainer
              t={t}
              user={user}
              data={data}
              loading={loading}
              isUserAsHost={isUserAsHost}
              activeType={activeType}
              onClickStatsButton={val => setActiveType(val)}
              changeTab={val => onChangeUserType(val)}
              enableMaze={enable_maze}
              totalCount={totalCount}
            />

            {activeType === report.OVERVIEW && (
              <OverviewUserReport
                activeDateFilter={dateFilter.value}
                user={user}
                data={data}
                t={t}
                loading={loading}
                badges={badges}
                certificates={certificates}
                enableMaze={enable_maze}
                isUserReport
              />
            )}
            {activeType === report.TRIVIA && (
              <Row style={{ paddingTop: '3rem' }}>
                <div className="report-view-wrap">
                  <Col lg={7} sm={24}>
                    <ul className="report-view-item left-short-margin">
                      <li>
                        {t('v3:overview').toUpperCase()}{' '}
                        <Tooltip
                          placement="topLeft"
                          title={
                            'Pie chart of ShareSphere questions answered by the user on the  basis of categories.'
                          }
                          arrow
                        >
                          <img alt="" src={InfoIcon} />
                        </Tooltip>
                      </li>
                    </ul>
                    <CategoryChart
                      type={'USER'}
                      graphData={{
                        total: data.trivia.data ? data.trivia.data : []
                      }}
                    />
                  </Col>
                  <Col lg={17} sm={24}>
                    <ul className="report-view-item left-margin">
                      <li>
                        {t('v3:overviewGraph').toUpperCase()}{' '}
                        <Tooltip
                          placement="topLeft"
                          title={
                            'Total ShareSphere answered by the user with these categories and their respective results of success and failures.'
                          }
                          arrow
                        >
                          <img alt="" src={InfoIcon} />
                        </Tooltip>
                      </li>
                    </ul>
                    <CategoryGraph
                      graphData={{
                        total: data.trivia.data ? data.trivia.data : []
                      }}
                    />
                  </Col>
                </div>
              </Row>
            )}

            {activeType !== report.OVERVIEW && activeType !== report.TRIVIA && (
              <UserReportTable
                activeType={activeType}
                data={data}
                t={t}
                isUserAsHost={isUserAsHost}
                loading={loading}
                enable_maze={enable_maze}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default withTranslation('report')(UserReport)
