import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import {
  Row,
  Rate,
  Tooltip,
  Icon,
  Menu,
  Switch,
  Popconfirm,
  message,
  Dropdown
} from 'antd'
import { Course } from './styled'
import { pencil, trash } from '../../images'
import { withTranslation, Trans } from 'react-i18next'
import './styles.scss'
import { general } from '../../../../constants'
import { changePublishedMarketplace } from '../../../../services/api/courses'
import EditIcon from '../../../../assets/edit.svg'
import { TranslationOutlined } from '@ant-design/icons'

const getTitle = ({ title, type }, t) =>
  `${t('course:delete_warning')} "${title.substring(0, 50)}" ${
    type === 'Course'
      ? t('general:course')
      : type === 'Live Event'
      ? t('general:live_event')
      : type === 'Podcast'
      ? t('V3:podcast')
      : type === 'Skill'
      ? t('v4:skill')
      : type === 'Maze'
      ? t('v4:maze')
      : type === 'Channel'
      ? t('v4:channel')
      : t('general:live_assessment')
    // : t('general:live_assessment').toUpperCase()
  }?`

const onCloneCourse = (e, data, props) => {
  const { onClone } = props
  // Ensure e is an event before calling stopPropagation
  if (e && typeof e.stopPropagation === 'function') {
    e.stopPropagation()
  }
  if (data.is_cloning) return
  onClone(data)
}
const clickTranslate = (e, data, props) => {
  const { onTranslate } = props
  if (e && typeof e.stopPropagation === 'function') {
    e.stopPropagation()
  }
  // if (data.is_cloning) return
  onTranslate(data)
}
const onDelete = (e, data, props) => {
  const { onRemove } = props
  if (e && typeof e.stopPropagation === 'function') {
    e.stopPropagation()
  }
  onRemove(data)
}

const directTo = data => {
  if (data.is_cloning) return '#'
  switch (data.type) {
    case 'Course':
      if (data.course_type === 'scorm') return `scorm/${data.id}`
      return `courses/${data.id}`
    case 'Live Event':
      return `events/${data.id}`
    case 'Podcast':
      return `podcast/${data.id}/play`
    case 'Live Assessment':
      return `assessments/${data.id}`
    case 'Maze':
      return `maze-detail/${data.id}`
    case 'Skill':
      return `skill-journey/${data.id}`
    case 'Channel':
      return `channel/${data.id}`

    default:
      break
  }
}

const checkStatus = (
  {
    is_finished: isFinished,
    type,
    event_type,
    start_date_time: startDataTime,
    duration = 0
  },
  t
) => {
  if (type === general.LIVE_EVENT || type === general.PODCAST) {
    if (!startDataTime) {
      return t('unPublishEvent')
    } else {
      const newTime = moment(startDataTime).format('LLLL')
      const diffMinutes = moment().diff(newTime, 'minutes')
      const newDuration = duration || 0 + 10
      const checkInterval = diffMinutes - newDuration

      if (checkInterval > 0) {
        if (event_type && event_type === 'discussion_room') {
          return t('v3:pastPodcast')
        } else {
          return t('pastEvent')
        }
      } else {
        if (isFinished) {
          if (event_type && event_type === 'discussion_room') {
            return t('v3:pastPodcast')
          }
          return t('pastEvent')
        } else {
          if (event_type && event_type === 'discussion_room') {
            return t('v3:upcomingPodcast')
          }
          return t('upcoming')
        }
      }
    }
  } else if (type === general.LIVE_ASSESSMENT) {
    if (!startDataTime) {
      return t('unPublishAssessment')
    } else {
      const newTime = moment(startDataTime).format('LLLL')
      const diffMinutes = moment().diff(newTime, 'minutes')
      const newDuration = duration || 0 + 10
      const checkInterval = diffMinutes - newDuration

      if (checkInterval > 0) {
        return t('pastAssessment')
      } else {
        if (isFinished) {
          return t('pastAssessment')
        } else {
          return t('upcomingAssessment')
        }
      }
    }
  } else if (type === 'Maze') {
    if (!startDataTime) {
      return t('unPublishMaze')
    } else {
      const newTime = moment(startDataTime).format('LLLL')
      const diffMinutes = moment().diff(newTime, 'minutes')
      const newDuration = duration || 0 + 10
      const checkInterval = diffMinutes - newDuration

      if (checkInterval > 0) {
        return t('pastMaze')
      } else {
        return t('upcomingMaze')
      }
    }
  } else if (type === 'Skill') {
    return 'TRAINING SKILL JOURNEY'
  } else if (type === 'Channel') {
    return 'WORKSHOP CHANNEL'
  } else {
    if (isFinished) {
      return t('pastCourse')
    }
    return t('upcoming_course')
  }
}

const getTextByType = (type, action, t, data) => {
  switch (action) {
    case 'edit':
      return t('labels:edit_course')

    case 'delete':
      return t('labels:delete_course')

    case 'view':
      return t('labels:view_course')

    case 'clone':
      if (type === 'Course') {
        return t('labels:clone_course')
      } else {
        return t('Clone this maze')
      }
    case 'date':
      if (type === general.PODCAST) {
        return t('v3:date_podcast')
      } else if (type === general.LIVE_EVENT) {
        return t('labels:date_event')
      } else if (type === 'Maze') {
        return t('metamaze date')
      } else {
        return t('labels:date_assessment')
      }
    case 'translate':
      return t('v4:translateCourseTip')
    default:
      break
  }
}

const ECourse = ({ t, ...props }) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [isSwitchChecked, setSwitchChecked] = useState(true)

  const {
    data,
    onEdit,
    user: {
      info: { id: userId, is_super_admin }
    }
  } = props
  const dateFormat = 'D MMM YYYY, HH:mm'

  const { cover = {} } = data

  const src = cover && cover.resizes ? cover.resizes.medium : ''

  const date = moment(data.start_date_time).format(dateFormat)
  const tooltipEdit = getTextByType(data.type, 'edit', t)
  const tooltipDelete = getTextByType(data.type, 'delete', t)
  const tooltipView = getTextByType(data.type, 'view', t)
  const tooltipClone = getTextByType(data.type, 'clone', t)
  const tooltipTranslate = getTextByType(data.type, 'translate', t)
  let eventType
  if (
    data &&
    data.event_type === 'discussion_room' &&
    data.discussion_room_type !== 'stories'
  ) {
    eventType = 'PODCAST'
  } else if (
    data &&
    data.event_type === 'discussion_room' &&
    data.discussion_room_type === 'stories'
  ) {
    eventType = 'STORY'
  } else if (data && data.encoder === 'other_webrtc') {
    eventType = 'WEBINAR'
  } else if (data && data.encoder === 'conference') {
    eventType = 'CONFERENCE'
  } else if (data && data.encoder === 'peer2peer') {
    eventType = 'COACHING'
  } else {
    eventType =
      data && data.course_type === 'scorm'
        ? 'Scorm'
        : data && data.type && data.type.replace(' ', '_').toLowerCase()
  }

  const checkMarketplacePublishing = setting =>
    setting &&
    setting.publish_option &&
    (setting.publish_option === 'marketplace' ||
      setting.publish_option === 'organization_marketplace')

  const isPublished = data && data.marketplace && data.marketplace.is_published

  useEffect(() => {
    setSwitchChecked(isPublished)
  }, [isPublished])

  const onChangeMarketplacePublishing = () => {
    setIsUpdating(true)

    const dataType =
      data && data.type === 'Course'
        ? 'courses'
        : data.type === 'Channel'
        ? 'collections'
        : data.type === 'Skill'
        ? 'skills'
        : data.type === 'Maze'
        ? 'programme'
        : 'events'

    changePublishedMarketplace(
      { id: data && data.id, type: dataType },
      {
        is_published: !isSwitchChecked
      }
    )
      .then(res => {
        if (!isSwitchChecked) {
          message.success(`${data.type} is published to marketplace !`)
        }
        setSwitchChecked(val => !val)
        setIsUpdating(false)
      })
      .catch(err => {
        message.error(err.message)
        setIsUpdating(false)
      })
  }

  const isPublishedToMarketplace =
    data &&
    (data.type === 'Course' ||
      data.type === 'Skill' ||
      data.type === general.LIVE_EVENT ||
      data.type === 'Podcast' ||
      data.type === 'Channel' ||
      data.type === 'Maze') &&
    checkMarketplacePublishing(data.setting || data.privacy)

  const canTogglePublish =
    data &&
    (is_super_admin ||
      data.host_id === userId ||
      (data.host_ids && data.host_ids.indexOf(userId) !== -1) ||
      data.creator_id === userId ||
      data.user_id === userId)

  const isRestricted = data && data.is_restricted
  const menu = (
    <Menu className="secondary-image-upload">
      <Menu.Item key="1" onClick={() => onEdit(data)}>
        <Course.EveneLink>
          <Tooltip title={`${tooltipEdit} ${data.title}`}>
            <div className="workshop-edit-menu">
              <Course.IconEvents src={pencil} />
              &nbsp;{t('buttons:edit')}&nbsp;{data.type}
            </div>
          </Tooltip>
        </Course.EveneLink>
      </Menu.Item>
      {(data.type === 'Course' || data.type === 'Maze') && !isRestricted && (
        <Menu.Item
          key="2"
          onClick={e => {
            onCloneCourse(e, data, props)
          }}
        >
          <Course.EveneLink>
            <Tooltip title={`${tooltipClone}`}>
              <div className="workshop-edit-menu">
                <Icon type="copy" style={{ color: '#0068FF' }} />
                &nbsp;{t('buttons:clone')}&nbsp;{data.type}
              </div>
            </Tooltip>
          </Course.EveneLink>
        </Menu.Item>
      )}
      {data.type === 'Course' && !isRestricted && (
        <Menu.Item
          key="4"
          onClick={e => {
            clickTranslate(e, data, props)
          }}
        >
          <Course.EveneLink>
            <Tooltip title={`${tooltipTranslate}`}>
              <div className="workshop-edit-menu">
                <TranslationOutlined style={{ color: '#0068FF' }} />
                &nbsp;{t('v4:translateModalBtn')}&nbsp;{data.type}
              </div>
            </Tooltip>
          </Course.EveneLink>
        </Menu.Item>
      )}
      <Menu.Item key="3">
        <Course.Event
          title={getTitle(data, t)}
          okText={t('general:yes')}
          cancelText={t('general:no')}
          placement="left"
          icon={false}
          onConfirm={e => {
            onDelete(e, data, props)
          }}
        >
          <Tooltip title={`${tooltipDelete} ${data.title}`}>
            <div className="workshop-edit-menu">
              <Course.IconEvents src={trash} />
              &nbsp;{t('v4:Delete')}&nbsp;{data.type}
            </div>
          </Tooltip>
        </Course.Event>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="workshop-course">
      <div
        className="workshop-course-cover"
        style={{ backgroundImage: `url(${src})` }}
      ></div>
      <div className="workshop-course-body">
        <div className="workshop-course-body-header">
          <div className="workshop-course-body-header-title">
            <div className="workshop-course-body-header-title-h1">
              {checkStatus(data, t)}
            </div>
            <div className="workshop-course-body-header-title-h2">
              <Tooltip title={`${tooltipView} ${data.title}`}>
                <div
                  className={'workshop-course-body-header-title-h2-titletext'}
                  style={data.type === 'Course' ? { width: '15rem' } : {}}
                >
                  <Course.Title to={directTo(data) ? directTo(data) : ''}>
                    {data.title}
                  </Course.Title>
                </div>
              </Tooltip>
              {data.type === 'Course' && data.course_type !== 'scorm' && (
                <div className="workshop-course-body-header-title-h2-lessons">
                  &nbsp; | &nbsp; {data.lessons_count || 0}{' '}
                  {t('general:lessons')}
                </div>
              )}
            </div>
          </div>
          {data.type === 'Course' && (
            <div
              className="workshop-course-body-header-type"
              style={{
                width: `${
                  data.type === 'Course' ? 'calc(100% - 13rem)' : '100%'
                }`
              }}
            >
              <div className="workshop-course-body-header-type-tags">
                <span> {t(`general:${eventType}`)}</span>
              </div>
              {data.is_ai_generated && (
                <div className="workshop-course-body-header-type-ai">
                  <span>AI</span>
                </div>
              )}
            </div>
          )}
          <div className="workshop-course-body-header-action">
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
            >
              <span className="image-edit" size="large">
                <img src={EditIcon} alt="" />
              </span>
            </Dropdown>
          </div>
        </div>

        <div className="workshop-course-body-tags">
          {data.avg_rating && (
            <div className="workshop-course-body-rate">
              {data.avg_rating.toFixed(2)}
              <Rate
                value={data.avg_rating}
                style={{ fontSize: 16, color: '#5B6EE2' }}
              />
            </div>
          )}
          {data.type === 'Course' && data.is_translated && (
            <div className="workshop-course-body-tags-translation">
              <span>{t('v4:isTranslated')}</span>
            </div>
          )}
        </div>

        {data.type !== 'Course' && (
          <div className="workshop-course-body-tags">
            <div className="workshop-course-body-tags-type">
              <span> {t(`general:${eventType}`)}</span>
            </div>
            {data.type === 'Maze' && !data.is_published && (
              <div className="workshop-course-body-tags-draft">
                <span>Draft</span>
              </div>
            )}
            {data.type === general.LIVE_EVENT && data.start_date_time && (
              <Course.DateLive>
                <Trans i18nKey="">
                  <Course.DateLabel>
                    {getTextByType(data.type, 'date', t, data)}
                  </Course.DateLabel>
                  <Course.DateStrong>{{ date }}</Course.DateStrong>
                </Trans>
              </Course.DateLive>
            )}
          </div>
        )}

        {isPublishedToMarketplace && canTogglePublish && (
          <div className="workshop-course-body-courseswitch">
            <Popconfirm
              title={`This will ${
                isSwitchChecked ? 'unpublish' : 'publish'
              } your ${data.type} from the marketplace. Are you sure?`}
              onConfirm={onChangeMarketplacePublishing}
              okText="Yes"
              cancelText="No"
              icon={false}
            >
              <Switch loading={isUpdating} checked={isSwitchChecked} />
            </Popconfirm>
            {isUpdating && isSwitchChecked
              ? 'Unpublishing'
              : isUpdating
              ? 'Publishing'
              : isSwitchChecked
              ? 'Unpublish from Marketplace'
              : 'Unpublished'}
          </div>
        )}
        <div className="workshop-course-body-date">
          {t('created')} {moment(data.created_at).format(dateFormat)}
        </div>
      </div>
    </div>
  )
}

export default withTranslation('course')(ECourse)
