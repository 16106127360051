import React, { Fragment, useEffect, useState } from 'react'
import { Button, Divider, message } from 'antd'
import { useTranslation } from 'react-i18next'

const PageTypes = ({
  activeType: propActiveType,
  onClickType,
  pageStructureData,
  currentPage,
  pageTypes = {}
}) => {
  const [activeType, setActiveType] = useState()

  useEffect(() => {
    setActiveType(propActiveType)
  }, [propActiveType])

  const handleClick = type => {
    const isCurrentType = activeType === type
    const newType = isCurrentType ? null : type

    const currentPageData = pageStructureData[currentPage - 1]

    const pageHasScenario =
      currentPageData &&
      currentPageData.data.some(node => node.type === 'SCENARIO')

    if (pageHasScenario) {
      message.error(
        'You cannot add other element in a scenario page. Please try other pages or remove the scenario from the page first.',
        10
      )
      return
    }

    setActiveType(newType)

    if (onClickType) {
      onClickType(newType)
    }
  }

  useEffect(() => {
    if (activeType) {
      const isBUlletPoints = activeType === 'bulletPoints'

      if (isBUlletPoints) return

      message.info(
        `Please click on the pages from the pages list whose content you want to reference in the ${activeType}.`
      )
    }
  }, [activeType])

  const { t } = useTranslation()

  return (
    <Fragment>
      <Divider orientation="left" className="divider-title">
        {t('general:type')}
      </Divider>

      <div className="type-options">
        {Object.keys(pageTypes).map(type => (
          <Button
            key={type}
            className="type-option"
            type={activeType === type ? 'primary' : 'default'}
            onClick={() => handleClick(type)}
          >
            {pageTypes[type]}
          </Button>
        ))}
      </div>
    </Fragment>
  )
}

export default PageTypes
