import React, { useState, useRef } from 'react'
import { Modal, Button, Icon } from 'antd'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import 'antd/dist/antd.css'

import './ImagePreview.scss'

const ImagePreview = props => {
  const [visible, setVisible] = useState(false)
  const [rotation, setRotation] = useState(0)
  const contentRef = useRef(null)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    resetRotation()
  }

  const rotateClockwise = () => {
    setRotation(prevRotation => prevRotation + 90)
  }

  const rotateCounterclockwise = () => {
    setRotation(prevRotation => prevRotation - 90)
  }

  const resetRotation = () => {
    setRotation(0)
  }

  return (
    <div>
      <img
        {...props}
        alt="Preview"
        onClick={showModal}
        className="shl-image-preview"
        style={{ cursor: 'pointer', maxWidth: '100%', ...props.style }}
      />

      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width="100vw"
        style={{
          maxWidth: '100vw',
          margin: 0,
          top: 0,
          padding: 0
        }}
        bodyStyle={{
          height: '100vh',
          padding: 0
        }}
        wrapClassName="fullscreen-modal"
        destroyOnClose
      >
        <TransformWrapper
          initialScale={1}
          minScale={0.1}
          maxScale={5}
          limitToBounds={true}
          wheel={{ disabled: false }}
          pinch={{ disabled: false }}
          doubleClick={{ disabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform, setTransform }) => (
            <div className="image-preview-container">
              <div className="shl-tools">
                <Button onClick={() => zoomIn()}>
                  <Icon type="zoom-in" />
                </Button>
                <Button onClick={() => zoomOut()}>
                  <Icon type="zoom-out" />
                </Button>
                <Button
                  onClick={() => {
                    rotateClockwise()
                  }}
                >
                  <Icon type="redo" />
                </Button>
                <Button
                  onClick={() => {
                    rotateCounterclockwise()
                  }}
                >
                  <Icon type="undo" />
                </Button>
                <Button
                  onClick={() => {
                    resetRotation()
                    resetTransform()
                  }}
                >
                  Reset
                </Button>
              </div>

              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '100%'
                }}
                contentStyle={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  ref={contentRef}
                  className="image-wrapper"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100%',
                    minWidth: '100%',
                    transformOrigin: 'center center'
                  }}
                >
                  <img
                    {...props}
                    alt="Preview"
                    style={{
                      maxWidth: 'none',
                      maxHeight: 'none',
                      transform: `rotate(${rotation}deg)`,
                      transformOrigin: 'center center',
                      transition:
                        rotation === 0 ? 'none' : 'transform 0.3s ease-in-out'
                    }}
                  />
                </div>
              </TransformComponent>
            </div>
          )}
        </TransformWrapper>
      </Modal>
    </div>
  )
}

export default ImagePreview
