const languages = [
  { name: 'Amharic', key: 'amharic', code: 'am' },
  { name: 'Arabic', key: 'arabic', code: 'ar' },
  { name: 'Basque', key: 'basque', code: 'eu' },
  { name: 'Bengali', key: 'bengali', code: 'bn' },
  { name: 'Bulgarian', key: 'bulgarian', code: 'bg' },
  { name: 'Catalan', key: 'catalan', code: 'ca' },
  { name: 'Cherokee', key: 'cherokee', code: 'chr' },
  { name: 'Croatian', key: 'croatian', code: 'hr' },
  { name: 'Czech', key: 'czech', code: 'cs' },
  { name: 'Danish', key: 'danish', code: 'da' },
  { name: 'Dutch', key: 'dutch', code: 'nl' },
  { name: 'English (US)', key: 'english', code: 'en-us' },
  { name: 'Estonian', key: 'estonian', code: 'et' },
  { name: 'Filipino', key: 'filipino', code: 'fil' },
  { name: 'Finnish', key: 'finnish', code: 'fi' },
  { name: 'French', key: 'french', code: 'fr' },
  { name: 'German', key: 'german', code: 'de' },
  { name: 'Greek', key: 'greek', code: 'el' },
  { name: 'Gujarati', key: 'gujarati', code: 'gu' },
  { name: 'Hebrew', key: 'hebrew', code: 'iw' },
  { name: 'Hindi', key: 'hindi', code: 'hi' },
  { name: 'Hungarian', key: 'hungarian', code: 'hu' },
  { name: 'Icelandic', key: 'icelandic', code: 'is' },
  { name: 'Indonesian', key: 'indonesian', code: 'id' },
  { name: 'Italian', key: 'italian', code: 'it' },
  { name: 'Japanese', key: 'japanese', code: 'ja' },
  { name: 'Kannada', key: 'kannada', code: 'kn' },
  { name: 'Korean', key: 'korean', code: 'ko' },
  { name: 'Latvian', key: 'latvian', code: 'lv' },
  { name: 'Lithuanian', key: 'lithuanian', code: 'lt' },
  { name: 'Malay', key: 'malay', code: 'ms' },
  { name: 'Malayalam', key: 'malayalam', code: 'ml' },
  { name: 'Marathi', key: 'marathi', code: 'mr' },
  { name: 'Norwegian', key: 'norwegian', code: 'no' },
  { name: 'Polish', key: 'polish', code: 'pl' },
  { name: 'Portuguese (Portugal)', key: 'portuguese', code: 'pt' },
  { name: 'Romanian', key: 'romanian', code: 'ro' },
  { name: 'Russian', key: 'russian', code: 'ru' },
  { name: 'Serbian', key: 'serbian', code: 'sr' },
  { name: 'Chinese (PRC)', key: 'mandarin', code: 'zh' },
  { name: 'Slovak', key: 'slovak', code: 'sk' },
  { name: 'Slovenian', key: 'slovenian', code: 'sl' },
  { name: 'Spanish', key: 'spanishEU', code: 'es' },
  { name: 'Swahili', key: 'swahili', code: 'sw' },
  { name: 'Swedish', key: 'swedish', code: 'sv' },
  { name: 'Tamil', key: 'tamil', code: 'ta' },
  { name: 'Telugu', key: 'telugu', code: 'te' },
  { name: 'Thai', key: 'thai', code: 'th' },
  { name: 'Traditional Chinese', key: 'traditional_chinese', code: 'tw' },
  { name: 'Turkish', key: 'turkish', code: 'tr' },
  { name: 'Urdu', key: 'urdu', code: 'ur' },
  { name: 'Ukrainian', key: 'ukrainian', code: 'uk' },
  { name: 'Vietnamese', key: 'vietnamese', code: 'vi' },
  { name: 'Welsh', key: 'welsh', code: 'cy' },
  { name: 'Burmese', key: 'burmese', code: 'mm' },
  { name: 'Mongolian', key: 'mongolian', code: 'mn' },
  { name: 'Spanish US', key: 'spanishUS', code: 'es-us' },
];

export default languages;
