import {
  HANDLE_COURSE_INPUT,
  RESET_COURSE_BUILDER,
  SET_COURSE_CREATION,
  COURSE_BUILDER_REORDER_LESSONS
} from '../types'

const defaultState = {
  courseId: null,
  courseTitle: '',
  courseOrganiser: '',
  courseTags: [],
  courseImgId: null,
  courseCertId: null,
  courseBadgeId: null,
  courseImg: '',
  courseFileName: '',
  courseType: '',
  courseDescription: '',
  lessons: {
    list: [],
    fetch: false
  },
  templateData: {},
  courseTrainerBio: '',
  courseTrainerLinkedIn: '',
  courseCategory: null,
  courseIsFree: false,
  coursePrice: 5,
  courseVideoLink: '',
  courseNoOfVideos: null,
  courseNoOfLessons: null,
  courseOrganization: null,
  courseGroup: null,
  courseShareProfile: false,
  courseObjectives: '',
  courseOutline: '',
  courseMarketPlaceDescription: '',
  courseMarketPlaceOption: null,
  coursePriceToEdit: 0,
  courseEditPermission: false,
  saving: false,
  isCreation: false,
  courseEmails: [],
  courseCategories: [],
  courseLang: ""
}

export default (state = defaultState, action) => {
  console.log('defultStatiu=--->', action.payload)
  switch (action.type) {
    case HANDLE_COURSE_INPUT:
      return {
        ...state,
        [action.field]: action.payload
      }
    case RESET_COURSE_BUILDER:
      return defaultState

    case SET_COURSE_CREATION:
      return {
        ...state,
        isCreation: action.payload
      }

    case COURSE_BUILDER_REORDER_LESSONS:
      return {
        ...state,
        lessons: {
          ...state.lessons,
          list: action.payload
        }
      }

    default:
      return state
  }
}
