import styled from 'styled-components'
import ReactQuill from 'react-quill'

import { styleguide } from '../../../../constants'
import { sizeList } from './Text.ctrl'

const { colors } = styleguide

/**
 * 4lines code below use to custom ReactQuill Size in text-editor Toolbar
 */
const Quill = ReactQuill.Quill
var Size = Quill.import('attributors/style/size')
Size.whitelist = sizeList
Quill.register(Size, true)

export const QuillStyled = styled(ReactQuill)`
  position: relative;
  height: 100%;
  text-align: center;
  background: ${colors.white};
  border: 0;

  .ql-container {
    z-index: 1;
  }

  .ql-toolbar,
  .ql-container {
    border: 0 !important;
  }

  .ql-toolbar.ql-snow {
    background-color: ${colors.light};
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .ql-editor {
    word-break: break-word;
    padding: 0;
    color: black;
    border: 1px solid #e3e3e3;
    min-height: 20rem;
  }
`
