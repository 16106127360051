import React from 'react'
import { Course } from '../'
import { Col, Row } from 'antd'

export default ({ data, onRemove, onEdit, user, onClone, onTranslate }) => {
  return (
    <Row gutter={[16, 16]}>
      {data.map((item, index) => (
        <Col xs={24} sm={24} md={24} lg={12} key={index + 'course'}>
          <Course
            user={user}
            data={item}
            onRemove={onRemove}
            onEdit={onEdit}
            onClone={onClone}
            onTranslate={onTranslate}
          />
        </Col>
      ))}
    </Row>
  )
}
