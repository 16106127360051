import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import {
  MeetingProvider,
  lightTheme
} from 'amazon-chime-sdk-component-library-react'

import { store } from './store'
import App from './App'

import './services/i18n'

import './styles/app.scss'

import { setConfig } from 'react-hot-loader'
import { hot } from 'react-hot-loader/root'

// Configure react-hot-loader to preserve component state
setConfig({
  pureSFC: true, // Treat functional components as pure
  pureRender: true, // Preserve state for class components
  reloadHooks: false // Disable hook reloading (optional)
})

const HotRoot = hot(() => (
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
      <MeetingProvider>
        <App />
      </MeetingProvider>
    </ThemeProvider>
  </Provider>
))

ReactDOM.render(<HotRoot />, document.querySelector('#app'))
