import React, { Component, createRef } from 'react'
import _ from 'lodash'
import {
  Modal,
  Button,
  Input,
  Checkbox,
  Spin,
  Row,
  Col,
  Form,
  Icon,
  Progress,
  Select,
  message
} from 'antd'
import { withTranslation, Trans } from 'react-i18next'
import api from '../../services/api'
import { config, general, languages } from '../../constants'
import { ModalStyles } from './CloneModal.ctrl'
import CourseDropzone from '../../views/Courses/Create/internal/CourseDropzone'
import { EditImageContainer } from '../../components/TextOnImage'
import { PopupMedia } from '../../views/Library/internal'
import { Editor } from '../../components/ImageEditor'
import './CloneModal.scss'
const { Option } = Select

const antIcon = <Icon type="loading" style={{ fontSize: 80 }} spin />

class CloneModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTranslaton: props.isTranslaton,
      isCheckAll: true,
      courseTitle: '',
      isCloning: false,
      courseId: null,
      isMazeClone: false,
      mazeTitle: '',
      mazeId: null,
      isCoverLoading: false,
      modalOpen: false
    }
    this.imageRef = createRef()
  }

  handleTitle = _.debounce(v => this.handleTitleChange(v), 1000)

  handleTranslateLanguage = language => {
    const { app } = this.props
    app.setState({
      courseCloneLang: language
    })
  }
  handleTitleChange = (v = '') => {
    const { data, app } = this.props

    if (data.type === 'Maze') {
      this.setState({ mazeTitle: v })
      return
    }

    const newTemple = _.cloneDeep(data)

    if (!newTemple) return

    newTemple.courseTitle = v
    app.setState({
      courseCloneTemplate: newTemple
    })
  }

  checkFinish = () => {
    const { mazeId } = this.state
    const { data } = this.props
    if (data && data.type === 'Maze') {
      console.log('checkfinishclone', mazeId ? true : false)
      return mazeId ? true : false
    } else {
      const { courseCloningProgress } = this.props
      console.log(
        'checkfinishclone',
        courseCloningProgress && courseCloningProgress.percent === 100
      )
      return courseCloningProgress && courseCloningProgress.percent === 100
    }
  }

  handleCancel = (isHide = false) => {
    const { app, clearCourseCloning } = this.props
    const { courseId } = this.state
    if (courseId && app) {
      clearCourseCloning()
      app.fetchData(true)
    }
    this.setState({
      isCloning: false,
      courseId: null
    })
    if (app) {
      app.setState({
        isClone: false,
        courseCloneTemplate: null
      })
    }
  }

  onDrop = file => {
    const image = URL.createObjectURL(file)
    this.setState({
      file,
      image
    })
  }

  handleClone = () => {
    const { app, clearCourseCloning, data } = this.props
    const { courseId, mazeId, isTranslaton } = this.state

    const isMaze = data && data.type === 'Maze'

    if (isTranslaton && !courseId) {
      this.translateAPI()
    } else if (isMaze && !mazeId) {
      this.cloneMaze()
    } else if (!isMaze && !courseId) {
      this.cloneAPI()
    } else if (courseId) {
      clearCourseCloning()
      if (app) {
        app.props.history.push(`/courses/${courseId}/edit`)
      }
    } else if (mazeId) {
      if (app) {
        app.props.history.push(`/maze/${mazeId}/edit`)
      }
    }
  }

  cloneMaze = () => {
    const {
      data: { id, title }
    } = this.props
    const { mazeTitle, cover } = this.state

    const obj = {
      body: {
        title: mazeTitle || title,
        ...(cover && {
          cover_id: cover.id
        })
      },
      params: {
        programme_id: id
      },
      action: 'clone_programme'
    }

    this.setState({ isCloning: true })

    api.maze.mazeProvider(obj).then(res => {
      if (res) {
        this.setState({ mazeId: res.data.id })
      }
    })
  }

  cloneAPI = () => {
    const {
      data: { courseTitle, courseId, courseLessons },
      app
    } = this.props

    if (courseTitle.length > 0) {
      const customData = {
        title: courseTitle.substring(0, 90),
        lesson_ids: _.map(
          _.filter(courseLessons, lesson => lesson.isChecked),
          item => item.id
        )
      }
      if (app) {
        app.setState({
          courseCloneTemplate: null
        })
      }
      this.setState({
        isCloning: true,
        courseTitle
      })
      api.courses.cloneCourse(courseId, customData).then(({ data }) => {
        this.setState({
          courseId: data.id,
          courseTitle: data.title
        })
      })
    }
  }
  translateAPI = () => {
    const {
      data: { courseTitle, courseId, courseLessons, courseLang },
      clearCourseCloning,
      app
    } = this.props
    if (courseTitle.length > 0) {
      const customData = {
        title: courseTitle.substring(0, 90),
        isTranslate: true,
        lang: app.state.courseCloneLang || courseLang,
        lesson_ids: _.map(
          _.filter(courseLessons, lesson => lesson.isChecked),
          item => item.id
        )
      }
      let courseDaa = app.state.courseCloneTemplate
      if (app) {
        app.setState({
          courseCloneTemplate: null
        })
      }

      this.setState({
        isCloning: true,
        courseTitle
      })

      api.courses
        .cloneCourse(courseId, customData)
        .then(({ data }) => {
          this.setState({
            courseId: data.id,
            courseTitle: data.title
          })
        })
        .catch(error => {
          if (app) {
            app.setState({
              courseCloneTemplate: {
                ...courseDaa,
                courseLang: app.state.courseCloneLang
              }
            })
          }
          clearCourseCloning()
          this.setState({
            isCloning: false,
            courseTitle
          })
          let errorMessage = 'Failed to translate, please try again!'

          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorMessage
          ) {
            errorMessage = error.response.data.errorMessage
          } else if (error && error.message) {
            errorMessage = error.message
          }

          message.error(errorMessage)

          this.setState({
            isCloning: false
          })
        })
    }
  }

  handleCheckAll = (recall = false) => {
    if (recall) {
      const { data } = this.props

      const isUnchecked = _.filter(
        data.courseLessons,
        lesson => !lesson.isChecked
      )

      this.setState({
        isCheckAll: _.isEmpty(isUnchecked)
      })
    } else {
      const { isCheckAll } = this.state

      this.setState(
        {
          isCheckAll: !isCheckAll
        },
        () => {
          this.handleCheckGroup(isCheckAll)
        }
      )
    }
  }

  handleCheckOne = ({ id, isChecked }) => {
    const { data, app } = this.props
    const newTemple = _.cloneDeep(data)

    _.map(newTemple.courseLessons, lesson => {
      if (lesson.id === id) {
        lesson.isChecked = !isChecked
      }
    })

    if (app) {
      app.setState(
        {
          courseCloneTemplate: newTemple
        },
        () => {
          this.handleCheckAll(true)
        }
      )
    }
  }

  handleCheckGroup = isCheckAll => {
    const { data, app } = this.props
    const newTemple = _.cloneDeep(data)

    _.map(newTemple.courseLessons, lesson => {
      lesson.isChecked = !isCheckAll
    })
    if (app) {
      app.setState({
        courseCloneTemplate: newTemple
      })
    }
  }

  getCloneBtn = () => {
    const { mazeId, isTranslaton } = this.state
    const { data, courseCloningProgress, t } = this.props
    const isMaze = data && data.type === 'Maze'
    if (isTranslaton) {
      if (courseCloningProgress) {
        const total = courseCloningProgress.total
        const completed = courseCloningProgress.completed
        if (total && completed && total === completed)
          return 'buttons:edit_course'
        else return t('v4:translatingCourse')
      }
      return t('v4:translateModalBtn')
    }
    if (!isMaze) {
      if (courseCloningProgress) {
        const total = courseCloningProgress.total
        const completed = courseCloningProgress.completed
        if (total && completed && total === completed)
          return 'buttons:edit_course'
        else return t('labels:cloning_course')
      }
    }

    if (isMaze && mazeId) {
      return 'v4:edit_maze'
    }
    return 'buttons:clone'
  }

  subTitleString = title => {
    if (title.length > 30) {
      return `${title.substring(0, 30)}...`
    }
    return title
  }

  getPercent = () => {
    const data = this.props.data
    const { mazeId } = this.state
    if (data && data.type === 'Maze') {
      if (mazeId) return 100
      return 0
    } else {
      const { courseCloningProgress } = this.props
      return (
        (courseCloningProgress && courseCloningProgress.percent.toFixed(0)) || 5
      )
    }
  }

  getDisable = (isClone = false) => {
    const { data, courseCloningProgress } = this.props
    const { mazeId, mazeTitle, isCloning } = this.state
    const isMaze = data && data.type === 'Maze'

    if (isClone && isMaze && isCloning && !mazeId) {
      return true
    }

    if (isMaze) {
      if (!mazeTitle) {
        if (data.title) {
          return false
        }
        return true
      }
      return false
    }

    if (!isMaze && data) {
      const isHaveCheck =
        data.courseLessons.filter(item => item.isChecked).length >= 1
      return !isHaveCheck
    }

    if (!isMaze && isClone) {
      const total = courseCloningProgress && courseCloningProgress.total
      const completed = courseCloningProgress && courseCloningProgress.completed
      if (total && completed) {
        return total !== completed
      }
      return true
    }

    return this.state.isCloning
  }
  getCancelDisable = (isClone = false) => {
    const { data, courseCloningProgress } = this.props
    const { mazeId, mazeTitle, isCloning } = this.state
    const isMaze = data && data.type === 'Maze'

    if (isClone && isMaze && isCloning && !mazeId) {
      return true
    }

    if (isMaze) {
      if (!mazeTitle) {
        if (data.title) {
          return false
        }
        return true
      }
      return false
    }
    if (!isMaze && isClone) {
      const total = courseCloningProgress && courseCloningProgress.total
      const completed = courseCloningProgress && courseCloningProgress.completed
      if (total && completed) {
        return total !== completed
      }
      return true
    }

    return this.state.isCloning
  }

  getCloseAble = () => {
    const { courseCloningProgress } = this.props
    if (courseCloningProgress) {
      return true
    }
    return false
  }

  handleUploadImage = file => {
    const { upload } = this.props
    this.setState({
      isCoverLoading: true
    })
    return upload(file).then(({ id, link }) => {
      this.setState({
        isCoverLoading: false,
        cover: {
          id: id,
          link: link
        }
      })
    })
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null
      })
    } else {
      this.setState({
        image: null,
        file: null
      })
    }
  }

  hideModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  handleFileUpload = files => {
    if (!files) return
    this.setState({ selectedAudio: files[0] })
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({
      selectedItem
    })
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
  }

  handleFileInsert = item => {
    const imageEditorInst = this.imageRef.current.imageEditorInst
    const data = imageEditorInst.toDataURL()
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: item.filename,
      size: item.file_size,
      type: item.media_mime_type
    }
    this.setState(
      {
        modalOpen: false,
        selectedItem: null
      },
      () => {
        this.handleUploadImage(body)
      }
    )
  }

  useCanva = type => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: type
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle, type)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title,
      size: blob.size,
      type: blob.type
    }
    this.handleUploadImage(body)
  }

  render() {
    const {
      data,
      width,
      visible,
      t,
      form: { getFieldDecorator }
    } = this.props
    const isMaze = data && data.type === 'Maze'

    const {
      isCheckAll,
      courseTitle,
      isCloning,
      mazeTitle,
      selectedItem,
      modalOpen,
      file,
      isCoverLoading,
      image,
      cover,
      isTranslaton
    } = this.state

    const modalTitle = isTranslaton
      ? t('v4:translateModalTitle')
      : isMaze
      ? 'Clone maze'
      : t('labels:clone_modal')
    const text = isMaze
      ? mazeTitle || (data && data.title)
      : data && data.courseTitle
    const lang = (data && data.courseLang) || 'en'
    if (isMaze) {
      var coverLink = cover
        ? cover.link
        : data && data.cover
        ? data.cover.link
        : ''
    }

    const modal_style = {
      overflow: 'auto',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }
    return (
      <Modal
        title={modalTitle}
        width={width}
        className="CloneCourseModal"
        style={ModalStyles}
        centered
        visible={visible}
        footer={null}
        onCancel={() => {
          this.handleCancel(true)
        }}
        closable={this.getCloseAble()}
        maskClosable={false}
      >
        {!data && !isCloning && (
          <div className="cloneloading">
            <Spin indicator={antIcon} />
            <span>Loading...</span>
          </div>
        )}
        {(data || isCloning) && (
          <div className="wrap-content">
            {((!isMaze && data) || (isMaze && !isCloning)) && (
              <div className="modal-title">
                <span>{t('labels:Title')}</span>
                <Form.Item colon={false} required={false}>
                  {getFieldDecorator('title', {
                    rules: [
                      {
                        required: true,
                        message: t('errors:title_course'),
                        max: 100
                      },
                      {
                        pattern: '.{3,}',
                        message: t('errors:min_3char')
                      }
                    ],
                    initialValue: text
                  })(
                    <Input
                      name="title"
                      maxLength={100}
                      className="title-input"
                      onChange={e => {
                        this.handleTitle(e.target.value)
                      }}
                    />
                  )}
                </Form.Item>
              </div>
            )}
            {isTranslaton && !isCloning && (
              <div className="modal-title">
                <span>{t('v4:translateSelectLang')}</span>
                <Form.Item colon={false} required={false}>
                  {getFieldDecorator('lang', {
                    initialValue: lang
                  })(
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={lang}
                      onChange={e => this.handleTranslateLanguage(e)}
                      style={{ width: '100%' }}
                    >
                      {languages.map(item => (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}

            {isMaze && !isCloning && (
              <div className="modal-body">
                <Row>
                  <Col md={24}>
                    <Form.Item
                      colon={false}
                      required={true}
                      label={t('labels:picture')}
                      className="no_bottom_margin"
                    >
                      <CourseDropzone
                        app={this}
                        onDrop={this.onDrop}
                        error={0}
                        disabled={isCoverLoading}
                        cover={coverLink}
                        loadImage={isCoverLoading}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {!isMaze && data && (
              <div className="modal-body">
                <div className="wrapWarning">
                  <Checkbox
                    onChange={() => this.handleCheckAll()}
                    checked={isCheckAll}
                  >
                    {t('labels:Lessons')} ({data.totalLesson}):
                  </Checkbox>
                  {this.getDisable(true) && (
                    <div>
                      <Icon type="warning" className="warningIcon" />
                      <label>{t('course:clonning_no_lesson')}</label>
                    </div>
                  )}
                </div>

                <div className="wrap-body-content">
                  {data.courseLessons.map((lesson, index) => {
                    return (
                      <Checkbox
                        onChange={() => {
                          this.handleCheckOne(lesson)
                        }}
                        className="lesson-box"
                        key={index}
                        checked={lesson.isChecked}
                      >
                        {t('labels:clone_of')} - {lesson.title}
                      </Checkbox>
                    )
                  })}
                </div>
              </div>
            )}

            {isCloning && (
              <div className="cloning-processing">
                {/* <Spin indicator={antIcon} /> */}
                <Progress
                  strokeLinecap="square"
                  type="circle"
                  percent={this.getPercent()}
                  size="large"
                />
                {!this.checkFinish() && (
                  <span id="title">
                    {isTranslaton
                      ? t('v4:translatingCourse')
                      : isMaze
                      ? t('v4:cloning_maze')
                      : t('labels:cloning_course')}
                  </span>
                )}
                {!this.checkFinish() && (
                  <span id="desc">
                    {isTranslaton
                      ? t('v4:translationCourseDesc')
                      : t('labels:cloning_course_desc')}
                  </span>
                )}
                {this.checkFinish() && (
                  <span id="title-course">
                    {isTranslaton ? (
                      <Trans i18nKey="v4:translationSuccess">
                        {this.subTitleString(courseTitle)}
                      </Trans>
                    ) : isMaze ? (
                      <Trans i18nKey="v4create_certificate:maze_cloning_success">
                        {this.subTitleString(text)}
                      </Trans>
                    ) : (
                      <Trans i18nKey="labels:cloning_success">
                        {this.subTitleString(courseTitle)}
                      </Trans>
                    )}
                  </span>
                )}
                <div className="wrap_percent">
                  <Progress percent={this.getPercent()} showInfo={false} />
                </div>
              </div>
            )}

            <div className="modal-footer">
              <Button
                disabled={this.getCancelDisable()}
                className={`btn-cancel ${this.getCancelDisable() &&
                  'disabled'}`}
                onClick={() => this.handleCancel()}
              >
                {t('buttons:cancel')}
              </Button>
              <Button
                disabled={this.getDisable(true)}
                className={`btn-clone ${this.getDisable(true) && 'disabled'}`}
                onClick={() => this.handleClone()}
              >
                {t(`${this.getCloneBtn()}`)}
              </Button>
            </div>
          </div>
        )}

        {image && (
          <EditImageContainer
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
          />
        )}

        {modalOpen && (
          <Modal
            visible={modalOpen}
            onCancel={this.hideModal}
            footer={null}
            width={'80%'}
            style={modal_style}
            centered
          >
            <div className="wrap_modal_tabs">
              <PopupMedia
                isPageBuilder
                handleInsertData={this.handleInsertData}
                types="images"
              />
            </div>
            {selectedItem && (
              <Modal
                visible={selectedItem}
                width="70%"
                onCancel={this.hidePreviewModal}
                footer={null}
                centered
              >
                <Editor library={selectedItem} ref={this.imageRef} />

                <div className="wrap_btn_actions">
                  <Button
                    type="danger"
                    size="large"
                    className="action_button"
                    onClick={this.hidePreviewModal}
                  >
                    {general.CANCEL}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="action_button"
                    onClick={() => this.handleFileInsert(selectedItem)}
                  >
                    {general.INSERT}
                  </Button>
                </div>
              </Modal>
            )}
          </Modal>
        )}
      </Modal>
    )
  }
}

export default Form.create()(withTranslation()(CloneModal))
