/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-22 10:35:38
 * @LastEditTime: 2019-09-23 10:28:06
 * @LastEditors: Please set LastEditors
 */
import styled, { css } from 'styled-components'
import { Head, media } from '../../../../../styles'
import { Button } from 'antd'

import { styleguide } from '../../../../../constants'

const { colors, typography, base } = styleguide
const footerHeight = 48

export const HeadWrap = styled(Head)`
  margin-bottom: 0;
`

export const CourseContent = styled.section`
  padding: 0;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
`

export const CourseOwner = styled.section``

export const Footer = styled.section`
  height: ${footerHeight}px;
  width: 100%;
  background-color: #fff;
  padding: 3rem;
  z-index: 9;
  margin-top: 2rem;

  ${props =>
    props.isFullscreen &&
    css`
      border-top: 0.1rem solid #eeeeee;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(244, 245, 250, 0.54);
      padding: 4rem 3rem;
    `}

  ${media.md`
    padding: 0 1.5rem;
  `}
`

Footer.Wrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}
`

Footer.Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${media.md`
    flex-direction: column;
    justify-content: flex-end;

    span {
      margin-right: 0;

      ${media.sm`
        display: none;
      `}
    }
  `}
`

export const Btn = styled(Button)`
  && {
    width: 15rem;
    height: 4rem;
    font-weight: ${typography.h2FontWeight};
  }
`

export const Save = styled.span`
  color: ${colors.blue};
  font-family: ${base.fontFamily};
  font-size: ${typography.hyperLinkFontSize};
  font-weight: 600;
  margin-right: 3rem;
  cursor: pointer;
`

export const ModalInner = styled.div`
  border-radius: 8px;
  overflow: hidden;
`
