import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Card, Checkbox, Icon, Modal, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'

import ChannelContext from '../../ChannelContext'

import './ChannelFormVideos.scss'
import UploadVideo from '../../../../../../../components/UploadVideo/UploadVideo'
import ReactPlayer from 'react-player'
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'
import history from '../../../../../../../history'
import { api } from '../../../../../../../services'

const VideoItem = SortableElement(({ children }) => {
  return <>{children}</>
})

const VideosContainer = SortableContainer(({ children }) => {
  return <div className="shl-channel-form-videos">{children}</div>
})
function ChannelFormVideos({ updateChannel, newAiVideo }) {
  const { t } = useTranslation()

  const { id, setChannelData, apiData, id: channelId, ...rest } = useContext(
    ChannelContext
  )
  console.log(channelId, rest, 'check channelidddd')

  const [videos, setVideos] = useState(apiData.collections_videos || [])
  const [showAddVideoModal, setShowAddVideoModal] = useState(false)
  const [enablePreview, setEnablePreview] = useState(false)
  const [previewVideo, setPreviewVideo] = useState('')
  const [deleteVideoId, setDeleteVideoId] = useState(null)

  const onUploadedVideos = (arr = []) => {
    if (!arr) return
    const files = arr.filter(item => item && item.hasOwnProperty('link'))

    setShowAddVideoModal(false)
    if (files.length < 1) return
    const newVideos = mapOrder([...videos, ...files])
    setVideos(newVideos)
    updateChannel(false, newVideos)
  }

  const mapOrder = array =>
    array.map((item, index) => ({ ...item, order: index }))

  const handleVideoPreview = videoId => {
    const videoToPreview = videos.find(video => video.id === videoId)
    if (
      videoToPreview &&
      videoToPreview.type === 'ai_video' &&
      videoToPreview.status !== 'generated'
    ) {
      history.push(`/channel/${id}/edit/create-ai-video/${videoToPreview.id}`)
      return
    }

    setPreviewVideo(videoId)
    setEnablePreview(true)
  }

  const removeVideo = id => {
    const newVideos = videos.filter(video => video.id !== id)
    setVideos(newVideos)
    updateChannel(false, newVideos)
  }

  useEffect(() => {
    if (setChannelData) {
      setChannelData(prevData => ({
        ...prevData,
        videos
      }))
    }
  }, [videos])

  useEffect(() => {
    if (!apiData.id) return
    if (newAiVideo && newAiVideo.fileId) {
      const newVideos = [
        ...videos,
        {
          type: 'ai_video',
          id: newAiVideo.fileId,
          title: newAiVideo.filename,
          order: videos.length
        }
      ]
      window.history.replaceState({}, document.title)
      setVideos(newVideos)
      updateChannel(false, newVideos)
    }
  }, [newAiVideo])

  const IconText = ({ type, text, onClick, className }) => (
    <span onClick={onClick} className={className}>
      <Icon type={type} style={{ marginRight: 8 }} />
      {text}
    </span>
  )

  const onPreviewCancel = () => {
    setEnablePreview(false)
    setPreviewVideo('')
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return
    const newVideos = mapOrder(arrayMove(videos, oldIndex, newIndex))
    setVideos(newVideos)
    updateChannel(false, newVideos)
  }

  const onYoutubeVideos = (vidArray = []) => {
    const ytVids = vidArray.map((item, index) => ({
      ...item,
      type: 'youtube'
    }))
    const newVids = mapOrder([...videos, ...ytVids])
    setVideos(newVids)
    updateChannel(false, newVids)
    setShowAddVideoModal(false)
  }

  const videoToPreview = useMemo(() => {
    const video = videos.find(item => item.id === previewVideo)
    return video
  }, [previewVideo])

  return (
    <Card title={t('v4:videos')} className="shl-channel-form-videos-card">
      <div className="shl-channel-form-videos-conatiner">
        {videos.length === 0 && (
          <Alert
            className="shl-channel-form-alert"
            type="warning"
            message={t('v3:no_video_yet')}
          />
        )}

        {videos.length > 0 && (
          <VideosContainer
            distance={1}
            onSortEnd={onSortEnd}
            lockAxis="y"
            lockToContainerEdges
          >
            {videos.map((video, index) => (
              <VideoItem key={index} index={index}>
                <div className="shl-channel-form-video">
                  <div className="shl-channel-form-video__thumbnail">
                    {video.type === 'ai_video' &&
                      video.status !== 'generated' ? null : video.type !==
                        'youtube' ? (
                      <ReactPlayer
                        className="react-player"
                        url={video.link}
                        height="100%"
                        width="100%"
                      />
                    ) : (
                      <img
                        src={`https://i.ytimg.com/vi/${video.id}/default.jpg`}
                        className="react-player"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="shl-channel-form-video__info">
                    <div className="shl-channel-form-video__title">
                      {video.title}
                    </div>
                    <div className="shl-channel-form-video__action">
                      <IconText
                        type="eye"
                        text="Preview"
                        onClick={() => handleVideoPreview(video.id)}
                        className="preview"
                      />
                      <Popconfirm
                        title={t('v3:are_you_sure_delete_video')}
                        onConfirm={() => removeVideo(video.id)}
                        okText={t('general:yes')}
                        cancelText={t('general:no')}
                        icon={null}
                        disabled={Boolean(deleteVideoId)}
                      >
                        <IconText
                          type={
                            deleteVideoId === video.id ? 'loading' : 'delete'
                          }
                          text="Delete"
                          className="delete"
                          loading={deleteVideoId === video.id}
                        />
                      </Popconfirm>
                    </div>
                    {video.isAiEnabled && (
                      <div className="shl-channel-form-video__more">
                        <Checkbox checked>{t('v3:ai')}</Checkbox>
                      </div>
                    )}
                  </div>
                </div>
              </VideoItem>
            ))}
          </VideosContainer>
        )}

        <div className="shl-channel-form-video-buttons">
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => setShowAddVideoModal(true)}
          >
            {t('v3:add_videos')}
          </Button>
          <Button
            type="primary"
            shape="round"
            size="large"
            disabled={!id}
            onClick={() => history.push(`/channel/${id}/edit/create-ai-video`)}
          >
            {t('v3:create_ai_video')}{' '}
          </Button>
        </div>

        {showAddVideoModal && (
          <Modal
            visible={showAddVideoModal}
            onCancel={() => setShowAddVideoModal(false)}
            className="custom-ant-modal channel-video-modal"
            destroyOnClose
            width={800}
            header={null}
            footer={null}
          >
            <UploadVideo
              isMultiple
              showYoutube
              showLibray
              onUploadedVideos={onUploadedVideos}
              onYoutubeVideos={onYoutubeVideos}
            />
          </Modal>
        )}

        {videoToPreview && videoToPreview.id && (
          <Modal
            visible={enablePreview}
            onCancel={onPreviewCancel}
            destroyOnClose
            header={null}
            footer={null}
            bodyStyle={{ padding: 0 }}
            width="800px"
            centered
            closable={false}
          >
            <ReactPlayer
              responsive
              width="800px"
              url={
                videoToPreview.type === 'youtube'
                  ? `https://www.youtube.com/watch?v=${videoToPreview.id}`
                  : videoToPreview.link
              }
              controls
              loop
              playing
            />
          </Modal>
        )}
      </div>
    </Card>
  )
}

export default ChannelFormVideos
